import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import StorageService from '../../services/StorageService';

import Padding from './Padding';
import Icon from "./../../common/components/Icon.js";
// import Image from './../../common/components/Image';

import {BREAKPOINT_SM, BREAKPOINT_XS } from '../../config';

const Wrapper = styled.div`
  position: relative;   
  min-height: calc(100vh - 100px);
  background-color: #333;  
  background-image: url('${props => props.src}');
  background-position: center right;
  background-size: cover;
`;
const Contents = styled.div`
  z-index: 3;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 50%;
  @media (max-width: ${BREAKPOINT_XS}px){
    width: 100%;
  }
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  text-shadow: 0px 0px 20px rgba(0,0,0,0.5);
`;
const Uppertext = styled.div`
  font-family: 'Lato', sans-serif;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 40px;
  @media (max-width: ${BREAKPOINT_SM}px){
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 40px;
  }
  @media (max-width: ${BREAKPOINT_XS}px){
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 40px;
  }
  letter-spacing: 1.5px;
  font-weight: 900;
  text-transform: uppercase;

  & span{
    position: relative;
    top: 3px;
    font-size: 17px;
    margin-right: 6px;
  }
`;
const Text = styled.div`
  font-family: 'Skranji', cursive;
  font-size: 52px;
  line-height: 65px;
  @media (max-width: ${BREAKPOINT_SM}px){
    font-size: 42px;
    line-height: 50px;
  }
  @media (max-width: ${BREAKPOINT_XS}px){
    font-size: 32px;
    line-height: 40px;
  }
  letter-spacing: 1.5px;
`;
const Lowertext = styled.div`
  font-family: 'Lato', sans-serif;
  margin-top: 25px;
  font-size: 18px;
  line-height: 22px;
  @media (max-width: ${BREAKPOINT_SM}px){
    margin-top: 18px;
    font-size: 17px;
    line-height: 21px;
  }
  @media (max-width: ${BREAKPOINT_XS}px){
    margin-top: 10px;
    font-size: 16px;
    line-height: 19px;
  }
  letter-spacing: 0.5px;
  font-weight: 500;
`;
const Layer = styled.div`
  z-index: 2;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 33%, rgba(255, 255, 255, 0) 67%, rgba(255, 255, 255, 0) 100%)
`;

const Header = ({ 
  page, 
  uppertext = null,
  text = null,
  lowertext = null,
  alt
}) => {
  const [srcImage, setSrcImage] = useState(null);
  
  useEffect(() => {
    if(page){
      StorageService.getUrl("/web/headers/"+page+".jpg").then((response) => {
        setSrcImage(response);
      });
    }
  }, [page]);

  return (
    <Wrapper src={srcImage}>
      <Contents>
        <Padding top right bottom left>
          <Uppertext><Icon name="adjust" size="24px" /> {uppertext}</Uppertext>
          <Text>{text}</Text>
          <Lowertext>{lowertext}</Lowertext>
        </Padding>
      </Contents>
      <Layer />
      {/* <img width={width} height={height} src={url ? url : null} alt={alt}  /> */}
      {/* <Image src={page ? "/web/headers/"+page+".jpg" : null} alt={alt} /> */}
    </Wrapper>
  )
}

export default Header
