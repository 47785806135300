import React, {useState, useEffect, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';

import DatabaseService from '../../services/DatabaseService';

import Layout from '../layout/Layout';

import Container from '../../common/components/Container';
import Row from '../../common/components/Row';
import Col from '../../common/components/Col';
import Card from '../../common/components/Card';
import Loading from '../../common/components/Loading';
import Button from '../../common/components/Button';
import Input from '../../common/components/Input';
import Select from '../../common/components/Select';
import Cta from '../../common/components/Cta';
// import Checkbox from '../../common/components/Checkbox';
import Textarea from '../../common/components/Textarea';
import Success from '../../common/components/Success';


const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 40px;
`;
const Title = styled.div`
  font-family: 'Skranji',cursive;
  font-size: 30px;
`;
const CardHeaderTitle = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #303030;
  margin-bottom: 5px;
`;
const ErrorMessage = styled.div`
  height: 30px;
  color: red;
`;

const CessionApplicationsSingle = ({layout}) => {
  const {slug} = useParams();
  const [item, setItem] = useState();

  const [disabled, setDisabled] = useState(true);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    // if(!item.name || item.item.length === 0){
    //   valid = false;
    // }
    setDisabled(!valid);
  }, [item]);


  const getItem = useCallback(() => {
    DatabaseService.getCessionApplication(slug).then((response) => {
      if(response.success){
        setItem(response.data);
      }else{
        console.log(response.error);
      }
    });
  }, [slug]);

  const updateItem = useCallback(() => {
    setErrorMessage("");
    DatabaseService.updateCessionApplication(slug, item).then((response) => {
      if(response.success){
        setSuccessMessage("Dati salvati correttamente!");
      }else{
        setErrorMessage(response.error);
        console.log(response.error);
      }
    });
  }, [slug, item]);

  useEffect(() => {
    getItem();
  }, [slug, getItem]);

  if(item){
    return (
      <Layout layout={layout}>
        <Container>
          
          <Header>
            <Title>Candidatura alla cessione: {item.familyName + " " + item.givenName} per {item.horseName}</Title>    
          </Header>

          <Row>
             
            <Col>
              <Card
                headerLeft={
                  <>
                    <CardHeaderTitle>Informazioni generali</CardHeaderTitle>
                  </>
                }
              >
                <Row>
                  <Col widthmd="12">
                    <Select
                      label="Stato"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "Da prendere in carico", value: ""},
                        {name: "In carico", value: "In carico"},
                        {name: "Riuscito", value: "Riuscito"},
                        {name: "Annullato", value: "Annullato"}
                      ]}
                      selected={item.status}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.status = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Input
                      label="Nome"
                      width="100%"
                      value={item.givenName}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.givenName = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Input
                      label="Cognome"
                      width="100%"
                      value={item.familyName}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.familyName = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Input
                      label="Telefono"
                      width="100%"
                      value={item.phone}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.phone = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Input
                      label="Email"
                      width="100%"
                      value={item.email}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.email = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Textarea
                      label="Perché non puoi o non vuoi più tenere il cavallo?"
                      width="100%"
                      value={item.cause}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.cause = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Select
                      label="Quanto è urgente la necessità di trovare casa al cavallo?"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "Immediata", value: "Immediata"},
                        {name: "Il più velocemente possibile", value: "Il più velocemente possibile"},
                        {name: "Il tempo necessario per fare le cose con cura", value: "Il tempo necessario per fare le cose con cura"},
                        {name: "Non c'è fretta", value: "Non c'è fretta"}
                      ]}
                      selected={item.priority}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.priority = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col> 
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                headerLeft={
                  <>
                    <CardHeaderTitle>Informazioni generali sul cavallo</CardHeaderTitle>
                  </>
                }
              >
                <Row>
                  <Col widthmd={6}>
                    <Select
                      label="Regione dove si trova il cavallo"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "Abruzzo", value: "Abruzzo"},
                        {name: "Basilicata", value: "Basilicata"},
                        {name: "Calabria", value: "Calabria"},
                        {name: "Campania", value: "Campania"},
                        {name: "Emilia-Romagna", value: "Emilia-Romagna"},
                        {name: "Friuli-Venezia Giulia", value: "Friuli-Venezia Giulia"},
                        {name: "Lazio", value: "Lazio"},
                        {name: "Liguria", value: "Liguria"},
                        {name: "Lombardia", value: "Lombardia"},
                        {name: "Marche", value: "Marche"},
                        {name: "Molise", value: "Molise"},
                        {name: "Piemonte", value: "Piemonte"},
                        {name: "Puglia", value: "Puglia"},
                        {name: "Sardegna", value: "Sardegna"},
                        {name: "Sicilia", value: "Sicilia"},
                        {name: "Toscana", value: "Toscana"},
                        {name: "Trentino-Alto Adige", value: "Trentino-Alto Adige"},
                        {name: "Umbria", value: "Umbria"},
                        {name: "Valle d'Aosta", value: "Valle d'Aosta"},
                        {name: "Veneto", value: "Veneto"},
                        {name: "Estero", value: "Estero"}
                      ]}
                      selected={item.horseRegion}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseRegion = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Input
                      label="CAP dove si trova il cavallo"
                      width="100%"
                      value={item.horseZip}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseZip = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={12}>
                    <Textarea
                      label="Indirizzo dove si trova il cavallo"
                      width="100%"
                      value={item.horseAddress}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseAddress = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Input
                      label="Nome del cavallo"
                      width="100%"
                      value={item.horseName}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseName = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Input
                      label="Data di nascita del cavallo"
                      width="100%"
                      value={item.horseBirthdate}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseBirthdate = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Input
                      label="Razza del cavallo"
                      width="100%"
                      value={item.horseBreed}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseBreed = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Input
                      label="Altezza (approssimativa) del cavallo"
                      width="100%"
                      value={item.horseHeight}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseHeight = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col> 
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                headerLeft={
                  <>
                    <CardHeaderTitle>Domande sulla vita del cavallo</CardHeaderTitle>
                  </>
                }
              >
                <Row>
                  <Col widthmd={6}>
                    <Input
                      label="Da quanto tempo è tuo"
                      width="100%"
                      value={item.humanTime}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.humanTime = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Select
                      label="Il cavallo è abituato a vivere"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "Da solo", value: "Da solo"},
                        {name: "Con un solo altro cavallo", value: "Con un solo altro cavallo"},
                        {name: "Con diversi altri cavalli", value: "Con diversi altri cavalli"},
                        {name: "Insieme ad altri animali", value: "Insieme ad altri animali"},
                        {name: "Altro", value: "Altro"}
                      ]}
                      selected={item.horseFriends}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseFriends = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Select
                      label="Il cavallo è abituato a vivere"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "In box", value: "In box"},
                        {name: "In box con paddock comunicante", value: "In box con paddock comunicante"},
                        {name: "In box con qualche ora al paddock", value: "In box con qualche ora al paddock"},
                        {name: "In paddock con capannina", value: "In paddock con capannina"},
                        {name: "Al pascolo", value: "Al pascolo"},
                        {name: "Altro", value: "Altro"}
                      ]}
                      selected={item.horseHouse}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseHouse = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Select
                      label="Il cavallo è abituato ad avere"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "Fieno sempre a disposizione", value: "Fieno sempre a disposizione"},
                        {name: "Fieno razionato distribuito ad orari fissi", value: "Fieno razionato distribuito ad orari fissi"},
                        {name: "Altro", value: "Altro"}
                      ]}
                      selected={item.horseFeeding}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseFeeding = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Select
                      label="Il cavallo è abituato ad essere"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "Ferrato davanti e dietro", value: "Ferrato davanti e dietro"},
                        {name: "Ferrato solo davanti", value: "Ferrato solo davanti"},
                        {name: "Scalzo", value: "Scalzo"},
                        {name: "Altro", value: "Altro"}
                      ]}
                      selected={item.horseFeet}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseFeet = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={12}>
                    <Textarea
                      label="Note"
                      width="100%"
                      value={item.horseManagementNotes}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseManagementNotes = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                headerLeft={
                  <>
                    <CardHeaderTitle>Domande sulle caratteristiche del cavallo</CardHeaderTitle>
                  </>
                }
              >
                <Row>
                  <Col widthmd={6}>
                    <Textarea
                      label="Il cavallo ha dei problemi problemi fisici adesso? Se si, quali?"
                      width="100%"
                      value={item.horseCurrentPhysical}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseCurrentPhysical = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Textarea
                      label="Il cavallo ha avuto in passato dei problemi problemi fisici? Se si, quali?"
                      width="100%"
                      value={item.horsePastPhysical}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horsePastPhysical = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Textarea
                      label="Il cavallo ha dei problemi problemi comportamentali adesso? Se si, quali?"
                      width="100%"
                      value={item.horseCurrentMental}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseCurrentMental = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Textarea
                      label="Il cavallo ha avuto in passato dei problemi problemi comportamentali? Se si, quali?"
                      width="100%"
                      value={item.horsePastMental}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horsePastMental = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={12}>
                    <Textarea
                      label="Descrivi il carattere del cavallo"
                      width="100%"
                      value={item.horsePersonality}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horsePersonality = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Textarea
                      label="Com'è il cavallo con le persone? (esempio: tranquillo, confidente, pauroso ecc)"
                      width="100%"
                      value={item.horseWithHumans}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseWithHumans = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Textarea
                      label="Com'è il cavallo con gli altri cavalli? (esempio: tranquillo, confidente, pauroso ecc)"
                      width="100%"
                      value={item.horseWithHorses}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseWithHorses = val;
                        setItem(tempItem);
                      }}
                      required
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <ErrorMessage>
            <br/>{errorMessage}<br/>
          </ErrorMessage>

          <div style={{"float": "right"}}>
            <Button 
              onClick={() => updateItem()}
              variant="default-o"
              text="Salva"
              icon="save"
              disabled={disabled}
            />
          </div>
          <Cta 
            variant="default-o"
            text="Indietro"
            link={"/admin/cession-applications"}
            icon="arrow_backward"
          />
          

        </Container>

        {successMessage ?
          <Success fnClose={() =>setSuccessMessage(false)} message={successMessage} />
        : null}

      </Layout>
    )
  }else{
    return <Loading />;
  }
}

export default CessionApplicationsSingle;
