import React, {Component} from "react";
import styled from "styled-components";

import markerResidenteDellOasi from "./../../assets/markers/map-marker-green.png";
import markerAdottato from "./../../assets/markers/map-marker-orange.png";
import markerInCercaDiCasa from "./../../assets/markers/map-marker-yellow.png";
import markerAltro from "./../../assets/markers/map-marker-red.png";


const ZOOM_START = 5;
const ZOOM_MAX = 20;
const INITIAL_LAT = 42.74068808850803;
const INITIAL_LNG = 12.37713542336654;

const Map  = styled.div`
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 400px;
  overflow: hidden;
`;


class HorseMap extends Component {
  constructor(props) {
    super(props);

    this.map = null;
    this.geocoder = null;
    this.markers = [];
  }

  componentDidMount(){
    const ApiKey = 'AIzaSyDeVWT_7CdZ7FY3mxuBDNdV9gZU2nEKs1k';
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${ApiKey}`;
    script.async = true;
    script.defer = true;
    script.addEventListener('load', () => {
      this.initMap();
    });

    document.body.appendChild(script);
  }

  initMap(){
    this.map = new window.google.maps.Map(document.getElementById('map'), {
      zoom: ZOOM_START,
      maxZoom: ZOOM_MAX,
      center: {lat: INITIAL_LAT, lng: INITIAL_LNG},
      gestureHandling: 'greedy',
      mapTypeId: 'hybrid',
      mapTypeControl: false,
      streetViewControl: false
    });

    this.geocoder = new window.google.maps.Geocoder();

    this.props.list.forEach((horse) => {
      this.addMarker(horse);
    });
  }

  addMarker(horse){
    if(window.google){
      let marker = {};
      if(true){
        let icon;
        if(horse.status === "Residente dell'Oasi"){
          icon = markerResidenteDellOasi;
        }else if(horse.status === "Adottato"){
          icon = markerAdottato;
        }else if(horse.status === "In cerca di casa"){
          icon = markerInCercaDiCasa;
        }else{
          icon = markerAltro;
        }
        // this.geocoder.geocode({'address': horse.barn}, function(results, status) {
        //   if (status === 'OK') {
        //     // map.setCenter(results[0].geometry.location);
        //     // var marker = new google.maps.Marker({
        //     //   map: map,
        //     //   position: results[0].geometry.location
        //     // });
          if(horse.latlng){
            marker.gMarker = new window.google.maps.Marker({
              position: {lat: parseFloat(horse.latlng.split(",")[0]), lng: parseFloat(horse.latlng.split(",")[1])},
              label: horse.name,
              icon: {
                url: icon,
                size: new window.google.maps.Size(41, 41),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(21, 21),
                scaledSize: new window.google.maps.Size(41, 41)
              },
              map: this.map,
              // title: objLocation.NOME, //commentare
              alt: ""
            });
    
            marker.gInfo = new window.google.maps.InfoWindow({
              content: horse.name,
              disableAutoPan: true
            });
          }
          // } else {
          //   alert('Geocode was not successful for the following reason: ' + status);
          // }
        // });
      }
    }
  }

  render() {
    return (
      <Map id="map" />
    );
  }
}

export default HorseMap;