import React from "react";
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const Spinner = styled.div`
  width: 40px;
  height: 40px;


  border-radius: 50%;
  background: lightblue;
  background: linear-gradient(to right, lightblue 10%,transparent 40%);
  position: relative;
  animation: rotating 0.3s infinite linear;
  transform: translateZ(0);


  &:after {
    background: ${props => props.bgcolor};
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const Loading = ({bgcolor = "#ffffff"}) => {
  return (
    <Wrapper>
      <Spinner bgcolor={bgcolor} />
    </Wrapper>
  )
}

export default Loading;
