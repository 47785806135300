import React, {useState, useContext, useEffect, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {UserContext} from './../../contexts/UserContext';

import Typo from './../../common/components/Typo';

import Container from '../../common/components/Container';
import Row from '../../common/components/Row';
import Col from '../../common/components/Col';
import Logo from '../../common/components/Logo';
import Card from '../../common/components/Card';
import Button from '../../common/components/Button';
import Input from '../../common/components/Input';

import AuthService from '../../services/AuthService';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #F6F7FB;
`;

const Content = styled.div`
  text-align: center;
  width: 100%;
`;

const Error = styled.div`
  height: 30px;
  color: red;
`;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const {setUser} = useContext(UserContext);
  let navigate = useNavigate();


  useEffect(() => {
    AuthService.logout();
    setUser(null);
  }, [setUser]);

  const send = useCallback(() => {
    setError(null);
    if(email && password){
      AuthService.login(email, password).then(response => {
        if(response.success){
          setUser(response.data);
          navigate("/admin");
        }else{
          setError(response.error);
        }
      }).catch(error => {
          setError("Something went wrong");
          console.log(error.message);
      });

    }else{
      setError("Fill all the fields");
    }
  }, [email, password, setUser, navigate]);

  return (
    <Wrapper>
      <Content>
        <Container size="small">
          <Logo height="100px"/>
          <br/><br/>
          <Card
            footerRight={<Button onClick={() => send()}>Entra</Button>}
          >
            <Typo type="h2" align="center">Area riservata</Typo>

            <Row>
              <Col widthmd="12">
                <Input
                  label={"Email"}
                  name="email"
                  type="email"
                  width="100%"
                  value={email}
                  onChange={(val) => setEmail(val)}
                  required
                />
              </Col>
              <Col widthmd="12">
                <Input
                  label={"Password"}
                  name="password"
                  type="password"
                  width="100%"
                  value={password}
                  onChange={(val) => setPassword(val)}
                  required
                />
              </Col>
              <Col widthmd="12">
                <Error>
                  <br/>{error}<br/>
                </Error>
              </Col>
            </Row>
          </Card>
        </Container>
      </Content>
    </Wrapper>
  )
}

export default Login;
