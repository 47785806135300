import {useState, useCallback, useEffect, useContext} from 'react';
import {Routes, Route, useLocation, Navigate} from 'react-router-dom';
import {UserContext} from './contexts/UserContext';
// import { getAnalytics } from "firebase/analytics";

//Frontend
import Home from "./frontend/pages/Home";
import Associazione from "./frontend/pages/Associazione";
import Sostienici from "./frontend/pages/Sostienici";
import SostieniciCinquepermille from "./frontend/pages/SostieniciCinquepermille";
import SostieniciVolontariatoadistanza from "./frontend/pages/SostieniciVolontariatoadistanza";
import SostieniciCandidaturaAdozione from "./frontend/pages/SostieniciCandidaturaAdozione";
import CavalliList from "./frontend/pages/CavalliList";
import CavalliSingle from "./frontend/pages/CavalliSingle";
import EventiList from "./frontend/pages/EventiList";
import EventiSingle from "./frontend/pages/EventiSingle";
import Contatti from "./frontend/pages/Contatti";
import ContattiRichiestaCessione from "./frontend/pages/ContattiRichiestaCessione";



// import BlogList from "./frontend/pages/BlogList";
// import BlogSingle from "./frontend/pages/BlogSingle";
import CircleApplication from "./frontend/pages/CircleApplication";

// import CinquePerMille from "./frontend/pages/CinquePerMille";

//Authentication
// import Registration from "./backend/pages/Registration";
import Login from "./backend/pages/Login";

//Backend
import AdminDashboard from './backend/pages/Dashboard';
import AdminHorsesList from './backend/pages/HorsesList';
import AdminHorsesSingle from './backend/pages/HorsesSingle';
import AdminEventsList from './backend/pages/EventsList';
import AdminEventsSingle from './backend/pages/EventsSingle';
import AdminAdoptionApplicationsList from './backend/pages/AdoptionApplicationsList';
import AdminAdoptionApplicationsSingle from './backend/pages/AdoptionApplicationsSingle';
import AdminCessionApplicationsList from './backend/pages/CessionApplicationsList';
import AdminCessionApplicationsSingle from './backend/pages/CessionApplicationsSingle';

//404
import NotFound from "./frontend/pages/NotFound";

import {BREAKPOINT_MD, BREAKPOINT_SM, BREAKPOINT_XS, LAYOUT_LG, LAYOUT_MD, LAYOUT_SM, LAYOUT_XS } from './config';

const RequireAuth = ({children}: {children: JSX.Element}) => {
  const {user} = useContext(UserContext);
  let location = useLocation();
  // console.log(user);
  if (!user) {
    return <Navigate to="/admin/login" state={{ from: location }} replace />;
  }
  return (<>{children}</>);
}

const App = () => {
  const [resizeListener, setResizeListener] = useState(false);
  const [layout, setLayout] = useState(null);

  // useEffect(() => {
  //   AnalyticsService.getUrl(src).then((response) => {
  //     if(response.success){
  //       setUrl(response.url);
  //     }else{
  //       console.log(response.error);
  //     }
  //   });
  // }, []);

  const handleResize = useCallback(() => {
    setLayout(window.innerWidth <= BREAKPOINT_XS ? LAYOUT_XS : (window.innerWidth <= BREAKPOINT_SM ? LAYOUT_SM : (window.innerWidth <= BREAKPOINT_MD ? LAYOUT_MD : LAYOUT_LG)));
  }, []);

  useEffect(() => {
    if(!resizeListener){
      setResizeListener(true);
      window.addEventListener("resize", handleResize, false);
      handleResize();
    }
  }, [handleResize, resizeListener]);

  return (
    <Routes>

      {/*301*/}
      <Route exact path='/' element={<Navigate to={"/it"} />} />

      {/*Frontend*/}
      <Route exact path='/:lang/' element={<Home layout={layout} />} />
      <Route exact path='/:lang/associazione' element={<Associazione layout={layout} />} />
      <Route exact path='/:lang/association' element={<Associazione layout={layout} />} />
      <Route exact path='/:lang/sostienici' element={<Sostienici layout={layout} />} />
      <Route exact path='/:lang/support-us' element={<Sostienici layout={layout} />} />
      <Route exact path='/:lang/sostienici/cerchie' element={<CircleApplication layout={layout} />} />
      <Route exact path='/:lang/support-us/distance-adoptions' element={<CircleApplication layout={layout} />} />
      <Route exact path='/:lang/sostienici/cinque-x-mille' element={<SostieniciCinquepermille layout={layout} />} />
      <Route exact path='/:lang/support-us/cinque-x-mille' element={<SostieniciCinquepermille layout={layout} />} />
      <Route exact path='/:lang/sostienici/volontariato-a-distanza' element={<SostieniciVolontariatoadistanza layout={layout} />} />
      <Route exact path='/:lang/support-us/remote-volunteering' element={<SostieniciVolontariatoadistanza layout={layout} />} />
      <Route exact path='/:lang/sostienici/candidatura-adozione' element={<SostieniciCandidaturaAdozione layout={layout} />} />
      <Route exact path='/:lang/support-us/adoption-application' element={<SostieniciCandidaturaAdozione layout={layout} />} />
      <Route exact path='/:lang/cavalli' element={<CavalliList layout={layout} />} />
      <Route exact path='/:lang/horses' element={<CavalliList layout={layout} />} />
      <Route exact path='/:lang/cavalli/:slug' element={<CavalliSingle layout={layout} />} />
      <Route exact path='/:lang/horses/:slug' element={<CavalliSingle layout={layout} />} />
      <Route exact path='/:lang/eventi' element={<EventiList layout={layout} />} />
      <Route exact path='/:lang/events' element={<EventiList layout={layout} />} />
      <Route exact path='/:lang/eventi/:slug' element={<EventiSingle layout={layout} />} />
      <Route exact path='/:lang/events/:slug' element={<EventiSingle layout={layout} />} />
      {/* <Route exact path='/:lang/blog' element={<BlogList layout={layout} />} />
      <Route exact path='/:lang/blog/:slug' element={<BlogSingle layout={layout} />} /> */}
      <Route exact path='/:lang/contatti' element={<Contatti layout={layout} />} />
      <Route exact path='/:lang/contacts' element={<Contatti layout={layout} />} />
      <Route exact path='/:lang/contatti/richiesta-cessione' element={<ContattiRichiestaCessione layout={layout} />} />
      <Route exact path='/:lang/contacts/cession-request' element={<ContattiRichiestaCessione layout={layout} />} />


        
      {/*Redirect*/}    
      <Route exact path='/:lang/chi-siamo' element={<Navigate to={"/it/associazione"} />} />
      <Route exact path='/:lang/about-us' element={<Navigate to={"/en/association"} />} />
      <Route exact path='/:lang/come-aiutarci' element={<Navigate to={"/it/sostienici"} />} />
      <Route exact path='/:lang/help-us' element={<Navigate to={"/en/support-us"} />} />
      <Route exact path='/:lang/come-aiutarci/cerchie' element={<Navigate to={"/it/sostienici/adozioni-a-distanza"} />} />
      <Route exact path='/:lang/help-us/cerchie' element={<Navigate to={"/en/support-us/distance-adoptions"} />} />
      <Route exact path='/:lang/candidatura-cessione' element={<Navigate to={"/it/contatti/richiesta-cessione"} />} />
      <Route exact path='/:lang/cession-application' element={<Navigate to={"/en/contacts/cession-request"} />} />
      <Route exact path='/:lang/candidatura-adozione' element={<Navigate to={"/it/sostienici/candidatura-adozione"} />} />
      <Route exact path='/:lang/adoption-application' element={<Navigate to={"/it/support-us/adoption-application"} />} />
      <Route exact path='/:lang/sostienici/cinquexmille' element={<Navigate to={"/it/sostienici/cinque-x-mille"} />} />
      <Route exact path='/:lang/support-us/cinquexmille' element={<Navigate to={"/it/support-us/cinque-x-mille"} />} />
      <Route exact path='/:lang/sostienici/volontariatoadistanza' element={<Navigate to={"/it/sostienici/volontariato-a-distanza"} />} />
      <Route exact path='/:lang/support-us/remotevolunteering' element={<Navigate to={"/it/support-us/remote-volunteering"} />} />


      {/*Authentication*/}
      {/*<Route exact path='/admin/registration' element={<Registration />} />*/}
      <Route exact path='/admin/login' element={<Login />} />

      {/*Backend*/}
      <Route exact path='/admin' element={<RequireAuth><AdminDashboard layout={layout} /></RequireAuth>} />
      <Route exact path='/admin/horses' element={<RequireAuth><AdminHorsesList layout={layout} /></RequireAuth>} />
      <Route exact path="/admin/horses/:slug" element={<RequireAuth><AdminHorsesSingle layout={layout} /></RequireAuth>} />
      <Route exact path='/admin/events' element={<RequireAuth><AdminEventsList layout={layout} /></RequireAuth>} />
      <Route exact path="/admin/events/:slug" element={<RequireAuth><AdminEventsSingle layout={layout} /></RequireAuth>} />
      <Route exact path='/admin/adoption-applications' element={<RequireAuth><AdminAdoptionApplicationsList layout={layout} /></RequireAuth>} />
      <Route exact path="/admin/adoption-applications/:slug" element={<RequireAuth><AdminAdoptionApplicationsSingle layout={layout} /></RequireAuth>} />
      <Route exact path='/admin/cession-applications' element={<RequireAuth><AdminCessionApplicationsList layout={layout} /></RequireAuth>} />
      <Route exact path="/admin/cession-applications/:slug" element={<RequireAuth><AdminCessionApplicationsSingle layout={layout} /></RequireAuth>} />

      {/*404*/}
      <Route path='*' element={<NotFound layout={layout} />} />
    </Routes>
  );
}

export default App;
