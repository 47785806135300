import { initializeApp } from "@firebase/app";
import { getAnalytics, logEvent } from "@firebase/analytics";


import {firebaseConfig} from '../config';

class AnalyticsService {
  constructor() {
    this.app = initializeApp(firebaseConfig);
    // this.app.analytics();
  }

  init() {
    this.analytics = getAnalytics(this.app);
  }

  logEvent(name, params){    
    logEvent(this.analytics, name, params);
  }

}

let instance = new AnalyticsService();
export default instance;
