import React, {useRef, useState, useEffect, useCallback} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import DatabaseService from '../../services/DatabaseService';

import Layout from './../layout/Layout';

import Overlay from './../../common/components/Overlay';
import Container from './../../common/components/Container';
import Row from './../../common/components/Row';
import Col from './../../common/components/Col';
import Table from './../../common/components/Table';
import Loading from './../../common/components/Loading';
import Card from './../../common/components/Card';
import Button from './../../common/components/Button';
// import ModalNew from './common/ModalNew';


const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 40px;
`;
const TableWrapper = styled.div`
  & table tr td{
    white-space: nowrap;
  }
  & table tr td:nth-child(2),
  & table tr td:nth-child(3){
    word-break: break-all;
    white-space: unset;
  }
  overflow-x: auto; 
`;
const TableActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > *{
    margin-left: 5px;
  }
`;
const NoResult = styled.div`
  text-align: center;
`;
const Title = styled.div`
  font-family: 'Skranji',cursive;
  font-size: 30px;
`;


const EventsList = ({layout}) => {
  let scrollRef = useRef(null);
  // let navigate = useNavigate();
  const [list, setList] = useState(null);
  const [page] = useState(0);

  const [showModalNew, toggleModalNew] = useState(false);

  const [filters] = useState({
    // "rangeDate": changeDate(new Date(), -6, "days", "startOfDay") + "|" + changeDate(new Date(), 0, "days", "endOfDay"),
    // "level": null,
    "search": ""
  });

  // const changeFilters = useCallback((name, value) => {
  //   setList(null);
  //   setPage(0);
  //   let tempFilters = {...filters};
  //   // if(name === "rangeDate"){
  //   //   let start = value.split('|')[0] !== "null" ? value.split('|')[0] : changeDate(new Date(), -6, "days", "startOfDay");
  //   //   let end = value.split('|')[1] !== "null" ? value.split('|')[1] : changeDate(new Date(), 0, "days", "endOfDay");
  //   //   value = start+"|"+end;
  //   // }
  //   tempFilters[name] = value;
  //   setFilters(tempFilters);
  // }, [filters]);

  const getList = useCallback(() => {
    if(scrollRef && scrollRef.current){
      scrollRef.current.scrollTo(0,0);
    }
    DatabaseService.getEvents({
      page: page,
      // start: filters.rangeDate.split('|')[0],
      // end: filters.rangeDate.split('|')[1],
      // level: filters.level,
      searchKey: filters.search
    }).then((response) => {
      if(response.success){
        // setTotPages(response.totPages);
        setList(response.data);
      }else{
        console.log(response.error);
      }
    });

  }, [filters, page]);

  useEffect(() => {
    getList();
  }, [getList, filters, page]); 

  // const deleteItem = useCallback((item) => {
  //   setList(null);
  //   DatabaseService.deleteHorse(item.slug).then((response) => {
  //     if(response.success){
  //       getList();
  //     }else{
  //       console.log(response.error);
  //     }
  //   });
  // }, [getList]);

  return (
    <Layout layout={layout}>
      <div ref={scrollRef}>
        <Container>
          <Header>
            <Title>Eventi {list ? <small>({list.length})</small> : null}</Title>               
            <Button 
              onClick={() => toggleModalNew(true)}
              variant="default-o"
              text="Nuovo"
              icon="add"
            />
          </Header>

          <Row>
            <Col>
              <Card>
                <Row>
                  <Col widthmd="12">
                  {/*} <Filters
                      onChange={(...args) => changeFilters(...args)}
                      fields={
                        [
                          {
                            "label": "Search",
                            "name": "search",
                            "size": 4,
                            "type": "text",
                            "placeholder": null,
                            "value": filters.search
                          }
                        ]
                      }
                    />
                    <hr/>*/}
                    {list ?
                      list.length > 0 ?
                        <>
                          <TableWrapper>
                            <Table
                            columns={[
                            "Nome",
                            ""
                          ]}
                          values={
                            list.map(item => ({
                              values: [
                                item.name_it,
                                <TableActions onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                                  {/* <Button icon="remove" variant={"push-bittersweet"} size={"small"} onClick={() => deleteItem(item)} /> */}
                                </TableActions>
                              ],
                              link: ({children}) => (<Link to={"/admin/events/"+item.slug}>{children}</Link>)
                            }))}
                            />
                          </TableWrapper>

                        </>
                      : <NoResult>There are no results.</NoResult>
                    : <Loading />}
                  </Col>
                </Row>
                {/* {totPages > 1 ?
                  <OzPagination totPages={totPages} currentPage={page} onChange={((val) => setPage(val))} />
                : null } */}
              </Card>
            </Col>
          </Row>

          {showModalNew ? (
            <Overlay>
              {/*<ModalNew
                fnSuccess={(uuid) => {
                  navigate("/horses/"+uuid);
                  // getList();
                  // toggleModalNew(false);
                }}
                fnCancel={() => toggleModalNew(false)}
              />*/}
            </Overlay>
          ) : null}
        </Container>
      </div>
    </Layout>
  )
}

export default EventsList;
