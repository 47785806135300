import React from 'react'
import styled from 'styled-components'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Logo from './../../common/components/Logo'
import Socials from './Socials'

const Wrapper = styled.div`
  background-color: #053839;
  background-position: top left;
  background-repeat: repeat-x;

  mask-image: url(/patterns/border-mask.png);
  mask-position-x: 0px;
  mask-position-y: 0px;
  mask-repeat: repeat-x;
  mask-size: 150px;

  color: #e0e0e0;

  margin-top: -20px;
`;
const Top = styled.div`
  padding-top: 100px;
  padding-bottom: 40px;
`;
const Bottom = styled.div`
  padding-top: 20px;
  padding-bottom: 25px;
  text-align: center;
  border-top: 1px solid #ffffff;
`;

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <Top>
          <Row>
            <Col sm={3}>
              <Logo width="70%" inverse /><br />
              Equinozio ODV<br />
              Email: info@equinozio-odv.it<br />
              C.F.: 93069130206<br /><br />
              <Socials inverse />
            </Col>
            <Col sm={3}></Col>
            <Col sm={3}></Col>
            <Col sm={3}></Col>
          </Row>
        </Top>
        <Bottom>©{(new Date().getFullYear())} Equinozio ODV | Aiutaci a salvare chi non può salvarsi da solo!</Bottom>
      </Container>
    </Wrapper>
  )
}

export default Footer
