import React, {useState, useEffect, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';

import DatabaseService from '../../services/DatabaseService';

import Layout from './../layout/Layout';

import Container from '../../common/components/Container';
import Row from '../../common/components/Row';
import Col from '../../common/components/Col';
import Loading from '../../common/components/Loading';
// import Cta from '../../common/components/Cta';

import EventInfo from '../forms/EventInfo';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 40px;
`;
const Title = styled.div`
  font-family: 'Skranji',cursive;
  font-size: 30px;
`;

const EventsSingle = ({layout}) => {
  const {slug} = useParams();
  const [item, setItem] = useState();


  const getItem = useCallback(() => {
    DatabaseService.getEvent(slug).then((response) => {
      if(response.success){
        setItem(response.data);
      }else{
        console.log(response.error);
      }
    });
  }, [slug]);

  useEffect(() => {
    getItem();
  }, [slug, getItem]);

  if(item){
    return (
      <Layout layout={layout}>
        <Container>
          
          
         <Header>
            <Title>Evento: {item.name_it}</Title>    
          </Header>

          <Row>
            <Col>
              <EventInfo
                item={item}
                refreshItem={() => getItem()}
              />
            </Col>
          </Row>

        </Container>


      </Layout>
    )
  }else{
    return <Loading />;
  }
}

export default EventsSingle;
