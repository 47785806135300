import { initializeApp } from "@firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword  } from "@firebase/auth";

import {firebaseConfig} from './../config';

class AuthService {
  constructor() {
    this.app = initializeApp(firebaseConfig);
    this.auth = getAuth(this.app);
  }

  registration(email, password){
    return new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(this.auth, email, password).then((response) => {
        localStorage.setItem("equinozioodvUser", JSON.stringify(response.user));
        let res = {};
        res.success = true;
        res.data = response.user;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  login(email, password){
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword (this.auth, email, password).then((response) => {
        localStorage.setItem("equinozioodvUser", JSON.stringify(response.user));
        let res = {};
        res.success = true;
        res.data = response.user;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  logout(){
    localStorage.setItem("equinozioodvUser", null);
    // localStorage.setItem("dcgcCollection", null);
    // return firebase.auth().signOut();
    // // console.log("logout");
    // // return new Promise((resolve, reject) => {
    // //   firebase.auth().signOut().then(() => {
    // //     console.log("OK");
    // //     resolve(true);
    // //   }).catch((error) => {
    // //     console.log(error);
    // //     reject(error);
    // //   });
    // // });
  }

  state(){
    // return new Promise((resolve, reject) => {
    //   firebase.auth().onAuthStateChanged((user) => {
    //     if (user) {
    //       resolve(user);
    //       // User is signed in, see docs for a list of available properties
    //       // https://firebase.google.com/docs/reference/js/firebase.User
    //       var uid = user.uid;
    //       // ...
    //     } else {
    //       resolve(null);
    //       // User is signed out
    //       // ...
    //     }
    //   });
    // });
  }

}

let instance = new AuthService();
export default instance;
