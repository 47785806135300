import React, {useState, useEffect, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';

import DatabaseService from '../../services/DatabaseService';

import Layout from './../layout/Layout';

import Container from '../../common/components/Container';
import Row from '../../common/components/Row';
import Col from '../../common/components/Col';
import Card from '../../common/components/Card';
import Loading from '../../common/components/Loading';
import Button from '../../common/components/Button';
import Input from '../../common/components/Input';
import Select from '../../common/components/Select';
import Checkbox from '../../common/components/Checkbox';
import Textarea from '../../common/components/Textarea';
import Success from '../../common/components/Success';
import Cta from '../../common/components/Cta';


const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 40px;
`;
const Title = styled.div`
  font-family: 'Skranji',cursive;
  font-size: 30px;
`;
const CardHeaderTitle = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #303030;
  margin-bottom: 5px;
`;
const ErrorMessage = styled.div`
  height: 30px;
  color: red;
`;

const HorsesSingle = ({layout}) => {
  const {slug} = useParams();
  const [item, setItem] = useState();

  const [disabled, setDisabled] = useState(true);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    // if(!item.name || item.item.length === 0){
    //   valid = false;
    // }
    setDisabled(!valid);
  }, [item]);


  const getItem = useCallback(() => {
    DatabaseService.getHorse(slug).then((response) => {
      if(response.success){
        setItem(response.data);
      }else{
        console.log(response.error);
      }
    });
  }, [slug]);

  const updateItem = useCallback(() => {
    setErrorMessage("");
    DatabaseService.updateHorse(slug, item).then((response) => {
      if(response.success){
        setSuccessMessage("Dati salvati correttamente!");
      }else{
        setErrorMessage(response.error);
        console.log(response.error);
      }
    });
  }, [slug, item]);

  useEffect(() => {
    getItem();
  }, [slug, getItem]);

  if(item){
    return (
      <Layout layout={layout}>
        <Container>
          
          <Header>
            <Title>Cavalli: {item.name}</Title>    
          </Header>

          <Row>
            <Col>
              <Card
                headerLeft={
                  <>
                    <CardHeaderTitle>Informazioni Anagrafiche</CardHeaderTitle>
                  </>
                }
              >
                <Row>
                  <Col widthmd="6">
                    <Input
                      label="Nome"
                      width="100%"
                      value={item.name}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.name = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Input
                      label="Nome sul documento"
                      width="100%"
                      value={item.docname}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.docname = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Select
                      label="Specie"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "", value: null},
                        {name: "Cavallo", value: "Cavallo"},
                        {name: "Asino", value: "Asino"},
                        {name: "Altro", value: "Altro"}
                      ]}
                      selected={item.species}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.species = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Input
                      label="Razza"
                      width="100%"
                      value={item.breed}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.breed = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Select
                      label="Sesso"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "", value: null},
                        {name: "Femmina", value: "Femmina"},
                        {name: "Castrone", value: "Castrone"},
                        {name: "Stallone", value: "Stallone"},
                        {name: "Maschio", value: "Maschio"}
                      ]}
                      selected={item.sex}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.sex = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Input
                      label="Mantello"
                      width="100%"
                      value={item.coat}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.coat = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="4">
                    <Input
                      label="Data di nascita"
                      width="100%"
                      value={item.birthday}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.birthday = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="4">
                    <Input
                      label="Luogo di nascita"
                      width="100%"
                      value={item.birthplace}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.birthplace = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="4">
                    <Select
                      label="Deceduto"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "", value: 0},
                        {name: "Sì", value: 1},
                        {name: "No", value: -1}
                      ]}
                      selected={item.isDeceased}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.isDeceased = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Input
                      label="Tipo libretto"
                      width="100%"
                      value={item.doctype}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.doctype = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Input
                      label="N° Libretto"
                      width="100%"
                      value={item.docnumber}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.docnumber = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Input
                      label="N° Microchip"
                      width="100%"
                      value={item.chipnumber}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.chipnumber = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Input
                      label="UELN"
                      width="100%"
                      value={item.uelnnumber}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.uelnnumber = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  {/* <Col widthmd="6">
                    <Checkbox
                      label="Emergency"
                      selected={emergency}
                      onChange={(value) => setEmergency(value)}
                    />
                  </Col> */}
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                headerLeft={
                  <>
                    <CardHeaderTitle>Stato</CardHeaderTitle>
                  </>
                }
              >
                <Row>
                  
                  <Col widthmd="3">
                    <Select
                      label="Equinozio"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "", value: 0},
                        {name: "Sì", value: 1},
                        {name: "No", value: -1}
                      ]}
                      selected={item.isEquinozio}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.isEquinozio = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="3">
                    <Select
                      label="Iscritto in BDN"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "", value: 0},
                        {name: "Sì", value: 1},
                        {name: "No", value: -1}
                      ]}
                      selected={item.bdn}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.bdn = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="3">
                    <Select
                      label="DPA"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "", value: 0},
                        {name: "Sì", value: 1},
                        {name: "No", value: -1}
                      ]}
                      selected={item.dpa}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.dpa = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="3">
                    <Select
                      label="Decesso dichiarato regolarmente"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "", value: 0},
                        {name: "Sì", value: 1},
                        {name: "No", value: -1}
                      ]}
                      selected={item.isDeceasedReported}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.isDeceasedReported = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="4">
                    <Textarea
                      label="Stabulazione reale"
                      width="100%"
                      value={item.barn}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.barn = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="2">
                    <Input
                      label="Lat,Lng reale"
                      width="100%"
                      value={item.latlng}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.latlng = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="3">
                    <Input
                      label="Codice Stalla in BDN"
                      width="100%"
                      value={item.barncode}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.barncode = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="3">
                    <Select
                      label="Stallato correttamente in BDN"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "", value: 0},
                        {name: "Sì", value: 1},
                        {name: "No", value: -1}
                      ]}
                      selected={item.isCorrectBarncode}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.isCorrectBarncode = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Textarea
                      label="Intestatario (se diverso da Equinozio)"
                      width="100%"
                      value={item.owner}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.owner = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="3">
                    <Select
                      label="Intestato correttamente"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "", value: 0},
                        {name: "Sì", value: 1},
                        {name: "No", value: -1}
                      ]}
                      selected={item.property}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.property = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="3">
                  </Col>
                  <Col widthmd="12">
                    <Textarea
                      label="Note"
                      width="100%"
                      value={item.notes}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.notes = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                headerLeft={
                  <>
                    <CardHeaderTitle>Adozione</CardHeaderTitle>
                  </>
                }
              >
                <Row>
                  <Col widthmd="3">
                    <Select
                      label="In adozione"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "", value: 0},
                        {name: "Sì", value: 1},
                        {name: "No", value: -1}
                      ]}
                      selected={item.isAdopted}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.isAdopted = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  {item.isAdopted === 1? 
                    <>
                      <Col widthmd="3">
                        <Textarea
                          label="Adottante"
                          width="100%"
                          value={item.adopter}
                          onChange={(val) => {
                            let tempItem = {...item};
                            tempItem.adopter = val;
                            setItem(tempItem);
                          }}
                        />
                      </Col>
                      <Col widthmd="3">
                        <Textarea
                          label="Telefono adottante"
                          width="100%"
                          value={item.adopterPhone}
                          onChange={(val) => {
                            let tempItem = {...item};
                            tempItem.adopterPhone = val;
                            setItem(tempItem);
                          }}
                        />
                      </Col>
                      <Col widthmd="3">
                        <Textarea
                          label="Email adottante"
                          width="100%"
                          value={item.adopterEmail}
                          onChange={(val) => {
                            let tempItem = {...item};
                            tempItem.adopterEmail = val;
                            setItem(tempItem);
                          }}
                        />
                      </Col>
                      <Col widthmd="3">
                        <Select
                          label="Adozione contrattualizzata"
                          width="100%"
                          searchable={false}
                          values={[
                            {name: "", value: 0},
                            {name: "Sì", value: 1},
                            {name: "No", value: -1}
                          ]}
                          selected={item.isAdoptionContractualized}
                          onChange={(val) => {
                            let tempItem = {...item};
                            tempItem.isAdoptionContractualized = val;
                            setItem(tempItem);
                          }}
                        />
                      </Col>
                      <Col widthmd="3">
                        <Input
                          label="Originale contratto presso"
                          width="100%"
                          value={item.adoptionContractLocation}
                          onChange={(val) => {
                            let tempItem = {...item};
                            tempItem.adoptionContractLocation = val;
                            setItem(tempItem);
                          }}
                        />
                      </Col>
                      <Col widthmd="3">
                        <Input
                          label="Data contratto"
                          width="100%"
                          value={item.adoptionContractDate}
                          onChange={(val) => {
                            let tempItem = {...item};
                            tempItem.adoptionContractDate = val;
                            setItem(tempItem);
                          }}
                        />
                      </Col>
                      <Col widthmd="3">
                        <Textarea
                          label="Stabulazione contrattuale"
                          width="100%"
                          value={item.barnAdoptionContract}
                          onChange={(val) => {
                            let tempItem = {...item};
                            tempItem.barnAdoptionContract = val;
                            setItem(tempItem);
                          }}
                        />
                      </Col>
                      <Col widthmd="3">
                        <Input
                          label="Codice Stalla contrattuale"
                          width="100%"
                          value={item.barncodeAdoptionContract}
                          onChange={(val) => {
                            let tempItem = {...item};
                            tempItem.barncodeAdoptionContract = val;
                            setItem(tempItem);
                          }}
                        />
                      </Col>
                      <Col widthmd="3">
                        Stabulazione in BDN regolare secondo contratto:&nbsp;
                        {item.isCorrectBarncode === 1 && item.barncode === item.barncodeAdoptionContract ? "Sì" : "No"}
                      </Col>
                      <Col widthmd="12">
                        <Textarea
                          label="Note"
                          width="100%"
                          value={item.notesAdoptionContract}
                          onChange={(val) => {
                            let tempItem = {...item};
                            tempItem.notesAdoptionContract = val;
                            setItem(tempItem);
                          }}
                        />
                      </Col>
                      <Col widthmd="6">
                      </Col>
                    </> 
                  : null}
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                headerLeft={
                  <>
                    <CardHeaderTitle>Sito web</CardHeaderTitle>
                  </>
                }
              >
                <Row>
                  <Col widthmd="6">
                    <Select
                      label="Tipologia"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "", value: null},
                        {name: "Residente dell'Oasi", value: "Residente dell'Oasi"},
                        {name: "In cerca di casa", value: "In cerca di casa"},
                        {name: "Adottato", value: "Adottato"},
                        {name: "Deceduto", value: "Deceduto"},
                        {name: "Altro", value: "Altro"}
                      ]}
                      selected={item.status}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.status = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Checkbox
                      label="Mostra sul sito"
                      selected={item.showWeb}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.showWeb = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="4">
                    <Textarea
                      label="Frase"
                      width="100%"
                      value={item.claim}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.claim = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="4">
                    <Textarea
                      label="Frase IT"
                      width="100%"
                      value={item.claim_it}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.claim_it = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="4">
                    <Textarea
                      label="Frase EN"
                      width="100%"
                      value={item.claim_en}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.claim_en = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="4">
                    <Textarea
                      label="Descrizione breve"
                      width="100%"
                      value={item.short}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.short = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="4">
                    <Textarea
                      label="Descrizione breve IT"
                      width="100%"
                      value={item.short_it}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.short_it = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="4">
                    <Textarea
                      label="Descrizione breve EN"
                      width="100%"
                      value={item.short_en}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.short_en = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="4">
                    <Textarea
                      label="Descrizione lunga"
                      width="100%"
                      value={item.description}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.description = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="4">
                    <Textarea
                      label="Descrizione lunga IT"
                      width="100%"
                      value={item.description_it}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.description_it = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="4">
                    <Textarea
                      label="Descrizione lunga EN"
                      width="100%"
                      value={item.description_en}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.description_en = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Checkbox
                      label="Emergenza"
                      selected={item.emergency}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.emergency = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Input
                      label="Localizzazione"
                      width="100%"
                      value={item.location}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.location = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                </Row>
                <hr/>
                <Row>
                  <Col widthmd="4">
                    <Input
                      label="Pagamento donazione Stripe"
                      width="100%"
                      value={item.stripeDonationLink}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.stripeDonationLink = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="4">
                    <Input
                      label="Causale donazione"
                      width="100%"
                      value={item.donationReason}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.donationReason = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="4">
                    <Input
                      label="Immagine campagna"
                      width="100%"
                      value={item.donationImageSlug}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.donationImageSlug = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="4">
                    <Textarea
                      label="Descrizione donazione IT"
                      width="100%"
                      value={item.donationDescription_it}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.donationDescription_it = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="4">
                    <Textarea
                      label="Descrizione donazione EN"
                      width="100%"
                      value={item.donationDescription_en}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.donationDescription_en = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                </Row>
                <hr/>
                <Row>
                  <Col widthmd="6">
                    <Input
                      label="Cerchia Stripe"
                      width="100%"
                      value={item.stripeCircleId}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.stripeCircleId = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Input
                      label="Pagamento cerchia Stripe"
                      width="100%"
                      value={item.stripeCircleLink}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.stripeCircleLink = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          
          <ErrorMessage>
            <br/>{errorMessage}<br/>
          </ErrorMessage>

          <div style={{"float": "right"}}>
            <Button 
              onClick={() => updateItem()}
              variant="default-o"
              text="Salva"
              icon="save"
              disabled={disabled}
            />
          </div>
          <Cta 
            variant="default-o"
            text="Indietro"
            link={"/admin/horses"}
            icon="arrow_backward"
          />
          

        </Container>

        {successMessage ?
          <Success fnClose={() =>setSuccessMessage(false)} message={successMessage} />
        : null}

      </Layout>
    )
  }else{
    return <Loading />;
  }
}

export default HorsesSingle;
