import React, {useState, useCallback, useEffect} from 'react';
import styled from 'styled-components';

import DatabaseService from '../../services/DatabaseService';

// import {getConfig} from './../../../config';

// import {BITTERSWEET, OzContainer, OzBreadcrumbs, OzCard, OzButton, OzRow, OzCol, OzInput, OzSelect, OzUserTag} from '@ozwol/webui';

// import ProjectsService from './../../../services/ProjectsService';

import Container from './../../common/components/Container';
import Row from './../../common/components/Row';
import Col from './../../common/components/Col';
import Card from './../../common/components/Card';
import Input from './../../common/components/Input';
import Button from './../../common/components/Button';


const Error = styled.div`
  height: 30px;
  color: red;
`;
const Title = styled.div`
  font-family: 'Skranji',cursive;
  font-size: 30px;
`;

const NewHorse = ({
  fnSuccess,
  fnCancel
}) => {
  const [slug, setSlug] = useState("");
  const [item, setItem] = useState();
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    let composedItem = {};
    let valid = true;

    composedItem.slug = slug;
    if(slug.length === 0){
      valid = false;
    }

    setItem(composedItem);
    setDisabled(!valid);
  }, [
    slug
  ]);

  const addItem = useCallback((item) => {
    setError("");
    console.log(item);
    DatabaseService.addHorse(slug, item).then((response) => {
      if(response.success){
        fnSuccess(slug);
      }else{
        setError(response.error);
        console.log(response.error);
      }
    });
  }, [slug, fnSuccess]);

  return (
    <Container size="medium">
      <Card
        footerLeft={    
          <Button 
            onClick={() => fnCancel()}
            variant="default-o"
            text="Annulla"
            icon="close"
          />
        }
        footerRight={    
          <Button 
            onClick={() => addItem(item)}
            variant="default-o"
            text="Salva"
            icon="save"
            disabled={disabled}
          />
        }
      >
       
          
        <Title>Aggiungi cavallo</Title>    

        <Row>
          <Col widthmd="12">
            <Input
              label="Slug"
              width="100%"
              value={slug}
              placeholder="Slug"
              onChange={(val) => setSlug(val)}
              required
            />
          </Col>
        </Row>
        <Error>
          <br/>{error}<br/>
        </Error>
      </Card>
    </Container>
  )
}

export default NewHorse;
