import React  from 'react';
import styled from 'styled-components';

import Container from './../../common/components/Container';

const Wrapper = styled.footer`
  text-align: center;
  color: #a5a5a5;
  font-size: 13px;
`;

const Nav = styled.a`
  color: inherit;
  font-weight: 400;

  &:hover{
    text-decoration: underline;
    color: inherit;
  }
`;

const Footer = () => {
  const version = require('./../../../package.json').version;
  return (
    <Wrapper>
      <Container>
        Powered by <Nav href="https://www.narcolepticvolcano.it" target="_blank">narcolepticvolcano</Nav> - v. {version}
      </Container>
    </Wrapper>
  )
}

export default Footer;
