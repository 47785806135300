import React, {useLayoutEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';

import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";

import {LAYOUT_SM, LAYOUT_XS } from '../../config';

const DETAIL_WIDTH = "515px";

const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
/*
const Top = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`;
*/

const Wrapper = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  overflow: hidden;
`;
const Side = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: ${props => props.isMobile ? "calc(100% - 68px)" : "255px"};
  width: ${props => props.isMobile ? "calc(100% - 68px)" : "255px"};
  overflow: hidden;
  background: #fcdebe;
  max-width: ${props => props.showMenu ? "100vw" : "0vw"};
  transition: all 0.25s ease-in-out;
`;
const Content = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
`;

const Head = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 80px;
  z-index: 2;
`;
const ScrollContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
`;
const ScrollerPage = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 60px 0px 20px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.25s;
`;
const Page = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;
const ScrollerDetail = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: ${DETAIL_WIDTH};
  padding: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.25s;
  box-shadow: 0 0 10px 1px #4466f20d;
  overflow-x: visible;
  overflow-y: scroll;
`;
const Detail = styled.div`
  flex-grow: 1;
  flex-shrink: 1;

`;
const Foot = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 60px;
`;

const Layout = ({ layout, detail, children }) => {
  const [showMenu, setShowMenu] = useState(JSON.parse(localStorage.getItem("equinozioodvPreferences")) && JSON.parse(localStorage.getItem("equinozioodvPreferences")).hasOwnProperty("showMenu") ? JSON.parse(localStorage.getItem("equinozioodvPreferences")).showMenu : !(layout === LAYOUT_SM || layout === LAYOUT_XS));
  // let isMobile = layout === LAYOUT_SM || layout === LAYOUT_XS;

  const refScrollerPage = useRef();
  const refScrollerDetail = useRef();
  const location = useLocation();
  // useLayoutEffect(() => { //TODO CHECK
  //   refScrollerPage.current.scrollTo(0, 0);
  // }, [location.pathname && !detail]);
  useLayoutEffect(() => {
    refScrollerDetail.current.scrollTo(0, 0);
  }, [location.pathname]);
  // document.documentElement.setAttribute('lang', lang);

  return (
    <Container>
      {/*<Top>
        
      </Top>*/}
      <Wrapper>
        <Side isMobile={layout === LAYOUT_XS} showMenu={showMenu}>
          <Sidebar />
        </Side>
        <Content>
          <Head>
            <Header callbackTogglerMenu={() => {
              setShowMenu(!showMenu);
              let temp = {}
              if(localStorage.getItem("equinozioodvPreferences")){
                temp = JSON.parse(localStorage.getItem("equinozioodvPreferences"));
              }
              temp.showMenu = !showMenu;
              localStorage.setItem("equinozioodvPreferences", JSON.stringify(temp));
            }}/>
          </Head>
          <ScrollContainer>
            <ScrollerPage ref={refScrollerPage}>
              <Page>
                {children}
              </Page>
              <Foot>
                <Footer />
              </Foot>
            </ScrollerPage>
            <ScrollerDetail ref={refScrollerDetail} style={{maxWidth: detail ? DETAIL_WIDTH : "0px"}}>
              {detail ?
                <Detail>
                  {detail}
                </Detail>
              : null }
            </ScrollerDetail>
          </ScrollContainer>
        </Content>
      </Wrapper>
    </Container>
  )
}

export default Layout;
