import React, {useState} from "react";
import styled from 'styled-components';
import Icon from "./Icon.js";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 20px 30px;
  ${props => props.variant === "default" ? `
    background-color: #fff;
    box-shadow: 0px 2px 16px #C1CCE838;
  ` : props.variant === "charcoal" ? `
    background-color: #000000;
    box-shadow: 0px 2px 16px #20283362;
  ` : null };

  height: ${props => props.height};


  &, & * {
    box-sizing: border-box;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.isCollapsed ? 0 : 20}px;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 13px;
  font-weight: 600;

  ${props => props.hasSeparator ? `
    padding-bottom: 10px;
    border-bottom: 1px solid transparent;
  ` : null}

  ${props => props.variant === "default" ? `
    color: #303030;
    border-color: #70707033;
  ` : props.variant === "charcoal" ? `
    color: #ffffff;
    border-color: #ffffff33;
  ` : null };

`;
const HeaderLeft = styled.div`
  padding-left: ${props => props.collapsible ? 30 : 0}px;
  position: relative;
`;
const HeaderCenter = styled.div`
  flex-grow: 1;
  padding: 0px 20px;
  text-align: center;
`;
const HeaderRight = styled.div`
`;

const Body = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 12px;
  font-weight: 400;

  & hr{
    border: 0px;
    border-top: 1px solid transparent;
    margin: 10px 0px;
  }

  ${props => props.variant === "default" ? `
    color: #303030;
    border-color: #70707033;
  ` : props.variant === "charcoal" ? `
    color: #ffffff;
    border-color: #ffffff33;
  ` : null };
`;
const Footer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 12px;
  font-weight: 400;

  ${props => props.hasSeparator ? `
    padding-top: 10px;
    border-bottom: 1px solid transparent;
  ` : null}

  ${props => props.variant === "default" ? `
    color: #303030;
    border-color: #70707033;
  ` : props.variant === "charcoal" ? `
    color: #ffffff;
    border-color: #ffffff33;
  ` : null };
`;
const FooterLeft = styled.div`
`;
const FooterCenter = styled.div`
  flex-grow: 1;
  padding: 0px 20px;
  text-align: center;
`;
const FooterRight = styled.div`
`;

const Toggle = styled.div`
  position: absolute;
  top: calc(50% - 10px);
  left: 0px;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  transform: rotate(${props => props.isCollapsed ? -90 : 0}deg);
  transition: all 0.25s ease-in-out;
  cursor: pointer;
`;


const Card = ({
    variant = "default",
    height = "auto",
    headerLeft = null,
    headerCenter = null,
    headerRight = null,
    headerLeftCollapsed = headerLeft,
    headerCenterCollapsed = headerCenter,
    headerRightCollapsed = headerRight,
    footerLeft = null,
    footerCenter = null,
    footerRight = null,
    headerSeparator = false,
    footerSeparator = false,
    collapsible = false,
    collapsed = false,
    children
  }) => {

  const [isCollapsed, setCollapsed] = useState(collapsed);

  let iconColor = "black";
  switch (variant) {
    case "charcoal":
      iconColor = "#FFFFFF";
      break;
    default:
      break;
  }

  return (
    <Wrapper height={height} variant={variant}>
      {headerLeft || headerRight ?
        <Header variant={variant} hasSeparator={!collapsible || !isCollapsed ? headerSeparator : false} isCollapsed={isCollapsed}>
          <HeaderLeft collapsible={collapsible}>
            {collapsible ?
              <Toggle onClick={() => setCollapsed(!isCollapsed)} isCollapsed={isCollapsed}>
                <Icon name={"arrow-down"} color={iconColor} />
              </Toggle>
            : null}{isCollapsed ? headerLeftCollapsed : headerLeft}
          </HeaderLeft>
          {headerCenter || (isCollapsed && headerCenterCollapsed) ?
            <HeaderCenter>
              {isCollapsed ? headerCenterCollapsed : headerCenter}
            </HeaderCenter>
          : null}
          <HeaderRight>
            {isCollapsed ? headerRightCollapsed : headerRight}
          </HeaderRight>
        </Header>
      : null }

      {!isCollapsed ?
        <>
          <Body variant={variant}>
            {children}
          </Body>

          {footerLeft || footerRight ?
            <Footer variant={variant} hasSeparator={footerSeparator}>
              <FooterLeft>
                {footerLeft}
              </FooterLeft>
              {footerCenter ?
                <FooterCenter>
                  {footerCenter}
                </FooterCenter>
              : null }
              <FooterRight>
                {footerRight}
              </FooterRight>
            </Footer>
          : null }
        </>
      : null }
    </Wrapper>
  )
}

export default Card;
