import React from 'react';
import styled from 'styled-components';

const Wrapper  = styled.div`
`;
const Item  = styled.a`
  display: inline-block;
  width: 25px;
  height: 25px;
  background-color: ${props => props.inverse ? "#e0e0e0" : "#333338"};
  mask: url("/icons/social-${props => props.social}.svg") no-repeat 100% 100%;
  mask-size: 100% 100%;
  margin-right: 12px;

  &:hover{
    background-color: #cf3118;
  }
`;

const Socials = ({ inverse }) => {
  return (
    <Wrapper>
      <Item href="https://www.instagram.com/equinozioodv" target="_blank" social="instagram" inverse={inverse} />
      <Item href="https://www.facebook.com/equinozioodv" target="_blank" social="facebook" inverse={inverse} />
      <Item href="mailto:info@equinozio-odv.it" target="_blank" social="email" inverse={inverse} />
    </Wrapper>
  )
}



export default Socials
