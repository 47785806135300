import React from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Image from './../../common/components/Image';


const Wrapper = styled(Link)`
  position: relative;
  display: block;
`;
const Overlay = styled.div`
  opacity: 0;

  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;

  background: rgb(19,70,17);
  background: linear-gradient(200deg, rgba(19,70,17,0.35) 0%, rgba(207,49,24,1) 95%);

  &:hover{
    opacity: 1;
  }

  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;

`;
const Typo = styled.div`
  font-family: 'Skranji', cursive;
  font-size: 30px;
  color: #ffffff;
`;


const EventThumb = ({ item }) => {
  let { lang } = useParams();
  return (
    <Wrapper to={(lang === "it" ? "/it/eventi/" : "/en/events/") + item.slug}>
      <Image src={"events/" + item.slug + "-thumb.png"} alt={item.name + " organizzato da Equinozio ODV"} />
      <Overlay>
        <Typo>{item.name}</Typo>
      </Overlay>
    </Wrapper>
  )
}

export default EventThumb;
