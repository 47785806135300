import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { useLocation } from "react-router-dom";

import Topbar from './Topbar';
import Footer from './Footer';
import Showcase from './Showcase';

import DatabaseService from './../../services/DatabaseService';

import {LAYOUT_SM, LAYOUT_XS, BREAKPOINT_SM } from '../../config';

const Wrapper = styled.div`
  margin-top: 100px;
  @media (max-width: ${BREAKPOINT_SM}px){
    margin-top: 80px;
  }
  width: 100vw;
  overflow-x: hidden;
`;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Layout = ({ layout, children }) => {
  const [horses, setHorses] = useState([]);

  useEffect(() => {
    DatabaseService.getHorses({order: "random", showWeb: true}).then((response) => {
      if(response.success){
        setHorses(response.data);
      }else{
        console.log(response.error);
      }
    });    
  }, []);

  return (
    <Wrapper>
      <ScrollToTop />
      <Topbar layout={layout} />
      {children}
      <Showcase
        list={horses}
        rows={1}
        cols={layout === LAYOUT_XS ? 4 : layout === LAYOUT_SM ? 8 : 12}
      />
      <Footer />
    </Wrapper>
  )
}

export default Layout
