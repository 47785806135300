import React, {useState, useEffect, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';

import DatabaseService from '../../services/DatabaseService';

import Layout from '../layout/Layout';

import Container from '../../common/components/Container';
import Row from '../../common/components/Row';
import Col from '../../common/components/Col';
import Card from '../../common/components/Card';
import Loading from '../../common/components/Loading';
import Button from '../../common/components/Button';
import Input from '../../common/components/Input';
import Select from '../../common/components/Select';
import Cta from '../../common/components/Cta';
import Checkbox from '../../common/components/Checkbox';
import Textarea from '../../common/components/Textarea';
import Success from '../../common/components/Success';


const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 40px;
`;
const Title = styled.div`
  font-family: 'Skranji',cursive;
  font-size: 30px;
`;
const CardHeaderTitle = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #303030;
  margin-bottom: 5px;
`;
const ErrorMessage = styled.div`
  height: 30px;
  color: red;
`;

const AdoptionApplicationsSingle = ({layout}) => {
  const {slug} = useParams();
  const [item, setItem] = useState();

  const [disabled, setDisabled] = useState(true);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [horses, setHorses] = useState([]);

  useEffect(() => {
    DatabaseService.getHorses({order: "name", status: "In cerca di casa", showWeb: true}).then((response) => {
      if(response.success){
        setHorses(response.data);
      }else{
        console.log(response.error);
      }
    });
  }, []);

  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    // if(!item.name || item.item.length === 0){
    //   valid = false;
    // }
    setDisabled(!valid);
  }, [item]);


  const getItem = useCallback(() => {
    DatabaseService.getAdoptionApplication(slug).then((response) => {
      if(response.success){
        setItem(response.data);
      }else{
        console.log(response.error);
      }
    });
  }, [slug]);

  const updateItem = useCallback(() => {
    setErrorMessage("");
    DatabaseService.updateAdoptionApplication(slug, item).then((response) => {
      if(response.success){
        setSuccessMessage("Dati salvati correttamente!");
      }else{
        setErrorMessage(response.error);
        console.log(response.error);
      }
    });
  }, [slug, item]);

  useEffect(() => {
    getItem();
  }, [slug, getItem]);

  if(item){
    return (
      <Layout layout={layout}>
        <Container>
          
          <Header>
            <Title>Candidatura all'adozione: {item.familyName + " " + item.givenName}</Title>    
          </Header>

          <Row>
          <Col>
              <Card
                headerLeft={
                  <>
                    <CardHeaderTitle>Informazioni generali</CardHeaderTitle>
                  </>
                }
              >
                <Row>
                  <Col widthmd="6">
                    <Select
                      label="Valutazione"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "Da valutare", value: ""},
                        {name: "Promosso", value: "Promosso"},
                        {name: "Sospeso", value: "Sospeso"},
                        {name: "Bocciato", value: "Bocciato"}
                      ]}
                      selected={item.evaluation}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.evaluation = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Select
                      label="Preaffido"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "", value: ""},
                        {name: "Da fare", value: "Da fare"},
                        {name: "Fatto", value: "Fatto"},
                        {name: "Non necessario", value: "Non necessario"}
                      ]}
                      selected={item.homecheck}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.homecheck = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd={12}>
                    <Textarea
                      label="Note interne"
                      width="100%"
                      value={item.internalNotes}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.internalNotes = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                headerLeft={
                  <>
                    <CardHeaderTitle>Informazioni generali</CardHeaderTitle>
                  </>
                }
              >
                <Row>
                  <Col widthmd="6">
                    <Input
                      label="Nome"
                      width="100%"
                      value={item.givenName}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.givenName = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Input
                      label="Cognome"
                      width="100%"
                      value={item.familyName}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.familyName = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Input
                      label="Telefono"
                      width="100%"
                      value={item.phone}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.phone = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Input
                      label="Email"
                      width="100%"
                      value={item.email}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.email = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Select
                      label="Regione"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "Abruzzo", value: "Abruzzo"},
                        {name: "Basilicata", value: "Basilicata"},
                        {name: "Calabria", value: "Calabria"},
                        {name: "Campania", value: "Campania"},
                        {name: "Emilia-Romagna", value: "Emilia-Romagna"},
                        {name: "Friuli-Venezia Giulia", value: "Friuli-Venezia Giulia"},
                        {name: "Lazio", value: "Lazio"},
                        {name: "Liguria", value: "Liguria"},
                        {name: "Lombardia", value: "Lombardia"},
                        {name: "Marche", value: "Marche"},
                        {name: "Molise", value: "Molise"},
                        {name: "Piemonte", value: "Piemonte"},
                        {name: "Puglia", value: "Puglia"},
                        {name: "Sardegna", value: "Sardegna"},
                        {name: "Sicilia", value: "Sicilia"},
                        {name: "Toscana", value: "Toscana"},
                        {name: "Trentino-Alto Adige", value: "Trentino-Alto Adige"},
                        {name: "Umbria", value: "Umbria"},
                        {name: "Valle d'Aosta", value: "Valle d'Aosta"},
                        {name: "Veneto", value: "Veneto"},
                        {name: "Estero", value: "Estero"}
                      ]}
                      selected={item.region}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.region = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    <Input
                      label="Cap"
                      width="100%"
                      value={item.zip}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.zip = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd="6">
                    Cavalli scelti:<br/>
                    {horses.map((horse) => {
                      return <>
                        <Checkbox 
                          label={horse.name}
                          selected={item.horses && item.horses.length > 0 && item.horses.indexOf(horse.slug) > -1}
                          onChange={(val) => {
                            let tempItem = {...item};
                            if(!tempItem.horses){
                              tempItem.horses = [];
                            }
                            if(val){
                              tempItem.horses.push(horse.slug);
                            }else{
                              tempItem.horses.splice(tempItem.horses.indexOf(horse.slug), 1);
                            }
                            setItem(tempItem);
                          }}
                        />
                      </>  
                    })}
                  </Col>
                  <Col widthmd="6">

                    Newsletter:<br/>
                    <Checkbox 
                      label={"Aggiornamenti dall'Oasi"}
                      selected={item.newsletterUpdates}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.newsletterUpdates = val;
                        setItem(tempItem);
                      }}
                    />
                    <Checkbox 
                      label={"Cavalli in cerca di casa"}
                      selected={item.newsletterAdoption}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.newsletterAdoption = val;
                        setItem(tempItem);
                      }}
                    />              
                    <Checkbox 
                      label={"Cavalli in cerca di adozioni a distanza, fide o mezzefide"}
                      selected={item.newsletterSupport}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.newsletterSupport = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                headerLeft={
                  <>
                    <CardHeaderTitle>Domande sulla vita del cavallo</CardHeaderTitle>
                  </>
                }
              >
                <Row>
                  <Col widthmd="12">
                    <Textarea
                      label="Indirizzo in cui andrebbe a vivere il cavallo"
                      width="100%"
                      value={item.horseAddress}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseAddress = val;
                        setItem(tempItem);
                      }}
                      
                    />
                  </Col>
                  <Col widthmd="6">
                    <Select
                      label="Il cavallo vivrebbe"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "In un maneggio", value: "In un maneggio"},
                        {name: "In un terreno di proprietà", value: "In un terreno di proprietà"},
                        {name: "In un terreno in affitto", value: "In un terreno in affitto"},
                        {name: "Altro", value: "Altro"}
                      ]}
                      selected={item.horsePlace}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horsePlace = val;
                        setItem(tempItem);
                      }}                      
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Textarea
                      label="Note"
                      width="100%"
                      value={item.horsePlaceNotes}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horsePlaceNotes = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Select
                      label="Il cavallo vivrebbe"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "Da solo", value: "Da solo"},
                        {name: "Con un solo altro cavallo", value: "Con un solo altro cavallo"},
                        {name: "Con diversi altri cavalli", value: "Con diversi altri cavalli"},
                        {name: "Insieme ad altri animali", value: "Insieme ad altri animali"},
                        {name: "Altro", value: "Altro"}
                      ]}
                      selected={item.horseFriends}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseFriends = val;
                        setItem(tempItem);
                      }}
                      
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Textarea
                      label="Note"
                      width="100%"
                      value={item.horseFriendsNotes}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseFriendsNotes = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Select
                      label="Il cavallo vivrebbe"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "In box", value: "In box"},
                        {name: "In box con paddock comunicante", value: "In box con paddock comunicante"},
                        {name: "In box con qualche ora al paddock", value: "In box con qualche ora al paddock"},
                        {name: "In paddock con capannina", value: "In paddock con capannina"},
                        {name: "Al pascolo", value: "Al pascolo"},
                        {name: "Altro", value: "Altro"}
                      ]}
                      selected={item.horseHouse}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseHouse = val;
                        setItem(tempItem);
                      }}
                      
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Textarea
                      label="Note"
                      width="100%"
                      value={item.horseHouseNotes}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseHouseNotes = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Select
                      label="Il cavallo avrebbe"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "Fieno sempre a disposizione", value: "Fieno sempre a disposizione"},
                        {name: "Fieno razionato distribuito ad orari fissi", value: "Fieno razionato distribuito ad orari fissi"},
                        {name: "Altro", value: "Altro"}
                      ]}
                      selected={item.horseFeeding}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseFeeding = val;
                        setItem(tempItem);
                      }}
                      
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Textarea
                      label="Note"
                      width="100%"
                      value={item.horseFeedingNotes}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseFeedingNotes = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Select
                      label="Il cavallo sarebbe"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "Ferrato davanti e dietro", value: "Ferrato davanti e dietro"},
                        {name: "Ferrato solo davanti", value: "Ferrato solo davanti"},
                        {name: "Scalzo", value: "Scalzo"},
                        {name: "Altro", value: "Altro"}
                      ]}
                      selected={item.horseFeet}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseFeet = val;
                        setItem(tempItem);
                      }}
                      
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Textarea
                      label="Note"
                      width="100%"
                      value={item.horseFeetNotes}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.horseFeetNotes = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                headerLeft={
                  <>
                    <CardHeaderTitle>Domande sulle attività col cavallo</CardHeaderTitle>
                  </>
                }
              >
                <Row>
                  <Col widthmd={6}>
                    <Textarea
                      label="Cosa vorrai fare col cavallo che adotti?"
                      width="100%"
                      value={item.humanActivities}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.humanActivities = val;
                        setItem(tempItem);
                      }}
                      
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Textarea
                      label="Qual è la tua esperienza nel mondo dei cavalli e dell'Equitazione?"
                      width="100%"
                      value={item.humanExperience}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.humanExperience = val;
                        setItem(tempItem);
                      }}
                      
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Textarea
                      label="Utilizzi un metodo/scuola/approccio in particolare?"
                      width="100%"
                      value={item.humanSchool}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.humanSchool = val;
                        setItem(tempItem);
                      }}
                      
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Textarea
                      label="Hai già avuto altri cavalli in passato a casa o in maneggio?"
                      width="100%"
                      value={item.humanPrevious}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.humanPrevious = val;
                        setItem(tempItem);
                      }}
                      
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Textarea
                      label="Li hai ancora? Se no, perché?"
                      width="100%"
                      value={item.humanWhy}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.humanWhy = val;
                        setItem(tempItem);
                      }}
                      
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col>
              <Card
                headerLeft={
                  <>
                    <CardHeaderTitle>Caratteristiche che cerchi nel cavallo</CardHeaderTitle>
                  </>
                }
              >
                <Row>
                  <Col widthmd={12}>
                    <Textarea
                      label="Quale caratteristica fisica è fondamentale o preferibile che il cavallo che adotti abbia e perché? (esempio: altezza, età ecc)"
                      width="100%"
                      value={item.desiredPhysics}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.desiredPhysics = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd={12}>
                    <Textarea
                      label="Quale caratteristica comportamentale è fondamentale o preferibile che il cavallo che adotti abbia e perché? (esempio: tranquillo, confidente ecc)"
                      width="100%"
                      value={item.desiredMental}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.desiredMental = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd={12}>
                    <Textarea
                      label="Quale attività è fondamentale o preferibile che il cavallo che adotti conosca già e perché? (esempio: già abituato a sella o mai messa, già abituato ad essere spazzolato ecc)"
                      width="100%"
                      value={item.desiredSkills}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.desiredSkills = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Select
                      label="Sei eventualmente disponibile ad adottare un cavallo con problematiche fisiche più o meno gravi?"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "Assolutamente no, solo cavalli perfettamente sani", value: "Assolutamente no, solo cavalli perfettamente sani"},
                        {name: "Forse, dipende dalla problematica", value: "Forse, dipende dalla problematica"},
                        {name: "Sì, se il problema non è troppo grave", value: "Sì, se il problema non è troppo grave"},
                        {name: "Sì, se il problema non compromette le attività che voglio fare col cavallo", value: "Si, se il problema non compromette le attività che voglio fare col cavallo"},
                        {name: "Assolutamente sì, adotto il cavallo che ne ha più bisogno", value: "Assolutamente sì, adotto il cavallo che ne ha più bisogno"}
                      ]}
                      selected={item.desiredHealth}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.desiredHealth = val;
                        setItem(tempItem);
                      }}
                      
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Textarea
                      label="Perché?"
                      width="100%"
                      value={item.desiredHealthNotes}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.desiredHealthNotes = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Select
                      label="Sei eventualmente disponibile ad adottare un cavallo con problematiche comportamentali più o meno gravi?"
                      width="100%"
                      searchable={false}
                      values={[
                        {name: "Assolutamente no, solo cavalli bravissimi", value: "Assolutamente no, solo cavalli bravissimi"},
                        {name: "Forse, dipende dalla problematica", value: "Forse, dipende dalla problematica"},
                        {name: "Sì, se il problema non è troppo grave", value: "Sì, se il problema non è troppo grave"},
                        {name: "Sì, se il problema non compromette le attività che voglio fare col cavallo", value: "Si, se il problema non compromette le attività che voglio fare col cavallo"},
                        {name: "Assolutamente sì, adotto il cavallo che ne ha più bisogno", value: "Assolutamente sì, adotto il cavallo che ne ha più bisogno"}
                      ]}
                      selected={item.desiredBrain}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.desiredBrain = val;
                        setItem(tempItem);
                      }}
                      
                    />
                  </Col>
                  <Col widthmd={6}>
                    <Textarea
                      label="Perché?"
                      width="100%"
                      value={item.desiredBrainNotes}
                      onChange={(val) => {
                        let tempItem = {...item};
                        tempItem.desiredBrainNotes = val;
                        setItem(tempItem);
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <ErrorMessage>
            <br/>{errorMessage}<br/>
          </ErrorMessage>

          <div style={{"float": "right"}}>
            <Button 
              onClick={() => updateItem()}
              variant="default-o"
              text="Salva"
              icon="save"
              disabled={disabled}
            />
          </div>
          <Cta 
            variant="default-o"
            text="Indietro"
            link={"/admin/adoption-applications"}
            icon="arrow_backward"
          />
          

        </Container>

        {successMessage ?
          <Success fnClose={() =>setSuccessMessage(false)} message={successMessage} />
        : null}

      </Layout>
    )
  }else{
    return <Loading />;
  }
}

export default AdoptionApplicationsSingle;
