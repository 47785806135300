import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';


import AnalyticsService from './../../services/AnalyticsService';

import Layout from './../layout/Layout';
import Section from './../layout/Section';
import Typo from './../../common/components/Typo';
import Showcase from './../layout/Showcase';
import Meta from './../layout/Meta';
// import NewsletterAvailability from '../layout/NewsletterAvailability';

import DatabaseService from './../../services/DatabaseService';

import {LAYOUT_SM, LAYOUT_XS } from '../../config';

const EventiList = ({layout}) => {
  let { lang } = useParams();
  const [eventsNext, setEventsNext] = useState([]);
  const [eventsPast, setEventsPast] = useState([]);

  useEffect(() => {
    AnalyticsService.init();  
  }, []);
  
  useEffect(() => {
    DatabaseService.getEvents({
      status: "next",
      order: "asc"
    }).then((response) => {
      if(response.success){
        setEventsNext(response.data);
      }else{
        console.log(response.error);
      }
    });
    DatabaseService.getEvents({
      status: "past",
      order: "desc"
    }).then((response) => {
      if(response.success){
        setEventsPast(response.data);
      }else{
        console.log(response.error);
      }
    });

    
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Eventi" : "Events"}
        languages={{"it": "eventi", "en":"events"}}
      />
      <Section>
        <Typo type="h1" align="center">{lang === "it" ? "Eventi" : "Events"}</Typo>
      </Section>
      <Section>
        <Typo type="h2" align="center">{lang === "it" ? "Prossimi eventi" : "Upcoming events"}</Typo>
        {/*Typo type="p" align="center">Presto disponibile.</Typo>*/}
        <Showcase
          type="events"
          list={eventsNext}
          cols={layout === LAYOUT_XS ? 2 : layout === LAYOUT_SM ? 3 : 6}
        />
      </Section>
      <Section variant="peach">
        <Typo type="h2" align="center">{lang === "it" ? "Eventi passati" : "Previous events"}</Typo>
        {/*Typo type="p" align="center">Presto disponibile.</Typo>*/}
        <Showcase
          type="events"
          list={eventsPast}
          cols={layout === LAYOUT_XS ? 2 : layout === LAYOUT_SM ? 3 : 6}
        />
      </Section>
    </Layout>
  )
}

export default EventiList
