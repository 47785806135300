import React, {useState, useEffect, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';

import AnalyticsService from './../../services/AnalyticsService';
import DatabaseService from '../../services/DatabaseService';

import Section from '../layout/Section';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Typo from '../../common/components/Typo';
import Meta from '../layout/Meta';

import Layout from '../layout/Layout';
import Padding from '../layout/Padding';
import Button from '../../common/components/Button';
import Input from '../../common/components/Input';
import Select from '../../common/components/Select';
import Textarea from '../../common/components/Textarea';
// import Checkbox from '../../common/components/Checkbox';

// const Label = styled.div`
//   width: 100%;
//   margin-top: 20px;
//   margin-bottom: 5px;
//   font-size: 12px;
//   font-weight: 300;
// `;
const ErrorMessage = styled.div`
  margin: 20px 0px;
  width: 100%;
  color: red;
  text-align: center;
`;

const SuccessMessage = styled.div`
  margin: 20px 0px;
  width: 100%;
  color: green;
  text-align: center;
`;

const ContattiRichiestaCessione = ({layout}) => {
  let { lang } = useParams();
  const [item, setItem] = useState({});

  const [disabled, setDisabled] = useState(true);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    if(!item.givenName || item.givenName.length === 0){
      valid = false;
    }
    if(!item.familyName || item.familyName.length === 0){
      valid = false;
    }
    if(!item.phone || item.phone.length === 0){
      valid = false;
    }
    if(!item.cause || item.cause.length === 0){
      valid = false;
    }
    if(!item.priority || item.priority.length === 0){
      valid = false;
    }

    if(!item.horseRegion || item.horseRegion.length === 0){
      valid = false;
    }
    if(!item.horseZip || item.horseZip.length === 0){
      valid = false;
    }
    if(!item.horseAddress || item.horseAddress.length === 0){
      valid = false;
    }
    
    if(!item.horseName || item.horseName.length === 0){
      valid = false;
    }
    if(!item.horseBirthdate || item.horseBirthdate.length === 0){
      valid = false;
    }
    if(!item.horseBreed || item.horseBreed.length === 0){
      valid = false;
    }
    if(!item.horseHeight || item.horseHeight.length === 0){
      valid = false;
    }
    
    if(!item.humanTime || item.humanTime.length === 0){
      valid = false;
    }
    if(!item.horseFriends || item.horseFriends.length === 0){
      valid = false;
    }
    if(!item.horseHouse || item.horseHouse.length === 0){
      valid = false;
    }
    if(!item.horseFeeding || item.horseFeeding.length === 0){
      valid = false;
    }
    if(!item.horseFeet || item.horseFeet.length === 0){
      valid = false;
    }
    if(!item.horseCurrentPhysical || item.horseCurrentPhysical.length === 0){
      valid = false;
    }
    if(!item.horsePastPhysical || item.horsePastPhysical.length === 0){
      valid = false;
    }
    if(!item.horseCurrentMental || item.horseCurrentMental.length === 0){
      valid = false;
    }
    if(!item.horsePastMental || item.horsePastMental.length === 0){
      valid = false;
    }
    if(!item.horsePersonality || item.horsePersonality.length === 0){
      valid = false;
    }
    if(!item.horseWithHumans || item.horseWithHumans.length === 0){
      valid = false;
    }
    if(!item.horseWithHorses || item.horseWithHorses.length === 0){
      valid = false;
    }
    
    
    setDisabled(!valid);
  }, [item]);

  const send = useCallback(() => {
    setErrorMessage("");
    item.date = Date.now();
    DatabaseService.addCessionApplication(item).then((response) => {
      if(response.success){
        setSuccessMessage("Richiesta inviata correttamente!");
        setItem({});
      }else{
        setErrorMessage(response.error);
        console.log(response.error);
      }
    });
  }, [item]);

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Richiesta di Cessione" : "Cession request"}
        languages={{"it": "richiesta-cessione", "en":"cession-request"}}
      />
      <Section variant="primary-2">
        <Padding top right bottom left>
          <Typo type="h1" align="center">Candidatura alla Cessione</Typo>
          <Typo type="p">
            Se per qualunque motivo hai bisogno di aiuto a trovare una nuova casa ad un cavallo, compila questo modulo e la nostra associazione farà il possibile per trovare una sistemazione adeguata.<br/>
            Nel momento in cui il cavallo troverà una nuova casa, verrà intestato all'associazione per garantirne la corretta gestione per il resto della sua vita, regolarizzando l'adottante con un contratto di adozione.
          </Typo>
        </Padding>
      </Section>
      <Section variant="primary-3">
        <Padding top right bottom left>
          <Typo type="h3" align="center">Informazioni generali</Typo>
          <Row>
            <Col md={6}>
              <Input
                label="Nome"
                width="100%"
                value={item.givenName}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.givenName = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Input
                label="Cognome"
                width="100%"
                value={item.familyName}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.familyName = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Input
                label="Telefono"
                width="100%"
                value={item.phone}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.phone = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Input
                label="Email"
                width="100%"
                value={item.email}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.email = val;
                  setItem(tempItem);
                }}
              />
            </Col>
            <Col md={6}>
              <Textarea
                label="Perché non puoi o non vuoi più tenere il cavallo?"
                width="100%"
                value={item.cause}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.cause = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Select
                label="Quanto è urgente la necessità di trovare casa al cavallo?"
                width="100%"
                searchable={false}
                values={[
                  {name: "Immediata", value: "Immediata"},
                  {name: "Il più velocemente possibile", value: "Il più velocemente possibile"},
                  {name: "Il tempo necessario per fare le cose con cura", value: "Il tempo necessario per fare le cose con cura"},
                  {name: "Non c'è fretta", value: "Non c'è fretta"}
                ]}
                selected={item.priority}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.priority = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>          
          </Row>
          <br/><br/>
          <br/><br/>
          <Typo type="h3" align="center">Informazioni sul cavallo</Typo>
          <Row>
            <Col md={6}>
              <Select
                label="Regione dove si trova il cavallo"
                width="100%"
                searchable={false}
                values={[
                  {name: "Abruzzo", value: "Abruzzo"},
                  {name: "Basilicata", value: "Basilicata"},
                  {name: "Calabria", value: "Calabria"},
                  {name: "Campania", value: "Campania"},
                  {name: "Emilia-Romagna", value: "Emilia-Romagna"},
                  {name: "Friuli-Venezia Giulia", value: "Friuli-Venezia Giulia"},
                  {name: "Lazio", value: "Lazio"},
                  {name: "Liguria", value: "Liguria"},
                  {name: "Lombardia", value: "Lombardia"},
                  {name: "Marche", value: "Marche"},
                  {name: "Molise", value: "Molise"},
                  {name: "Piemonte", value: "Piemonte"},
                  {name: "Puglia", value: "Puglia"},
                  {name: "Sardegna", value: "Sardegna"},
                  {name: "Sicilia", value: "Sicilia"},
                  {name: "Toscana", value: "Toscana"},
                  {name: "Trentino-Alto Adige", value: "Trentino-Alto Adige"},
                  {name: "Umbria", value: "Umbria"},
                  {name: "Valle d'Aosta", value: "Valle d'Aosta"},
                  {name: "Veneto", value: "Veneto"},
                  {name: "Estero", value: "Estero"}
                ]}
                selected={item.horseRegion}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseRegion = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Input
                label="CAP dove si trova il cavallo"
                width="100%"
                value={item.horseZip}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseZip = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={12}>
              <Textarea
                label="Indirizzo dove si trova il cavallo"
                width="100%"
                value={item.horseAddress}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseAddress = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Input
                label="Nome del cavallo"
                width="100%"
                value={item.horseName}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseName = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Input
                label="Data di nascita del cavallo"
                width="100%"
                value={item.horseBirthdate}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseBirthdate = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Input
                label="Razza del cavallo"
                width="100%"
                value={item.horseBreed}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseBreed = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Input
                label="Altezza (approssimativa) del cavallo"
                width="100%"
                value={item.horseHeight}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseHeight = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>          
          </Row>
          <br/><br/>
          <br/><br/>
          <Typo type="h3" align="center">Domande sulla vita del cavallo</Typo>
          <Row>
            <Col md={6}>
              <Input
                label="Da quanto tempo è tuo?"
                width="100%"
                value={item.humanTime}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.humanTime = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Select
                label="Il cavallo è abituato a vivere"
                width="100%"
                searchable={false}
                values={[
                  {name: "Da solo", value: "Da solo"},
                  {name: "Con un solo altro cavallo", value: "Con un solo altro cavallo"},
                  {name: "Con diversi altri cavalli", value: "Con diversi altri cavalli"},
                  {name: "Insieme ad altri animali", value: "Insieme ad altri animali"},
                  {name: "Altro", value: "Altro"}
                ]}
                selected={item.horseFriends}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseFriends = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Select
                label="Il cavallo è abituato a vivere"
                width="100%"
                searchable={false}
                values={[
                  {name: "In box", value: "In box"},
                  {name: "In box con paddock comunicante", value: "In box con paddock comunicante"},
                  {name: "In box con qualche ora al paddock", value: "In box con qualche ora al paddock"},
                  {name: "In paddock con capannina", value: "In paddock con capannina"},
                  {name: "Al pascolo", value: "Al pascolo"},
                  {name: "Altro", value: "Altro"}
                ]}
                selected={item.horseHouse}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseHouse = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Select
                label="Il cavallo è abituato ad avere"
                width="100%"
                searchable={false}
                values={[
                  {name: "Fieno sempre a disposizione", value: "Fieno sempre a disposizione"},
                  {name: "Fieno razionato distribuito ad orari fissi", value: "Fieno razionato distribuito ad orari fissi"},
                  {name: "Altro", value: "Altro"}
                ]}
                selected={item.horseFeeding}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseFeeding = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Select
                label="Il cavallo è abituato ad essere"
                width="100%"
                searchable={false}
                values={[
                  {name: "Ferrato davanti e dietro", value: "Ferrato davanti e dietro"},
                  {name: "Ferrato solo davanti", value: "Ferrato solo davanti"},
                  {name: "Scalzo", value: "Scalzo"},
                  {name: "Altro", value: "Altro"}
                ]}
                selected={item.horseFeet}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseFeet = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={12}>
              <Textarea
                label="Note"
                width="100%"
                value={item.horseManagementNotes}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseManagementNotes = val;
                  setItem(tempItem);
                }}
              />
            </Col>
          </Row>
          <br/><br/>
          <br/><br/>
          <Typo type="h3" align="center">Domande sulle caratteristiche del cavallo</Typo>
          <Row>
            <Col md={6}>
              <Textarea
                label="Il cavallo ha dei problemi problemi fisici adesso? Se si, quali?"
                width="100%"
                value={item.horseCurrentPhysical}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseCurrentPhysical = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Textarea
                label="Il cavallo ha avuto in passato dei problemi problemi fisici? Se si, quali?"
                width="100%"
                value={item.horsePastPhysical}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horsePastPhysical = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Textarea
                label="Il cavallo ha dei problemi problemi comportamentali adesso? Se si, quali?"
                width="100%"
                value={item.horseCurrentMental}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseCurrentMental = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Textarea
                label="Il cavallo ha avuto in passato dei problemi problemi comportamentali? Se si, quali?"
                width="100%"
                value={item.horsePastMental}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horsePastMental = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={12}>
              <Textarea
                label="Descrivi il carattere del cavallo"
                width="100%"
                value={item.horsePersonality}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horsePersonality = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Textarea
                label="Com'è il cavallo con le persone? (esempio: tranquillo, confidente, pauroso ecc)"
                width="100%"
                value={item.horseWithHumans}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseWithHumans = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Textarea
                label="Com'è il cavallo con gli altri cavalli? (esempio: tranquillo, confidente, pauroso ecc)"
                width="100%"
                value={item.horseWithHorses}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseWithHorses = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            
          </Row>

          <ErrorMessage>
            {errorMessage}
          </ErrorMessage>

          <SuccessMessage>
            {successMessage}
          </SuccessMessage>

          <br/><br/>
          
          <center>
            <Button 
              onClick={() => send()} 
              variant={"light-o"} 
              text={"Invia richiesta"}
              disabled={disabled}
            />
          </center>
        </Padding>
      </Section>
    </Layout>
  )
}

export default ContattiRichiestaCessione;
