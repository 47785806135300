import React, {useEffect} from 'react';
// import styled from 'styled-components';
import {useParams} from 'react-router-dom';

import AnalyticsService from './../../services/AnalyticsService';

import Meta from './../layout/Meta';
import Layout from '../layout/Layout';
import Header from '../layout/Header';
import Section from '../layout/Section';
import Padding from '../layout/Padding';
// import Row from './../../common/components/Row';
// import Col from './../../common/components/Col';
import Typo from './../../common/components/Typo';
// import Cta from './../../common/components/Cta';

// import {LAYOUT_SM, LAYOUT_XS } from '../../config';


const Associazione = ({layout}) => {
  let { lang } = useParams();

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Associazione" : "Association"}
        languages={{"it": "associazione", "en":"association"}}
      />
      <Header 
        uppertext={lang === "it" ? "Associazione" : "Association"}
        text={<>Proteggiamo, riabilitiamo, diamo una seconda possibilità.</>}
        lowertext={<>Assieme possiamo fare la differenza nelle loro vite.</>}
        page="associazione"
        alt="Emotional image"
      />

      <Section 
        variant="primary-3" 
        minHeight="500px"
      >
        <Padding top right bottom left>
          {lang === "it" ? 
            <>
              <Typo type="h2">Chi siamo</Typo>
              <Typo type="p" align="left">
                L'Associazione Equinozio ODV nasce dal desiderio di assicurare un futuro a tutti quei cavalli o equidi in generale che, per i più svariati motivi, si trovano in pericolo o senza una famiglia.<br/>Uno dei nostri obiettivi principali è quello di diffondere il concetto di cavallo come AMICO e compagno di vita e non come strumento utile a raggiungere i nostri scopi personali.  
              </Typo>
              <Typo type="p" align="left">
                Molto spesso il cavallo viene considerato come oggetto e quando "si deteriora" viene letteralmente scartato e la sua vita cambia radicalmente passando dalle lezioni o dalle passeggiate in compagnia ad una deprimente solitudine... Così i cavalli si lasciano andare, diventano apatici e piano piano si spengono.  
              </Typo>
              <Typo type="p" align="left">
                L'associazione quindi si propone di aiutare cavalli maltrattati o che il proprietario non può più mantenere, cavalli destinati al macello, o all'eutanasia in quanto ormai anziani, o seppur giovani con problematiche fisiche e psicologiche.  
              </Typo>  
            </> 
          :
            <>
              <Typo type="h2">About us</Typo>
              <Typo type="p" align="center">
                The Equinozio ODV association was founded out of a desire to ensure a future for all horses or equids in general that, for a variety of reasons, find themselves in danger or without a family.<br/>One of our main objectives is to spread the concept of the horse as a FRIEND and life companion and not as a tool to achieve our personal goals.
              </Typo>
              <Typo type="p" align="left">
                Very often the horse is considered as an object and when it "deteriorates" it is literally discarded and its life changes radically from lessons or walks in company to a depressing solitude... So the horses let themselves go, they become apathetic and slowly switch off.
              </Typo>
              <Typo type="p" align="center">
                The association aims to help horses that have been mistreated or that the owner can no longer maintain, horses that are destined for slaughter, or for euthanasia because they are old, or even young horses with physical and psychological problems.
              </Typo> 
            </>
          }
        </Padding>
      </Section>

      <Section 
        variant="primary-2" 
        imageDx={"/web/sections/associazione-cosafacciamo.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          {lang === "it" ? 
            <>
              <Typo type="h2">Che cosa facciamo?</Typo>
              <Typo type="p" align="left">
                <ul>
                  <li>Salviamo equidi e li aiutiamo  sia dal punto di vista fisico che da quello psicologico.</li>
                  <li>Troviamo famiglie nuove per i cavalli salvati.</li>
                  <li>Siamo di supporto a chi si impegna ad aiutare cavalli bisognosi.</li>
                  <li>Organizziamo raccolte fondi, banchetti e cene per sostenere le spese del mantenimento, dei veterinari, dei medicinali e integratori dei nostri e altri cavalli.</li>
                  <li>Organizziamo corsi e giornate a tema per far conoscere il mondo del cavallo.</li>
                </ul>
              </Typo>  
            </> 
          :
            <>
              <Typo type="h2">What are we going to do?</Typo>
              <Typo type="p" align="center">
                <ul>
                  <li>We rescue equines, help them both physically and psychologically.</li>
                  <li>We find new families for the rescued ones.</li>
                  <li>Whoever is committed to helping horses in need has our support.</li>
                  <li>We organise fundraisers, banquets and charity dinners to contribute to the ordinary expenses of maintaining the horses, but also for veterinarians, medicines and supplements for our animals and others.</li>
                  <li>We organize courses and themed days to introduce the world of horses.</li>
                </ul>
              </Typo> 
            </>
          }
        </Padding>
      </Section>
    </Layout>
  )
}

export default Associazione;
