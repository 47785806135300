import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';

import AnalyticsService from '../../services/AnalyticsService';

import Meta from '../layout/Meta';
import Layout from '../layout/Layout';
import Header from '../layout/Header';
import Section from '../layout/Section';
import Padding from '../layout/Padding';
import Typo from '../../common/components/Typo';
import Image from '../../common/components/Image';


const SostieniciCinquepermille = ({layout}) => {
  let { lang } = useParams();

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "5x1000 | Sostienici" : "5x1000 | Support us"}
        languages={{"it": "sostienici/cinquexmille", "en":"support-us/cinquexmille"}}
      />
      <Header 
        uppertext={lang === "it" ? "Sostienici" : "Support us"}
        text={lang === "it" ? "Dona il tuo 5x1000 ai cavalli in difficoltà." : "Dona il tuo 5x1000 ai cavalli in difficoltà."}
        lowertext={lang === "it" ? 
          <>
            Donare il 5xmille a Equinozio ODV è un gesto concreto che può fare la differenza.<br/>
            La tua firma ci aiuta a salvare chi non può salvarsi da solo.<br/>
            CF: 93069130206
          </>
        : 
          <>
            Donare il 5xmille a Equinozio ODV è un gesto concreto che può fare la differenza.<br/>
            La tua firma ci aiuta a salvare chi non può salvarsi da solo.<br/>
            CF: 93069130206
          </>          
        }
        page="cinquexmille"
        alt="Emotional image"
      />
      
      <Section
        variant="primary-3"
        imageDx={"/web/sections/cinquexmille-cosafacciamo.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          {lang === "it" ? 
            <>
              <Typo type="h2">Cosa facciamo con il tuo 5x1000</Typo>
              <Typo type="p">
                Ogni giorno ci impegniamo a garantire una vita dignitosa e sicura a cavalli maltrattati, abbandonati o in situazioni di pericolo. Il nostro lavoro è possibile grazie al contributo prezioso di persone come te, che decidono di destinare una piccola parte del proprio 5x1000 per sostenere la nostra causa.
              </Typo>
              <Typo type="p">
                Con il tuo 5x1000, puoi fare una differenza reale nella vita di un cavallo bisognoso. Le donazioni ricevute vengono impiegate per garantire cure veterinarie, cibo e rifugio per i cavalli salvati da Equinozio ODV e per salvarne tanti altri ancora. Ogni euro conta e ogni gesto di generosità contribuisce a cambiare il destino di un cavallo in difficoltà.
              </Typo>
            </>
          :
            <>
              <Typo type="h2">Cosa facciamo con il tuo 5x1000</Typo>
              <Typo type="p">
                Ogni giorno ci impegniamo a garantire una vita dignitosa e sicura a cavalli maltrattati, abbandonati o in situazioni di pericolo. Il nostro lavoro è possibile grazie al contributo prezioso di persone come te, che decidono di destinare una piccola parte del proprio 5x1000 per sostenere la nostra causa.
              </Typo>
              <Typo type="p">
                Con il tuo 5x1000, puoi fare una differenza reale nella vita di un cavallo bisognoso. Le donazioni ricevute vengono impiegate per garantire cure veterinarie, cibo e rifugio per i cavalli salvati da Equinozio ODV e per salvarne tanti altri ancora. Ogni euro conta e ogni gesto di generosità contribuisce a cambiare il destino di un cavallo in difficoltà.
              </Typo>
            </>
          }
        </Padding>
      </Section>

      <Section
        variant="secondary-2"
        imageSx={"/web/sections/cinquexmille-cose.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          {lang === "it" ? 
            <>
              <Typo type="h2">Cos'è il 5x1000</Typo>
              <Typo type="p">
                Il 5×1000 è la quota di imposta sui redditi delle persone fisiche che il contribuente può destinare agli enti non profit iscritti all'elenco dei beneficiari dell’Agenzia delle Entrate e alle iniziative sociali dei comuni.
              </Typo>
              <Typo type="p">
                In poche parole, lo stato destina una parte del tuo reddito per scopi benefici, tu puoi selezionare una preferenza.
              </Typo>
            </>
          :
            <>
              <Typo type="h2">Cos'è il 5x1000</Typo>
              <Typo type="p">
                Il 5×1000 è la quota di imposta sui redditi delle persone fisiche che il contribuente può destinare agli enti non profit iscritti all'elenco dei beneficiari dell’Agenzia delle Entrate e alle iniziative sociali dei comuni.
              </Typo>
              <Typo type="p">
                In poche parole, lo stato destina una parte del tuo reddito per scopi benefici, tu puoi selezionare una preferenza.
              </Typo>
            </>
          }
        </Padding>
      </Section>

      <Section variant="default">
        <Padding top right bottom left>
          {lang === "it" ? 
            <>
              <Typo type="h2" align="center">Come donare il tuo 5x1000</Typo>
              <Typo type="p" align="center">
                È semplice e senza alcun costo aggiuntivo per te!
              </Typo>
              <Typo type="p" align="center">
                Quando farai la Dichiarazione dei Redditi, troverai una sezione dove indicare la tua preferenza per il 5x1000.<br/>
                Nella casella per gli Enti del Terzo Settore iscritti nel RUNTS, firma e inserisci il nostro CF: 93069130206.
                <br/><br/>
                <Image src={"/web/contents/firma-cinquexmille.png"} width="400px" />
                <br/><br/>
                In questo modo, insieme, possiamo continuare a fornire un rifugio sicuro e amorevole a quanti più cavalli possibile.
              </Typo>
            </>
          :
            <>
              <Typo type="h2" align="center">Come donare il tuo 5x1000</Typo>
              <Typo type="p" align="center">
                È semplice e senza alcun costo aggiuntivo per te!
              </Typo>
              <Typo type="p" align="center">
                Quando farai la Dichiarazione dei Redditi, troverai una sezione dove indicare la tua preferenza per il 5x1000.<br/>
                Nella casella per gli Enti del Terzo Settore iscritti nel RUNTS, firma e inserisci il nostro CF: 93069130206.
                <br/><br/>
                <Image src={"/web/contents/firma-cinquexmille.png"} width="400px" />
                <br/><br/>
                In questo modo, insieme, possiamo continuare a fornire un rifugio sicuro e amorevole a quanti più cavalli possibile.
              </Typo>
            </>
          }
        </Padding>
      </Section>

      <Section 
        variant="secondary-1"
        imageSx={"/web/sections/cinquexmille-grazie.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          {lang === "it" ? 
            <>
              <Typo type="h2">Grazie per aiutarci a fare la differenza</Typo>
              <Typo type="p">
                La tua generosità è la forza trainante dietro ad ogni momento di serenità di un cavallo salvato. Unisciti a noi nella nostra missione di proteggere e promuovere il benessere dei cavalli. Oltre a donare il tuo 5x1000, puoi diventare parte attiva della nostra comunità, partecipando come volontario, diffondendo la nostra causa e condividendo le storie dei nostri cavalli salvati.
              </Typo>
              <Typo type="p">
                Ogni gesto di solidarietà e ogni contributo finanziario ci avvicinano sempre di più al nostro obiettivo di garantire una vita felice e sicura a ogni cavallo bisognoso. Grazie per essere dalla nostra parte e per aiutarci a costruire un futuro migliore per i nostri amici equini.
              </Typo>
            </>
          :
            <>
              <Typo type="h2">Grazie per aiutarci a fare la differenza</Typo>
              <Typo type="p">
              La tua generosità è la forza trainante dietro ad ogni momento di serenità di un cavallo salvato. Unisciti a noi nella nostra missione di proteggere e promuovere il benessere dei cavalli. Oltre a donare il tuo 5x1000, puoi diventare parte attiva della nostra comunità, partecipando come volontario, diffondendo la nostra causa e condividendo le storie dei nostri cavalli salvati.
              </Typo>
              <Typo type="p">
                Ogni gesto di solidarietà e ogni contributo finanziario ci avvicinano sempre di più al nostro obiettivo di garantire una vita felice e sicura a ogni cavallo bisognoso. Grazie per essere dalla nostra parte e per aiutarci a costruire un futuro migliore per i nostri amici equini.
              </Typo>
            </>
          }
        </Padding>
      </Section>
    </Layout>
  )
}

export default SostieniciCinquepermille;
