import React from 'react';
import Layout from './../layout/Layout';
// import Typo from './../../common/components/Typo';


const Dashboard = ({layout}) => {

  return (
    <Layout layout={layout}>
      <center>Se sei qui e non vedi nulla, in alto a sinistra c'è il menu da aprire.</center>
    </Layout>
  )
}

export default Dashboard;
