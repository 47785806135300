import React, {useEffect} from 'react';
// import styled from 'styled-components';
import {useParams} from 'react-router-dom';

import AnalyticsService from './../../services/AnalyticsService';
// import DatabaseService from './../../services/DatabaseService';

import Meta from './../layout/Meta';
import Layout from '../layout/Layout';
import Header from '../layout/Header';
import Section from '../layout/Section';
import Padding from '../layout/Padding';
// import Showcase from '../layout/Showcase';
// import Row from './../../common/components/Row';
// import Col from './../../common/components/Col';
import Typo from './../../common/components/Typo';
// import Cta from './../../common/components/Cta';

// import NewsletterAvailability from '../layout/NewsletterAvailability';



// const ImageFull = styled.img`
//   width: 100%;
// `;

const HomePage = ({layout}) => {
  let { lang } = useParams();

  // const [horsesEmergency, setHorsesEmergency] = useState([]);
  // const [eventsNext, setEventsNext] = useState([]);

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  useEffect(() => {
    // DatabaseService.getHorses({order: "name", emergency: true, showWeb: true}).then((response) => {
    //   // console.log(response);
    //   if(response.success){
    //     setHorsesEmergency(response.data);
    //   }else{
    //     console.log(response.error);
    //   }
    // });    
    // DatabaseService.getEvents({
    //   status: "next",
    //   order: "asc"
    // }).then((response) => {
    //   if(response.success){
    //     setEventsNext(response.data);
    //   }else{
    //     console.log(response.error);
    //   }
    // });
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        languages={{"it": "", "en":""}}
      />
      <Header 
        uppertext={<>Equinozio ODV</>}
        text={<>Aiutaci a salvare<br/>chi non può<br/>salvarsi da solo.</>}
        lowertext={<>Lottiamo ogni giorno per salvare i cavalli più bisognosi.</>}
        page="homepage"
        alt="Emotional image"
      />
      
      
      {/* CAMPAGNA ATTIVA */}
      {/* <Section 
        variant="primary-3" 
        imageSx={"/web/headers/homepage.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          <Typo type="h2">Campagna attiva</Typo>
          <Typo type="p">Info varie sulla campagna attiva</Typo>
          <br/>
          <Cta 
            size="big"
            variant="light-o"
            text="Contatti"
            link="/it/contatti"
            icon="arrow_forward"
          />
        </Padding>
      </Section> */}


      {/* PRESENTAZIONE/MANIFESTO */}
      <Section 
        variant="secondary-2"
      >
        <Padding top right bottom left>
          {lang === "it" ? 
            <>
              <Typo type="h2" align="center">Il nostro Manifesto</Typo>
              <Typo type="p" align="center">L'associazione Equinozio ODV nasce dal desiderio di assicurare un futuro a tutti i cavalli o equidi in generale che, per i più svariati motivi, si trovano in pericolo o senza una famiglia.<br/>Uno dei nostri obiettivi principali è quello di diffondere il concetto di cavallo come AMICO e compagno di vita e non come strumento utile a raggiungere i nostri scopi personali.</Typo>
              <Typo type="p" align="center">L'associazione si propone di aiutare cavalli maltrattati o che il proprietario non può più mantenere, cavalli destinati al macello, o all'eutanasia in quanto ormai anziani, o seppur giovani con problematiche fisiche e psicologiche.</Typo>
            </>
          :
            <>
              <Typo type="h2" align="center">Our Manifesto</Typo>
              <Typo type="p" align="center">The Equinozio ODV association was founded out of a desire to ensure a future for all horses or equids in general that, for a variety of reasons, find themselves in danger or without a family.<br/>One of our main objectives is to spread the concept of the horse as a FRIEND and life companion and not as a tool to achieve our personal goals.</Typo>
              <Typo type="p" align="center">The association aims to help horses that have been mistreated or that the owner can no longer maintain, horses that are destined for slaughter, or for euthanasia because they are old, or even young horses with physical and psychological problems.</Typo>
            </>
          }
        </Padding>
      </Section>
      

      {/* EVENTI */}
      {/* {eventsNext && eventsNext.length > 0 ?
        <Section variant="default">
          <Padding top right bottom left>
            {lang === "it" ? 
              <Typo type="h2">I prossimi eventi</Typo>
            :
              <Typo type="h2">Upcoming events</Typo>
            }

            TODO
            <Showcase
              list={eventsNext}
              type="events"
              cols={3}
            />
          </Padding>
        </Section>
      : null } */}


      {/* ADOZIONI */}
      {/* <Section variant="secondary-1">
        <Padding top right bottom left>
          FASCIA ADOZIONI
        </Padding>
      </Section> */}


      {/* DONA ORA */}
      {/* <Section variant="secondary-3">
        <Padding top right bottom left>
          FASCIA DONA ORA CON FORM
        </Padding>
      </Section> */}

      
      {/* NOTIZIE */}
      {/* <Section variant="default">
        <Padding top right bottom left>
          FASCIA NOTIZIE
        </Padding>
      </Section> */}

      
      {/* SHOP */}
      {/* <Section variant="primary-1">
        <Padding top right bottom left>
          FASCIA SHOP
        </Padding>
      </Section> */}


      {/* OASI */}
      {/* <Section 
        variant="secondary-2" 
        imageSx={"/web/sections/homepage-oasi.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          <Typo type="h2">La nostra Oasi</Typo>
          <Typo type="p">Lorem ipsum</Typo>
          <br/>
          <Cta 
            size="big"
            variant="light-o"
            text="Scopri"
            link="/it/associazione/oasi"
            icon="arrow_forward"
          />
        </Padding>
      </Section> */}

{/*
      
      <Section variant="peach">
        {lang === "it" ? 
          <>
            <Typo type="h2" align="center">Emergenze</Typo>
            <Typo type="p" align="center">Per questi cavalli abbiamo bisogno di un aiuto urgente, visualizza la loro scheda e aiutaci!</Typo>
          </>
        : 
          <>
            <Typo type="h2" align="center">Emergencies</Typo>
            <Typo type="p" align="center">For these horses we need urgent help, view their file and help us!</Typo>
          </>
        }
        <Showcase
          list={horsesEmergency}
          cols={layout === LAYOUT_XS ? 2 : layout === LAYOUT_SM ? 3 : 6}
        />
      </Section>
      
      
             
  */}
    </Layout>
  )
}

export default HomePage
