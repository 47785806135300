import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import StorageService from '../../services/StorageService';

import {BREAKPOINT_XS} from './../../config';

const Wrapper = styled.section`
  min-height: ${props => props.minHeight ? props.minHeight : "auto"};
  display: flex;
  flex-direction: row;
  @media (max-width: ${BREAKPOINT_XS}px){
    flex-direction: column;
  }

  ${props => props.variant === "primary-1" ? `
    background-color: #edfdfd;
    color: #000000;
  ` : ""}

  ${props => props.variant === "primary-2" ? `
    background-color: #499392;
    color: #ffffff;
  ` : ""}

  ${props => props.variant === "primary-3" ? `
    background-color: #053839;
    color: #ffffff;
  ` : ""}

  ${props => props.variant === "secondary-1" ? `
    background-color: #dbf9f9;
    color: #000000;
  ` : ""}

  ${props => props.variant === "secondary-2" ? `
    background-color: #377374;
    color: #ffffff;
  ` : ""}

  ${props => props.variant === "secondary-3" ? `
    background-color: #0a4646;
    color: #ffffff;
  ` : ""}
`;

const SlotSide = styled.div`
  flex-basis: 50%;
  @media (max-width: ${BREAKPOINT_XS}px){
    flex-basis: 100vw;
  }
  flex-grow: 0;
  flex-shrink: 0;
  background-image: url('${props => props.src}');
  background-position: center;
  background-size: cover;
`;
const SlotCx = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Section = ({
  variant="default", 
  imageSx = null,
  imageDx = null,
  minHeight = null,
  children
}) => {
  const [srcImageSx, setSrcImageSx] = useState(null);
  const [srcImageDx, setSrcImageDx] = useState(null);
  
  useEffect(() => {
    if(imageSx){
      StorageService.getUrl(imageSx).then((response) => {
        setSrcImageSx(response);
      });
    }
  }, [imageSx]);
  
  useEffect(() => {
    if(imageDx){
      StorageService.getUrl(imageDx).then((response) => {
        setSrcImageDx(response);
      });
    }
  }, [imageDx]);

  return (
    <Wrapper variant={variant} minHeight={minHeight}>
      {window.screen.width <= BREAKPOINT_XS ? 
        <SlotCx>
          {children}
        </SlotCx>
      : null}
      {srcImageSx ? 
        <SlotSide src={srcImageSx} />
      : null }
      {window.screen.width > BREAKPOINT_XS ? 
        <SlotCx>
        {children}
        </SlotCx>
      : null}
      {srcImageDx ? 
        <SlotSide src={srcImageDx} />
      : null }
    </Wrapper>
  )
}

export default Section;