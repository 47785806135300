import { initializeApp } from "@firebase/app";
import { getFirestore, query, where, orderBy, doc, collection, getDoc, setDoc, addDoc, updateDoc, deleteDoc, getDocs } from "@firebase/firestore";
// import { getFirestore, query, where, orderBy, doc, collection, getDoc, setDoc, updateDoc, getDocs, increment } from "@firebase/firestore";

import {firebaseConfig} from './../config';

class DatabaseService {
  constructor() {
    this.app = initializeApp(firebaseConfig);
    this.db = getFirestore(this.app);
  }

  addLog(func, params){
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem("equinozioodvUser"));
      if(user){
        addDoc(collection(this.db, "logs"), {
          user: user,
          function: func,
          params: params
        }).then((response) => {
          // console.log("Scrittura: addAdoptionApplication()");
          let res = {};
          res.success = true;
          resolve(res);
        }).catch((error) => {
          reject(error);
        });
      }
    });
  }

  getHorses(q = null, page = null){
    let composedQuery = query(collection(this.db, "horses"));
    if(q && q.status){
      composedQuery = query(composedQuery, where("status", "==", q.status));
    }
    if(q && q.species){
      composedQuery = query(composedQuery, where("species", "==", q.species));
    }
    if(q && q.emergency){
      composedQuery = query(composedQuery, where("emergency", "==", q.emergency));
    }
    if(q && q.showWeb){
      composedQuery = query(composedQuery, where("showWeb", "==", q.showWeb));
    }
    if(q && q.bdn){
      composedQuery = query(composedQuery, where("bdn", "==", q.bdn));
    }
    if(q && q.property){
      composedQuery = query(composedQuery, where("property", "==", q.property));
    }
    if(q && q.isEquinozio){
      composedQuery = query(composedQuery, where("isEquinozio", "==", q.isEquinozio));
    }
    if(q && q.isDeceased){
      composedQuery = query(composedQuery, where("isDeceased", "==", q.isDeceased));
    }
    if(q && q.order && q.order !== "random"){
      composedQuery = query(composedQuery, orderBy(q.order));
    }
    return new Promise((resolve, reject) => {
      getDocs(composedQuery).then((response) => {
        // console.log("Lettura: getHorses("+JSON.stringify(q)+","+page+")");
        let res = {};
        res.success = true;
        if(q && q.order === "random"){
          res.data = response.docs.map(doc => ({slug: doc.id, ...doc.data()})).sort(() => Math.random() - 0.5);
        }else{
          res.data = response.docs.map(doc => ({slug: doc.id, ...doc.data()}));
        }
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  // getCardsBySet(kSet, kLanguage){
  //   return new Promise((resolve, reject) => {
  //     getDocs(query(query(query(query(collection(this.db, "cards")), where("kSet", "==", kSet)), where("kLanguage", "==", kLanguage)), orderBy("index"))).then((response) => {
  //       console.log("Lettura: getCardsBySet("+kSet+", "+kLanguage+")");
  //       let res = {};
  //       res.success = true;
  //       res.data = response.docs.map(doc => ({slug: doc.id, ...doc.data()}));
  //       resolve(res);
  //     }).catch((error) => {
  //       reject(error);
  //     });
  //   });
  // }

  getHorse(slug){
    return new Promise((resolve, reject) => {
      getDoc(doc(this.db, "horses", slug)).then((response) => {
        // console.log("Lettura: getHorse("+slug+")");
        let res = {};
        res.success = true;
        res.data = response.data();
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addHorse(slug, item){
    return new Promise((resolve, reject) => {
      setDoc(doc(this.db, "horses", slug), item).then((response) => {
        this.addLog("addHorse", {"slug":slug, "item":item});
        console.log("Scrittura: addHorse("+slug+")");
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  updateHorse(slug, item){
    return new Promise((resolve, reject) => {
      updateDoc(doc(this.db, "horses", slug), item).then((response) => {
        this.addLog("updateHorse", {"slug":slug, "item":item});
        console.log("Scrittura: updateHorse("+slug+")");
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  deleteHorse(slug){
    return new Promise((resolve, reject) => {
      deleteDoc(doc(this.db, "horses", slug)).then((response) => {
        this.addLog("deleteHorse", {"slug":slug});
        console.log("Scrittura: deleteHorse("+slug+")");
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getEvents(q = null, page = null){
    let composedQuery = query(collection(this.db, "events"));
   
    if(q && q.status === "past"){
      composedQuery = query(composedQuery, where("date", "<", new Date()));
    }
    if(q && q.status === "next"){
      composedQuery = query(composedQuery, where("date", ">=", new Date()));
    }
    if(q && q.order === "asc"){
      composedQuery = query(composedQuery, orderBy("date"));
    }
    if(q && q.order === "desc"){
      composedQuery = query(composedQuery, orderBy("date"));
    }
    
    return new Promise((resolve, reject) => {
      getDocs(composedQuery).then((response) => {
        // console.log("Lettura: getEvents("+JSON.stringify(q)+","+page+")");
        let res = {};
        res.success = true;
        res.data = response.docs.map(doc => ({slug: doc.id, ...doc.data()}));
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getEvent(slug){
    return new Promise((resolve, reject) => {
      getDoc(doc(this.db, "events", slug)).then((response) => {
        // console.log("Lettura: getEvent("+slug+")");
        let res = {};
        res.success = true;
        res.data = response.data();
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addEvent(slug, item){
    return new Promise((resolve, reject) => {
      setDoc(doc(this.db, "events", slug), item).then((response) => {
        this.addLog("addEvent", {"slug":slug, "item":item});
        // console.log("Scrittura: addEvent("+slug+")");
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  updateEvent(slug, item){
    return new Promise((resolve, reject) => {
      updateDoc(doc(this.db, "events", slug), item).then((response) => {
        this.addLog("updateEvent", {"slug":slug, "item":item});
        // console.log("Scrittura: updateEvent("+slug+")");
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  deleteEvent(slug){
    return new Promise((resolve, reject) => {
      deleteDoc(doc(this.db, "events", slug)).then((response) => {
        this.addLog("deleteEvent", {"slug":slug});
        console.log("Scrittura: deleteEvent("+slug+")");
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getAdoptionApplications(q = null, page = null){
    let composedQuery = query(collection(this.db, "adoptionApplications"));
    if(q && q.region){
      composedQuery = query(composedQuery, where("region", "==", q.region));
    }
    // if(q && q.emergency){
    //   composedQuery = query(composedQuery, where("emergency", "==", q.emergency));
    // }
    // if(q && q.showWeb){
    //   composedQuery = query(composedQuery, where("showWeb", "==", q.showWeb));
    // }
    // if(q && q.order && q.order !== "random"){
    //   composedQuery = query(composedQuery, orderBy(q.order));
    // }
    return new Promise((resolve, reject) => {
      getDocs(composedQuery).then((response) => {
        // console.log("Lettura: getAdoptionApplications("+JSON.stringify(q)+","+page+")");
        let res = {};
        res.success = true;
        res.data = response.docs.map(doc => ({slug: doc.id, ...doc.data()}));
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getAdoptionApplication(slug){
    return new Promise((resolve, reject) => {
      getDoc(doc(this.db, "adoptionApplications", slug)).then((response) => {
        // console.log("Lettura: getAdoptionApplication("+slug+")");
        let res = {};
        res.success = true;
        res.data = response.data();
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addAdoptionApplication(item){
    return new Promise((resolve, reject) => {
      addDoc(collection(this.db, "adoptionApplications"), item).then((response) => {
        // console.log("Scrittura: addAdoptionApplication()");
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  updateAdoptionApplication(slug, item){
    return new Promise((resolve, reject) => {
      updateDoc(doc(this.db, "adoptionApplications", slug), item).then((response) => {
        this.addLog("updateAdoptionApplication", {"slug":slug, "item":item});
        // console.log("Scrittura: updateAdoptionApplication("+slug+")");
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  deleteAdoptionApplication(slug){
    return new Promise((resolve, reject) => {
      deleteDoc(doc(this.db, "adoptionApplications", slug)).then((response) => {
        this.addLog("deleteAdoptionApplication", {"slug":slug});
        // console.log("Scrittura: deleteAdoptionApplication("+slug+")");
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getCessionApplications(q = null, page = null){
    let composedQuery = query(collection(this.db, "cessionApplications"));
    if(q && q.region){
      composedQuery = query(composedQuery, where("region", "==", q.region));
    }
    // if(q && q.emergency){
    //   composedQuery = query(composedQuery, where("emergency", "==", q.emergency));
    // }
    // if(q && q.showWeb){
    //   composedQuery = query(composedQuery, where("showWeb", "==", q.showWeb));
    // }
    // if(q && q.order && q.order !== "random"){
    //   composedQuery = query(composedQuery, orderBy(q.order));
    // }
    return new Promise((resolve, reject) => {
      getDocs(composedQuery).then((response) => {
        // console.log("Lettura: getCessionApplications("+JSON.stringify(q)+","+page+")");
        let res = {};
        res.success = true;
        res.data = response.docs.map(doc => ({slug: doc.id, ...doc.data()}));
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getCessionApplication(slug){
    return new Promise((resolve, reject) => {
      getDoc(doc(this.db, "cessionApplications", slug)).then((response) => {
        // console.log("Lettura: getCessionApplication("+slug+")");
        let res = {};
        res.success = true;
        res.data = response.data();
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addCessionApplication(item){
    return new Promise((resolve, reject) => {
      addDoc(collection(this.db, "cessionApplications"), item).then((response) => {
        // console.log("Scrittura: addCessionApplication()");
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  updateCessionApplication(slug, item){
    return new Promise((resolve, reject) => {
      updateDoc(doc(this.db, "cessionApplications", slug), item).then((response) => {
        this.addLog("updateCessionApplication", {"slug":slug, "item":item});
        // console.log("Scrittura: updateCessionApplication("+slug+")");
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  deleteCessionApplication(slug){
    return new Promise((resolve, reject) => {
      deleteDoc(doc(this.db, "cessionApplications", slug)).then((response) => {
        this.addLog("deleteCessionApplication", {"slug":slug});
        // console.log("Scrittura: deleteCessionApplication("+slug+")");
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getCircleApplications(q = null, page = null){
    let composedQuery = query(collection(this.db, "circleApplications"));
    if(q && q.region){
      composedQuery = query(composedQuery, where("region", "==", q.region));
    }
    // if(q && q.emergency){
    //   composedQuery = query(composedQuery, where("emergency", "==", q.emergency));
    // }
    // if(q && q.showWeb){
    //   composedQuery = query(composedQuery, where("showWeb", "==", q.showWeb));
    // }
    // if(q && q.order && q.order !== "random"){
    //   composedQuery = query(composedQuery, orderBy(q.order));
    // }
    return new Promise((resolve, reject) => {
      getDocs(composedQuery).then((response) => {
        // console.log("Lettura: getCircleApplications("+JSON.stringify(q)+","+page+")");
        let res = {};
        res.success = true;
        res.data = response.docs.map(doc => ({slug: doc.id, ...doc.data()}));
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getCircleApplication(slug){
    return new Promise((resolve, reject) => {
      getDoc(doc(this.db, "circleApplications", slug)).then((response) => {
        // console.log("Lettura: getCircleApplication("+slug+")");
        let res = {};
        res.success = true;
        res.data = response.data();
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  addCircleApplication(item){
    return new Promise((resolve, reject) => {
      addDoc(collection(this.db, "circleApplications"), item).then((response) => {
        // console.log("Scrittura: addCircleApplication()");
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  updateCircleApplication(slug, item){
    return new Promise((resolve, reject) => {
      updateDoc(doc(this.db, "circleApplications", slug), item).then((response) => {
        this.addLog("updateCircleApplication", {"slug":slug, "item":item});
        // console.log("Scrittura: updateCircleApplication("+slug+")");
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  deleteCircleApplication(slug){
    return new Promise((resolve, reject) => {
      deleteDoc(doc(this.db, "circleApplications", slug)).then((response) => {
        this.addLog("deleteCircleApplication", {"slug":slug});
        // console.log("Scrittura: deleteCircleApplication("+slug+")");
        let res = {};
        res.success = true;
        resolve(res);
      }).catch((error) => {
        reject(error);
      });
    });
  }
}

let instance = new DatabaseService();
export default instance;