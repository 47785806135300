import React from 'react';
import styled from 'styled-components';

import {BREAKPOINT_SM, BREAKPOINT_XS } from '../../config';

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  position: relative;
  padding-top: ${props => props.top ? "50px" : "0px"};
  padding-right: ${props => props.right ? "50px" : "0px"};
  padding-bottom: ${props => props.bottom ? "50px" : "0px"};
  padding-left: ${props => props.left ? "50px" : "0px"};
  @media (max-width: ${BREAKPOINT_SM}px){
    padding-top: ${props => props.top ? "30px" : "0px"};
    padding-right: ${props => props.right ? "30px" : "0px"};
    padding-bottom: ${props => props.bottom ? "30px" : "0px"};
    padding-left: ${props => props.left ? "30px" : "0px"};
  }
  @media (max-width: ${BREAKPOINT_XS}px){
    padding-top: ${props => props.top ? "20px" : "0px"};
    padding-right: ${props => props.right ? "20px" : "0px"};
    padding-bottom: ${props => props.bottom ? "20px" : "0px"};
    padding-left: ${props => props.left ? "20px" : "0px"};
  }
`;

const Padding = ({ 
  top = false, 
  right = false, 
  bottom = false, 
  left = false, 
  children 
}) => {
  return (
    <Wrapper top={top} right={right} bottom={bottom} left={left}>
     {children}
    </Wrapper>
  )
}

export default Padding;
