import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import AnalyticsService from '../../services/AnalyticsService';


import DatabaseService from '../../services/DatabaseService';

import Section from '../layout/Section';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Typo from '../../common/components/Typo'
import Meta from '../layout/Meta';

import Layout from '../layout/Layout';
import Padding from '../layout/Padding';
import Button from '../../common/components/Button';
import Input from '../../common/components/Input';
import Select from '../../common/components/Select';
import Textarea from '../../common/components/Textarea';
import Checkbox from '../../common/components/Checkbox';

const Label = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 300;
`;
const ErrorMessage = styled.div`
  margin: 20px 0px;
  width: 100%;
  color: red;
  text-align: center;
`;

const SuccessMessage = styled.div`
  margin: 20px 0px;
  width: 100%;
  color: green;
  text-align: center;
`;

const SostieniciCandidaturaAdozione = ({layout}) => {
  let { lang } = useParams();
  const [item, setItem] = useState({});
  const [horses, setHorses] = useState([]);

  const [disabled, setDisabled] = useState(true);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    if(!item.givenName || item.givenName.length === 0){
      valid = false;
    }
    if(!item.familyName || item.familyName.length === 0){
      valid = false;
    }
    if(!item.phone || item.phone.length === 0){
      valid = false;
    }
    if(!item.region || item.region.length === 0){
      valid = false;
    }
    if(!item.zip || item.zip.length === 0){
      valid = false;
    }
    
    if(!item.horseAddress || item.horseAddress.length === 0){
      valid = false;
    }
    if(!item.horsePlace || item.horsePlace.length === 0){
      valid = false;
    }
    if(!item.horseFriends || item.horseFriends.length === 0){
      valid = false;
    }
    if(!item.horseHouse || item.horseHouse.length === 0){
      valid = false;
    }
    if(!item.horseFeeding || item.horseFeeding.length === 0){
      valid = false;
    }
    if(!item.horseFeet || item.horseFeet.length === 0){
      valid = false;
    }
    if(!item.humanActivities || item.humanActivities.length === 0){
      valid = false;
    }
    if(!item.humanExperience || item.humanExperience.length === 0){
      valid = false;
    }
    if(!item.humanSchool || item.humanSchool.length === 0){
      valid = false;
    }
    if(!item.humanPrevious || item.humanPrevious.length === 0){
      valid = false;
    }
    if(!item.humanWhy || item.humanWhy.length === 0){
      valid = false;
    }
    if(!item.desiredHealth || item.desiredHealth.length === 0){
      valid = false;
    }
    if(!item.desiredBrain || item.desiredBrain.length === 0){
      valid = false;
    }
    setDisabled(!valid);
  }, [item]);

  const send = useCallback(() => {
    setErrorMessage("");
    item.date = Date.now();
    DatabaseService.addAdoptionApplication(item).then((response) => {
      if(response.success){
        setSuccessMessage(lang === "it" ? "Richiesta inviata correttamente!" : "Request sent successfully");
        setItem({});
      }else{
        setErrorMessage(response.error);
        console.log(response.error);
      }
    });
  }, [item, lang]);

  useEffect(() => {
    DatabaseService.getHorses({order: "name", status: "In cerca di casa", showWeb: true}).then((response) => {
      if(response.success){
        setHorses(response.data);
      }else{
        console.log(response.error);
      }
    });
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Candidatura all'Adozione" : "Adoption application"}
        languages={{"it": "candidatura-adozione", "en":"adoption-application"}}
      />
      <Section variant="primary-2">
        <Padding top right bottom left>
          <Typo type="h1" align="center">{lang === "it" ? "Candidatura all'Adozione" : "Adoption application"}</Typo>
          <Typo type="p" align="center">
          {lang === "it" ? 
            "Desideri adottare un cavallo? Compila questo modulo e ti ricontatteremo il prima possibile!"
          : 
            "Would you like to adopt a horse? Fill in this form and we will get back to you as soon as possible!"
          } 
          </Typo>
        </Padding>
      </Section>
      <Section variant="primary-3">
        <Padding top right bottom left>
          <Typo type="h3" align="center">{lang === "it" ? "Informazioni generali" : "General informations"}</Typo>
          <Row>
            <Col md={6}>
              <Input
                label={lang === "it" ? "Nome" : "First name"}
                width="100%"
                value={item.givenName}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.givenName = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Input
                label={lang === "it" ? "Cognome" : "Last name"}
                width="100%"
                value={item.familyName}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.familyName = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Input
                label={lang === "it" ? "Telefono" : "Phone"}
                width="100%"
                value={item.phone}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.phone = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Input
                label={lang === "it" ? "Email" : "Email"}
                width="100%"
                value={item.email}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.email = val;
                  setItem(tempItem);
                }}
              />
            </Col>
            <Col md={6}>
              <Select
                label={lang === "it" ? "Regione" : "Region"}
                width="100%"
                searchable={false}
                values={[
                  {name: "Abruzzo", value: "Abruzzo"},
                  {name: "Basilicata", value: "Basilicata"},
                  {name: "Calabria", value: "Calabria"},
                  {name: "Campania", value: "Campania"},
                  {name: "Emilia-Romagna", value: "Emilia-Romagna"},
                  {name: "Friuli-Venezia Giulia", value: "Friuli-Venezia Giulia"},
                  {name: "Lazio", value: "Lazio"},
                  {name: "Liguria", value: "Liguria"},
                  {name: "Lombardia", value: "Lombardia"},
                  {name: "Marche", value: "Marche"},
                  {name: "Molise", value: "Molise"},
                  {name: "Piemonte", value: "Piemonte"},
                  {name: "Puglia", value: "Puglia"},
                  {name: "Sardegna", value: "Sardegna"},
                  {name: "Sicilia", value: "Sicilia"},
                  {name: "Toscana", value: "Toscana"},
                  {name: "Trentino-Alto Adige", value: "Trentino-Alto Adige"},
                  {name: "Umbria", value: "Umbria"},
                  {name: "Valle d'Aosta", value: "Valle d'Aosta"},
                  {name: "Veneto", value: "Veneto"},
                  {name: lang === "it" ? "Estero" : "Abroad", value: "Estero"}
                ]}
                selected={item.region}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.region = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Input
                label={lang === "it" ? "CAP" : "ZIP"}
                width="100%"
                value={item.zip}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.zip = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            {horses && horses.length > 0 ? 
              <Col md={12}>
                <Label>
                  {lang === "it" ? 
                    <>Hai visto qualche cavallo in particolare che vorresti adottare tra quelli che stanno cercando casa?<br/>
                    Se sì, spuntalo qui sotto:</>
                  : 
                    <>Have you seen any horses in particular that you would like to adopt among those looking for a home?<br/>
                    If yes, please tick it below:</>
                  }                
                </Label>
                
                {horses.map((horse) => {
                  return <>
                    <Checkbox 
                      label={horse.name}
                      selected={item.horses && item.horses.length > 0 && item.horses.indexOf(horse.slug) > -1}
                      onChange={(val) => {
                        let tempItem = {...item};
                        if(!tempItem.horses){
                          tempItem.horses = [];
                        }
                        if(val){
                          tempItem.horses.push(horse.slug);
                        }else{
                          tempItem.horses.splice(tempItem.horses.indexOf(horse.slug), 1);
                        }
                        setItem(tempItem);
                      }}
                    />
                  </>  
                })}
              </Col>
            : null }
            {/* <Col md={12}>
              <Label>
                {lang === "it" ? 
                  <>Vorresti ricevere per mail gli aggiornamenti sui nostri cavalli disponibili all'adozione?<br/>
                  Se sì, spunta qui sotto le liste a cui vuoi iscriverti:<br/></>
                : 
                  <>Would you like to receive e-mail updates on our horses available for adoption?<br/>
                  If so, please tick below the lists you would like to subscribe to:<br/></>
                }
                
                <Checkbox 
                  label={lang === "it" ? "Aggiornamenti dall'Oasi" : "Updates from the Oasis"}
                  selected={item.newsletterUpdates}
                  onChange={(val) => {
                    let tempItem = {...item};
                    tempItem.newsletterUpdates = val;
                    setItem(tempItem);
                  }}
                />
                <Checkbox 
                  label={lang === "it" ? "Cavalli in cerca di casa" : "Horses looking for a home"}
                  selected={item.newsletterAdoption}
                  onChange={(val) => {
                    let tempItem = {...item};
                    tempItem.newsletterAdoption = val;
                    setItem(tempItem);
                  }}
                />              
                <Checkbox 
                  label={lang === "it" ? "Cavalli in cerca di adozioni a distanza, fide o mezzefide" : "Horses seeking distance adoptions, fide or mezzefide"}
                  selected={item.newsletterSupport}
                  onChange={(val) => {
                    let tempItem = {...item};
                    tempItem.newsletterSupport = val;
                    setItem(tempItem);
                  }}
                />
              </Label>
              
            </Col> */}
          </Row>
          <br/><br/>
          <br/><br/>
          <Typo type="h3" align="center">{lang === "it" ? "Domande sulla vita del cavallo" : "Questions about the horse's life"}</Typo>
          <Row>
            <Col md={12}>
              <Textarea
                label={lang === "it" ? "Indirizzo in cui andrebbe a vivere il cavallo" : "Address where the horse would live"}
                width="100%"
                value={item.horseAddress}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseAddress = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Select
                label={lang === "it" ? "Il cavallo vivrebbe" : "The horse would live"}
                width="100%"
                searchable={false}
                values={[
                  {name: lang === "it" ? "In un maneggio" : "In a stable", value: "In un maneggio"},
                  {name: lang === "it" ? "In un terreno di proprietà" : "On a piece of land owned", value: "In un terreno di proprietà"},
                  {name: lang === "it" ? "In un terreno in affitto" : "On rented land", value: "In un terreno in affitto"},
                  {name: lang === "it" ? "Altro" : "Other", value: "Altro"}
                ]}
                selected={item.horsePlace}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horsePlace = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Textarea
                label={lang === "it" ? "Note" : "Notes"}
                width="100%"
                value={item.horsePlaceNotes}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horsePlaceNotes = val;
                  setItem(tempItem);
                }}
              />
            </Col>
            <Col md={6}>
              <Select
                label={lang === "it" ? "Il cavallo vivrebbe" : "The horse would live"}
                width="100%"
                searchable={false}
                values={[
                  {name: lang === "it" ? "Da solo" : "Alone", value: "Da solo"},
                  {name: lang === "it" ? "Con un solo altro cavallo" : "With only one other horse", value: "Con un solo altro cavallo"},
                  {name: lang === "it" ? "Con diversi altri cavalli" : "With several other horses", value: "Con diversi altri cavalli"},
                  {name: lang === "it" ? "Insieme ad altri animali" : "Together with other animals", value: "Insieme ad altri animali"},
                  {name: lang === "it" ? "Altro" : "Other", value: "Altro"}
                ]}
                selected={item.horseFriends}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseFriends = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Textarea
                label={lang === "it" ? "Note" : "Notes"}
                width="100%"
                value={item.horseFriendsNotes}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseFriendsNotes = val;
                  setItem(tempItem);
                }}
              />
            </Col>
            <Col md={6}>
              <Select
                label={lang === "it" ? "Il cavallo vivrebbe" : "The horse would live"}
                width="100%"
                searchable={false}
                values={[
                  {name: lang === "it" ? "In box" : "In a box", value: "In box"},
                  {name: lang === "it" ? "In box con paddock comunicante" : "In a box with communicating paddock", value: "In box con paddock comunicante"},
                  {name: lang === "it" ? "In box con qualche ora al paddock" : "In a box, with a few hours in the paddock", value: "In box con qualche ora al paddock"},
                  {name: lang === "it" ? "In paddock con capannina" : "In paddock with shelter", value: "In paddock con capannina"},
                  {name: lang === "it" ? "Al pascolo" : "At the pasture", value: "Al pascolo"},
                  {name: lang === "it" ? "Altro" : "Other", value: "Altro"}
                ]}
                selected={item.horseHouse}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseHouse = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Textarea
                label={lang === "it" ? "Note" : "Notes"}
                width="100%"
                value={item.horseHouseNotes}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseHouseNotes = val;
                  setItem(tempItem);
                }}
              />
            </Col>
            <Col md={6}>
              <Select
                label={lang === "it" ? "Il cavallo avrebbe" : "The horse would have"}
                width="100%"
                searchable={false}
                values={[
                  {name: lang === "it" ? "Fieno sempre a disposizione" : "Hay always available", value: "Fieno sempre a disposizione"},
                  {name: lang === "it" ? "Fieno razionato distribuito ad orari fissi" : "Rationed hay distributed at fixed times", value: "Fieno razionato distribuito ad orari fissi"},
                  {name: lang === "it" ? "Altro" : "Other", value: "Altro"}
                ]}
                selected={item.horseFeeding}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseFeeding = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Textarea
                label={lang === "it" ? "Note" : "Notes"}
                width="100%"
                value={item.horseFeedingNotes}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseFeedingNotes = val;
                  setItem(tempItem);
                }}
              />
            </Col>
            <Col md={6}>
              <Select
                label={lang === "it" ? "Il cavallo sarebbe" : "The horse would be"}
                width="100%"
                searchable={false}
                values={[
                  {name: lang === "it" ? "Ferrato davanti e dietro" : "xxx", value: "Shod front and back"},
                  {name: lang === "it" ? "Ferrato solo davanti" : "Shod only in front", value: "Ferrato solo davanti"},
                  {name: lang === "it" ? "Scalzo" : "Barefoot", value: "Scalzo"},
                  {name: lang === "it" ? "Altro" : "Other", value: "Altro"}
                ]}
                selected={item.horseFeet}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseFeet = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Textarea
                label={lang === "it" ? "Note" : "Notes"}
                width="100%"
                value={item.horseFeetNotes}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.horseFeetNotes = val;
                  setItem(tempItem);
                }}
              />
            </Col>
          </Row>
          <br/><br/>
          <br/><br/>
          <Typo type="h3" align="center">{lang === "it" ? "Domande sulle attività col cavallo" : "Questions related to horse activities"}</Typo>
          <Row>
            <Col md={6}>
              <Textarea
                label={lang === "it" ? "Cosa vorrai fare col cavallo che adotti?" : "What would you like to do with the horse you adopt?"}
                width="100%"
                value={item.humanActivities}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.humanActivities = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Textarea
                label={lang === "it" ? "Qual è la tua esperienza nel mondo dei cavalli e dell'Equitazione?" : "What is your experience in the world of horses and riding?"}
                width="100%"
                value={item.humanExperience}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.humanExperience = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Textarea
                label={lang === "it" ? "Utilizzi un metodo/scuola/approccio in particolare?" : "Do you use a particular method/school/approach?"}
                width="100%"
                value={item.humanSchool}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.humanSchool = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Textarea
                label={lang === "it" ? "Hai già avuto altri cavalli in passato a casa o in maneggio?" : "Have you had other horses in the past at home or at the stables?"}
                width="100%"
                value={item.humanPrevious}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.humanPrevious = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Textarea
                label={lang === "it" ? "Li hai ancora? Se no, perché?" : "Do you still have them? If not, why?"}
                width="100%"
                value={item.humanWhy}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.humanWhy = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
          </Row>
          <br/><br/>
          <br/><br/>
          <Typo type="h3" align="center">{lang === "it" ? "Caratteristiche che cerchi nel cavallo" : "Characteristics you look for in a horse"}</Typo>
          <Row>
            <Col md={12}>
              <Textarea
                label={lang === "it" ? "Quale caratteristica fisica è fondamentale o preferibile che il cavallo che adotti abbia e perché? (esempio: altezza, età ecc)" : "What physical characteristic is essential or preferable for the horse you adopt to have and why? (e.g. height, age etc.)"}
                width="100%"
                value={item.desiredPhysics}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.desiredPhysics = val;
                  setItem(tempItem);
                }}
              />
            </Col>
            <Col md={12}>
              <Textarea
                label={lang === "it" ? "Quale caratteristica comportamentale è fondamentale o preferibile che il cavallo che adotti abbia e perché? (esempio: tranquillo, confidente ecc)" : "What behavioural characteristic is essential or preferable for the horse you adopt to have and why? (e.g. quiet, confident etc.)"}
                width="100%"
                value={item.desiredMental}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.desiredMental = val;
                  setItem(tempItem);
                }}
              />
            </Col>
            <Col md={12}>
              <Textarea
                label={lang === "it" ? "Quale attività è fondamentale o preferibile che il cavallo che adotti conosca già e perché? (esempio: già abituato a sella o mai messa, già abituato ad essere spazzolato ecc)" : "What activity is essential or preferable for the horse you adopt to already know and why? (e.g. already used to a saddle or never put it on, already used to being brushed etc.)"}
                width="100%"
                value={item.desiredSkills}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.desiredSkills = val;
                  setItem(tempItem);
                }}
              />
            </Col>
            <Col md={6}>
              <Select
                label={lang === "it" ? "Sei eventualmente disponibile ad adottare un cavallo con problematiche fisiche più o meno gravi?" : "Are you possibly willing to adopt a horse with more or less severe physical problems?"}
                width="100%"
                searchable={false}
                values={[
                  {name: lang === "it" ? "Assolutamente no, solo cavalli perfettamente sani" : "Absolutely not, only perfectly healthy horses", value: "Assolutamente no, solo cavalli perfettamente sani"},
                  {name: lang === "it" ? "Forse, dipende dalla problematica" : "Maybe, it depends on the issue", value: "Forse, dipende dalla problematica"},
                  {name: lang === "it" ? "Sì, se il problema non è troppo grave" : "Yes, if the problem is not too serious", value: "Sì, se il problema non è troppo grave"},
                  {name: lang === "it" ? "Sì, se il problema non compromette le attività che voglio fare col cavallo" : "Yes, if the problem does not compromise the activities I want to do with the horse", value: "Sì, se il problema non compromette le attività che voglio fare col cavallo"},
                  {name: lang === "it" ? "Assolutamente sì, adotto il cavallo che ne ha più bisogno" : "Absolutely, I adopt the horse that needs it most", value: "Assolutamente sì, adotto il cavallo che ne ha più bisogno"}
                ]}
                selected={item.desiredHealth}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.desiredHealth = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Textarea
                label={lang === "it" ? "Perché?" : "Why?"}
                width="100%"
                value={item.desiredHealthNotes}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.desiredHealthNotes = val;
                  setItem(tempItem);
                }}
              />
            </Col>
            <Col md={6}>
              <Select
                label={lang === "it" ? "Sei eventualmente disponibile ad adottare un cavallo con problematiche comportamentali più o meno gravi?" : "Are you possibly willing to adopt a horse with more or less severe behavioural problems?"}
                width="100%"
                searchable={false}
                values={[
                  {name: lang === "it" ? "Assolutamente no, solo cavalli bravissimi" : "Absolutely not, only very good horses", value: "Assolutamente no, solo cavalli bravissimi"},
                  {name: lang === "it" ? "Forse, dipende dalla problematica" : "Maybe, it depends on the issue", value: "Forse, dipende dalla problematica"},
                  {name: lang === "it" ? "Sì, se il problema non è troppo grave" : "Yes, if the problem is not too serious", value: "Sì, se il problema non è troppo grave"},
                  {name: lang === "it" ? "Sì, se il problema non compromette le attività che voglio fare col cavallo" : "Yes, if the problem does not compromise the activities I want to do with the horse", value: "Sì, se il problema non compromette le attività che voglio fare col cavallo"},
                  {name: lang === "it" ? "Assolutamente sì, adotto il cavallo che ne ha più bisogno" : "Absolutely, I adopt the horse that needs it most", value: "Assolutamente sì, adotto il cavallo che ne ha più bisogno"}
                ]}
                selected={item.desiredBrain}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.desiredBrain = val;
                  setItem(tempItem);
                }}
                required
              />
            </Col>
            <Col md={6}>
              <Textarea
                label={lang === "it" ? "Perché?" : "Why?"}
                width="100%"
                value={item.desiredBrainNotes}
                onChange={(val) => {
                  let tempItem = {...item};
                  tempItem.desiredBrainNotes = val;
                  setItem(tempItem);
                }}
              />
            </Col>
            
          </Row>

          <ErrorMessage>
            {errorMessage}
          </ErrorMessage>

          <SuccessMessage>
            {successMessage}
          </SuccessMessage>

          <br/><br/>

          <center>
            <Button 
              onClick={() => send()} 
              variant={"light-o"} 
              text={"Invia richiesta"}
              disabled={disabled}
            />
          </center>
        </Padding>
      </Section>
    </Layout>
  )
}

export default SostieniciCandidaturaAdozione;
