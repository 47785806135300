import React, {useState, useEffect}  from 'react';
import styled from 'styled-components';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import HorseThumb from './HorseThumb';
import EventThumb from './EventThumb';


const Wrapper = styled.div`
`;
const Line = styled(Row)`
  justify-content: center;
`;
const Item = styled(Col)`
  padding: 0px;
`;


const Showcase = ({ type = "horses", list, rows, cols }) => {
  const [grid, setGrid] = useState(null);
  
  useEffect(() => {
    let tempGrid = [];
    let currRows = rows;
    if(list && list.length > 0){
      if(!currRows){
        currRows = Math.ceil(list.length / cols)
      }
      if(currRows>12){
        currRows = 12;
      }
      for(let iRow=0; iRow<currRows; iRow++){
        let gridRow = []
        for(let iCol=0; iCol<cols; iCol++){
          gridRow.push(list[iRow*cols+iCol])
        }
        tempGrid.push(gridRow)
      }
    }
    setGrid(tempGrid);
  }, [list, rows, cols]);
  
  return (
    <Wrapper>
      {grid ? grid.map((vRow, iRow) => {
        return (
          <Line key={iRow}>
            {vRow.map((item, iCol) => {
              if(item !== undefined){
                return (
                  <Item key={iCol} xs={12/cols}>
                    {type === "horses" ?
                      <HorseThumb item={item}/>
                    : type === "events" ?                      
                      <EventThumb item={item}/>
                    : null }
                  </Item>
                );
              }else {
                return null;
              }
            })}
          </Line>
        );
      }) : null}
    </Wrapper>
  )
}

export default Showcase;
