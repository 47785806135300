import React, {useRef, useState, useEffect, useCallback} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import DatabaseService from '../../services/DatabaseService';

import Layout from '../layout/Layout';
import Filters from '../layout/Filters';

import Overlay from '../../common/components/Overlay';
import Container from '../../common/components/Container';
import Row from '../../common/components/Row';
import Col from '../../common/components/Col';
import Table from '../../common/components/Table';
import Loading from '../../common/components/Loading';
import Card from '../../common/components/Card';
// import Button from '../../common/components/Button';

import NewHorse from '../forms/NewHorse';

import {formatDate} from '../../helpers';


const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 40px;
`;
const TableWrapper = styled.div`
  & table tr td{
    white-space: nowrap;
  }
  & table tr td:nth-child(2),
  & table tr td:nth-child(3){
    word-break: break-all;
    white-space: unset;
  }
  overflow-x: auto; 
`;
const TableActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > *{
    margin-left: 5px;
  }
`;
const NoResult = styled.div`
  text-align: center;
`;
const Title = styled.div`
  font-family: 'Skranji',cursive;
  font-size: 30px;
`;
const Tag = styled.div`
  display: inline-block;
  font-weight: 900;
  border-radius: 20px;
  padding: 0px 5px 1px;
  margin: 2px;
  ${props => props.status === "Riuscito" ? `
    color: white;
    background: green;
  ` : props => props.status === "Annullato" ? `
    color: white;
    background: red;
  ` : props => props.status === "In carico" ? `
    color: white;
    background: orange;
  ` : `
    color: white;
    background: grey;
  `}
  text-transform: uppercase;
`;


const CessionApplicationsList = ({layout}) => {
  let scrollRef = useRef(null);
  let navigate = useNavigate();
  const [list, setList] = useState(null);
  const [page, setPage] = useState(0);

  const [showModalNew, toggleModalNew] = useState(false);

  const [filters, setFilters] = useState({
    "status": null
  });

  const changeFilters = useCallback((name, value) => {
    setList(null);
    setPage(0);
    let tempFilters = {...filters};
    tempFilters[name] = value;
    setFilters(tempFilters);
  }, [filters]);

  const getList = useCallback(() => {
    if(scrollRef && scrollRef.current){
      scrollRef.current.scrollTo(0,0);
    }
    DatabaseService.getCessionApplications({
      page: page,
      region: filters.region,
      // start: filters.rangeDate.split('|')[0],
      // end: filters.rangeDate.split('|')[1],
      // level: filters.level
    }).then((response) => {
      if(response.success){
        setList(response.data);
      }else{
        console.log(response.error);
      }
    });

  }, [page, filters]);

  useEffect(() => {
    getList();
  }, [getList, page, filters]);

  // const deleteItem = useCallback((item) => {
  //   setList(null);
  //   DatabaseService.deleteCessionApplication(item.slug).then((response) => {
  //     if(response.success){
  //       getList();
  //     }else{
  //       console.log(response.error);
  //     }
  //   });
  // }, [getList]);

  return (
    <Layout layout={layout}>
      <div ref={scrollRef}>
        <Container>
          <Header>
            <Title>Candidature cessione {list ? <small>({list.length})</small> : null}</Title>            
            {/*<Button variant={"mint"} onClick={() => toggleModalNew(true)}>Nuovo</Button>*/}
          </Header>

          <Row>
            <Col>
              <Card>
                <Row>
                  <Col widthmd="12">
                    <Filters
                      onChange={(...args) => changeFilters(...args)}
                      fields={
                        [
                          {
                            "label": "Regione",
                            "name": "region",
                            "size": 4,
                            "type": "select",
                            "placeholder": null,
                            "value": filters.region,
                            "options": [
                              {
                                "name": "Tutti",
                                "value": null
                              },
                              {name: "Abruzzo", value: "Abruzzo"},
                              {name: "Basilicata", value: "Basilicata"},
                              {name: "Calabria", value: "Calabria"},
                              {name: "Campania", value: "Campania"},
                              {name: "Emilia-Romagna", value: "Emilia-Romagna"},
                              {name: "Friuli-Venezia Giulia", value: "Friuli-Venezia Giulia"},
                              {name: "Lazio", value: "Lazio"},
                              {name: "Liguria", value: "Liguria"},
                              {name: "Lombardia", value: "Lombardia"},
                              {name: "Marche", value: "Marche"},
                              {name: "Molise", value: "Molise"},
                              {name: "Piemonte", value: "Piemonte"},
                              {name: "Puglia", value: "Puglia"},
                              {name: "Sardegna", value: "Sardegna"},
                              {name: "Sicilia", value: "Sicilia"},
                              {name: "Toscana", value: "Toscana"},
                              {name: "Trentino-Alto Adige", value: "Trentino-Alto Adige"},
                              {name: "Umbria", value: "Umbria"},
                              {name: "Valle d'Aosta", value: "Valle d'Aosta"},
                              {name: "Veneto", value: "Veneto"},
                              {name: "Estero", value: "Estero"}
                            ]
                          }
                        ]
                      }
                    />
                    <hr/>
                    {list ?
                      list.length > 0 ?
                        <>
                          <TableWrapper>
                            <Table
                              columns={[
                                "Status",
                                "Nominativo",
                                "Cavallo",
                                "Regione",
                                "Data",
                                ""
                              ]}
                              values={
                                list.map(item => ({
                                  values: [
                                    <Tag status={item.status}>{item.status}</Tag>,
                                    item.familyName + " " + item.givenName,  
                                    item.horseName,                                    
                                    item.horseRegion,                                   
                                    formatDate(item.date),
                                    <TableActions onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                                      {/* <Button icon="remove" variant={"push-bittersweet"} size={"small"} onClick={() => deleteItem(item)} /> */}
                                    </TableActions>
                                  ],
                                  link: ({children}) => (<Link to={"/admin/cession-applications/"+item.slug}>{children}</Link>)
                                }))
                              }
                            />
                          </TableWrapper>
                        </>
                      : <NoResult>There are no results.</NoResult>
                    : <Loading />}
                  </Col>
                </Row>
                {/* {totPages > 1 ?
                  <OzPagination totPages={totPages} currentPage={page} onChange={((val) => setPage(val))} />
                : null } */}
              </Card>
            </Col>
          </Row>

          {showModalNew ? (
            <Overlay>
              {<NewHorse
                fnSuccess={(slug) => {
                  navigate("/admin/horses/"+slug);
                }}
                fnCancel={() => toggleModalNew(false)}
              />}
            </Overlay>
          ) : null}
        </Container>
      </div>
    </Layout>
  )
}

export default CessionApplicationsList;
