import React from "react";
import styled from 'styled-components';

const Wrapper  = styled.div`
  grid-column: auto / span ${props => props.width};
  max-width: 100%;

  ${props => props.order ? `
    order: ${props => props.order};
  ` : null }
/*
  ${props => props.offset ? `
    margin-left: ${props => props.offset / 12 * 100}%;
  ` : null }
*/

  @media (min-width: 576px) {
    ${props => props.widthsm ? `
      grid-column: auto / span ` + props.widthsm + `;
    ` : null }
    /*
    ${props => props.ordersm ? `
      order: ` + props.ordersm + `;
    ` : null }
    ${props => props.offsetsm ? `
      margin-left: ` + (props.offsetsm / 12 * 100) + `%;
    ` : null }
    */
  }

  @media (min-width: 768px) {
    ${props => props.widthmd ? `
      grid-column: auto / span ` + props.widthmd + `;
    ` : null }
    /*
    ${props => props.ordermd ? `
      order: ` + props.ordermd + `;
    ` : null }
    ${props => props.offsetmd ? `
      margin-left: ` + (props.offsetmd / 12 * 100) + `%;
    ` : null }
    */
  }

  @media (min-width: 992px) {
    ${props => props.widthlg ? `
      grid-column: auto / span ` + props.widthlg + `;
    ` : null }
    /*
    ${props => props.orderlg ? `
      order: ` + props.orderlg + `;
    ` : null }
    ${props => props.offsetlg ? `
      margin-left: ` + (props.offsetlg / 12 * 100) + `%;
    ` : null }
    */
  }

  @media (min-width: 1200px) {
    ${props => props.widthxl ? `
      grid-column: auto / span ` + props.widthxl + `;
    ` : null }
    /*
    ${props => props.orderxl ? `
      order: ` + props.orderxl + `;
    ` : null }
    ${props => props.offsetxl ? `
      margin-left: ` + (props.offsetxl / 12 * 100) + `%;
    ` : null }
    */
  }

`;

const Col = ({
    children,
    width=12, widthsm, widthmd, widthlg, widthxl,
    order, ordersm, ordermd, orderlg, orderxl,
    offset, offsetsm, offsetmd, offsetlg, offsetxl
  }) => {
  return (
    <Wrapper
      width={width}
      widthsm={widthsm}
      widthmd={widthmd}
      widthlg={widthlg}
      widthxl={widthxl}
      order={order}
      ordersm={ordersm}
      ordermd={ordermd}
      orderlg={orderlg}
      orderxl={orderxl}
      offset={offset}
      offsetsm={offsetsm}
      offsetmd={offsetmd}
      offsetlg={offsetlg}
      offsetxl={offsetxl}
    >
      {children}
    </Wrapper>
  )
}

export default Col;
