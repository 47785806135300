import React from 'react';
import styled from 'styled-components';


const Wrapper = styled.div`
  text-align: left;

  &, & * {
    box-sizing: border-box;
  }
`;

const Container = styled.div`
  position: relative;
  display: block;
  width: ${props => props.width};
  max-width: 100%;
  display: flex;

  &, & * {
    box-sizing: border-box;
  }
`;

const Input = styled.textarea`
  font-family: 'Be Vietnam Pro', sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;

  resize: none;
  padding: 8px 10px;
  min-height: 40px;
  letter-spacing: inherit;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;



  ${props => props.variant === "default" ? `
    background-color: ` + (props.disabled ? "#F2F4FD" : "#ffffff") + `;
    color: ` + (props.disabled ? "#30303054" : "#000000") + `;
    border: 1px solid #CBCBCB;
    box-shadow: inset 0px 1px 2px #687FE262;

    ::placeholder {
      color: #dedede;
      opacity: 1;
    }

    :-webkit-autofill {
      ${props => props.disabled ? `
        box-shadow: inset 0 0 0px 9999px #F2F4FD;
      ` : `
        box-shadow: inset 0 0 0px 9999px #ffffff;
      ` };
    }
  ` : props.variant === "charcoal" ? `
      background-color: ` + (props.disabled ? "#F2F4FD" : "#455262") + `;
      color: ` + (props.disabled ? "#30303054" : "#FFFFFF") + `;
      border: 1px solid #202833;
      box-shadow: inset 0px 1px 2px #20283362;

      ::placeholder {
        color: #FFFFFF;
        opacity: 1;
      }

      :-webkit-autofill {
        ${props => props.disabled ? `
          box-shadow: inset 0 0 0px 9999px #F2F4FD;
        ` : `
          box-shadow: inset 0 0 0px 9999px #ffffff;
        ` };
      }
  ` : null };
`;

const Label = styled.div`
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 14px;
`;

const Textarea = ({
  variant = "default",
  label,
  placeholder,
  value = "",
  type = "text",
  rows = 4,
  onChange,
  width = "260px",
  disabled = false,
  required = false
}) => {
  return (
    <Wrapper>
      {label ?
        <Label>
          {label} {required ? "*" : ""}
        </Label>
      : null}
      <Container
        width={width}
      >
        <Input
          placeholder={placeholder}
          variant={variant}
          rows={rows}
          disabled={disabled}
          onChange={(e) => {
            if(onChange){onChange(e.target.value)};
          }}
          value={value}
          required={required}
        />
      </Container>
    </Wrapper>
  )
}

export default Textarea;
