import React from 'react';
import styled from 'styled-components';

import Row from './../../common/components/Row';
import Col from './../../common/components/Col';
import Input from '../../common/components/Input';
import Select from '../../common/components/Select';


const Wrapper = styled.div`
  padding-top: 0px;
  padding-bottom: 10px;
`;

const Filters = ({fields, onChange, onSearch}) => {
  return (
    <Wrapper>
      <Row align={"bottom"}>
        {fields.map((field, i) =>
          <Col widthmd={field.size} key={i}>
            {(() => {
              switch(field.type) {
                case "text":
                  return <>
                    <div>{field.label}</div>
                    <Input
                      width={"100%"}
                      placeholder={field.placeholder}
                      value={field.value}
                      onChange={(val) => onChange(field.name, val)}
                    />
                  </>;
                case "select":
                  return <>
                    <div>{field.label}</div>
                    <Select
                      width={"100%"}
                      placeholder={field.placeholder}
                      values={field.options}
                      selected={field.value}
                      onChange={(val) => onChange(field.name, val)}
                      maxHeight={"200px"}
                    />
                  </>;
                case "searchableselect":
                  return <>
                    <div>{field.label}</div>
                    <Select
                      width={"100%"}
                      placeholder={field.placeholder}
                      values={field.options}
                      selected={field.value}
                      searchable={true}
                      onChange={(val) => onChange(field.name, val)}
                      onSearch={(val) => onSearch(field.name, val)}
                      maxHeight={"200px"}
                    />
                  </>;
                // case "rangedate":
                  // return <>
                  //   <div>{field.label}</div>
                  //     <OzDatepicker
                  //       type="range"
                  //       width={"100%"}
                  //       onChange={(startVal, endVal) => {
                  //         onChange(field.name, (startVal ? changeDate(startVal, 2, "hours", "startOfDay") : null) + "|" + (endVal ? changeDate(endVal, 2, "hours", "endOfDay") : (startVal ? changeDate(startVal, 2, "hours", "startOfDay") : null)));
                  //       }}
                  //       startValue={new Date(field.value.split('|')[0])}
                  //       endValue={changeDate(new Date(field.value.split('|')[1]), -2, "hours")}
                  //     />
                  // </>;
                  // return <>
                  //   <div>{field.label}</div>
                  //     <Datepicker
                  //       type="range"
                  //       width={"100%"}
                  //       onChange={(startVal, endVal) => {
                  //         onChange(field.name, (startVal ? startVal.toISOString() : null) + "|" + (endVal ? endVal.toISOString() : (startVal ? startVal.toISOString() : null)));
                  //       }}
                  //       startValue={new Date(field.value.split('|')[0])}
                  //       endValue={new Date(field.value.split('|')[1])}
                  //     />
                  // </>;
                default:
                  return null;
              }
            })()}
          </Col>
        )}
      </Row>
    </Wrapper>

  );
}

export default Filters;
