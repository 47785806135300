import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from "./Icon.js";


const Wrapper = styled.div`
  text-align: left;

  &, & * {
    box-sizing: border-box;
  }
`;
const Container = styled.div`
  position: relative;
  display: block;
  width: ${props => props.width};
  max-width: 100%;
`;

const Selected = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: ${props => props.searchable && !props.selected ? "0px" : "0px 30px 0px 10px"};
  cursor: pointer;
  height: ${props => props.height};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: inherit;
  font-size: 12px;
  box-sizing: border-box;



  ${props => props.open ? `
    &{
      border-bottom-left-radius: 0px !important;
    }
    &{
      border-bottom-right-radius: 0px !important;
    }
  ` : null }

  ${props => props.variant === "default" ? `
    box-shadow: ` + (props.searchable && props.open ? "inset" : "") + ` 0px 1px 2px #687FE262;
    background-color: #ffffff;
    border: 1px solid #CBCBCB;
    color: black;
  ` : props.variant === "charcoal" ? `
    box-shadow: inset 0px 1px 2px #20283362;
    background-color: #455262;
    border: 1px solid #202833;
    color: #FFFFFF;
  ` : null };
`;

const ArrowContainer = styled.div`
  position: absolute;
  content: "";
  top: 10px;
  right: 10px;
  height: calc(100% - 20px);
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const Arrow = styled.div`
  width: 16px;
  height: 16px;
  transition: all 0.15s ease-in-out;


  ${props => props.open ? `
    transform: rotate(180deg);
  ` : ``};
`;

const Placeholder = styled.div`
  font-family: 'Be Vietnam Pro', sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;

  ${props => props.variant === "default" ? `
    color: #dedede;
  ` : props.variant === "charcoal" ? `
    color: #FFFFFF;
  ` : null };

`;

const Options = styled.div`
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  right: 0;
  z-index: 99;
  box-shadow: 0px 1px 2px #687FE262;
  border-top: 0px solid transparent;
  border: 1px solid black;
  border-bottom: 0px;

  display: ${props => props.open ? "block" : "none"};

  ${props => props.maxHeight ? `
    max-height: ` + props.maxHeight + `;
    overflow-y: auto;
    overflow-x: hidden;
  ` : ``};


  ${props => props.variant === "default" ? `
    background-color: #ffffff;
    border-color: #CBCBCB;
    color: black;
  ` : props.variant === "charcoal" ? `
    background-color: #455262;
    border-color: #202833;
    color: #FFFFFF;
  ` : null };

`;

const Option = styled.div`
  font-family: 'Be Vietnam Pro', sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 0px 10px;
  border: 1px solid transparent;
  cursor: pointer;
  height: ${props => props.height};

  ${props => props.variant === "default" ? `
    border-color: transparent transparent #CBCBCB;
    &:hover{
      background-color: lilac;
    }
  ` : props.variant === "charcoal" ? `
    border-color: transparent transparent transparent; /*border-color: transparent transparent #202833;*/
    &:hover{
      background-color: rgba(0, 0, 0, 0.1);
    }
  ` : null };



`;

const ClickCatcher = styled.div`
  display: ${props => props.open ? "block" : "none"};
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`;

const Label = styled.div`
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 14px;
`;

const Search = styled.div`
  position: relative;
  height: calc(${props => props.height} - 2px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;
const SearchIconContainer = styled.div`
  position: absolute;
  content: "";
  top: 10px;
  left: 8px;
  height: calc(100% - 20px);
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const SearchIcon = styled.div`
  width: 20px;
  height: 20px;
`;
const SearchInput = styled.input`
  position: absolute;
  top: 0px;
  ${props => props.open ? "right" : "left"}: 0px;
  font-family: 'Be Vietnam Pro', sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;
  padding: 0px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: inherit;
  outline: none;
  width: calc(100% - 24px);
  border: 0px;
  background-color: transparent;
  height: ${props => props.height};

  ${props => props.variant === "default" ? `
    color: ` + (props.disabled ? "#30303054" : "black") + `;
    ::placeholder {
      color: #dedede;
      opacity: 1;
    }
    :-webkit-autofill {
      box-shadow: inset 0 0 0px 9999px #ffffff;
    }
  ` : props.variant === "charcoal" ? `
      color: ` + (props.disabled ? "#30303054" : "#FFFFFF") + `;
      ::placeholder {
        color: #FFFFFF;
        opacity: 1;
      }
      :-webkit-autofill {
        box-shadow: inset 0 0 0px 9999px #ffffff;
      }
  ` : null };
`;

const Clear = styled.div`
  z-index: 999;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  width: calc(${props => props.height} - 16px);
  height: calc(${props => props.height} - 16px);
  background-color: lilac;
  display: flex;
  justify-content: space-around;
  align-items: center;

  /* box-shadow: 0px 1px 2px #687FE239; */

  &:hover{
    box-shadow: inset 0px 1px 2px #687FE239;
`;


const Select = ({
  label,
  placeholder,
  values = [],
  selected,
  width,
  height = "34px",
  maxHeight,
  variant = "default",
  onChange = null,
  required = false,
  searchable,
  onSearch = () => {}
}) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  let iconColor = "black";
  switch (variant) {
    case "charcoal":
      iconColor = "#FFFFFF";
      break;
    default:
      break;
  }

  return (
    <Wrapper>
      {label ?
        <Label>
          {label} {required ? "*" : ""}
        </Label>
      : null}
      <Container
        width={width ? width : "260px"}
      >

        <Selected
          height={height}
          variant={variant}
          onClick={() => {setOpen(true); if(onChange){onChange(null);}}}
          selected={selected}
          searchable={searchable}
          open={open}
        >
          {selected ?
            (values.filter((item) => item.value === selected).length > 0 ? values.filter((item) => item.value === selected)[0].name : null)
          : searchable ?
            <Search
              height={height}
            >
              {searchable && open ?
                <SearchIconContainer>
                  <SearchIcon>
                    <Icon name={"search"} color={iconColor}/>
                  </SearchIcon>
                </SearchIconContainer>
              : null }
              <SearchInput
                height={height}
                open={open}
                variant={variant}
                onChange={(e) => {
                  setSearch(e.target.value);
                  if(onSearch){onSearch(e.target.value)};
                }}
                value={search}
                placeholder={placeholder}
              />
              {search !== "" ?
                <Clear height={height} onClick={() => {
                  setSearch("");
                  if(onSearch){onSearch("")};
                }}>
                  <Icon size="14px" name={"close"} color={iconColor}/>
                </Clear>
              : null }
            </Search>
          :
            <Placeholder
              variant={variant}
            >
              {placeholder}
            </Placeholder>
          }
          {!searchable || !open ?
            <ArrowContainer>
              <Arrow open={open}>
                <Icon size="16px" name={"arrow_drop_down"} color={iconColor}/>
              </Arrow>
            </ArrowContainer>
          : null }
        </Selected>
        <Options
          variant={variant}
          open={open}
          maxHeight={maxHeight ? maxHeight : null}
        >
          {values.map((item, i) =>
            <Option
              height={height}
              variant={variant}
              key={i}
              onClick={() => {
                setOpen(false);
                setSearch("");
                if(onSearch){onSearch("")};
                if(onChange){onChange(item.value);}
              }}
            >{item.name}</Option>
          )}
        </Options>

      </Container>
      <ClickCatcher open={open} onClick={() => {
        setSearch("");
        if(onSearch){onSearch("")};
        setOpen(false);
      }} />
    </Wrapper>
  )
}

export default Select;
