import React, {useState} from 'react';
import styled from 'styled-components';
import {NavLink, useParams, useLocation} from "react-router-dom";

import Padding from './../../frontend/layout/Padding';
import Icon from "./../../common/components/Icon.js";

import Logo from './../../common/components/Logo';
// import Button from './../../common/components/Button';

import {BREAKPOINT_SM} from './../../config';

const Wrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: 80px;
  @media (max-width: ${BREAKPOINT_SM}px){
    min-height: 60px;
  }
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  background-color: #ffffff;
  z-index: 99;
`;

const Content = styled.div`
  height: 100px;
  @media (max-width: ${BREAKPOINT_SM}px){
    height: 80px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  
`;

const MenuCnt = styled.div`
  @media (max-width: 991px){
    position: fixed;
    top: 80px;
    left: ${props => props.isOpen ? "0px" : "-100vw"};
    width: 100vw;
    height: calc(100vh - 80px);
    background-color: #ffffff;
    transition: all 0.25s ease-in-out;
    overflow-x: hidden;
    overflow-y: scroll;
  }
`;

const MobiIconsCnt = styled.div`
  z-index: 5;
  line-height: 0px;
  display: flex;
  @media (min-width: 992px){
    display: none;
  }
`;




const Nav = styled.div`
  overflow: hidden;

  white-space: nowrap;
  display: flex;
  flex-direction: row;
  list-style: none;

  @media (min-width: 992px){
    align-items: center;
  }

  @media (max-width: 991px){

    flex-direction: column;
    text-align: center;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(0,0,0,.075) inset;
    max-height: calc(100vh - 70px);
    overflow-x: hidden;
    overflow-y: auto;
  }
`;
const Link = styled(NavLink)`
  font-size: 18px;
  float: left;
  display: block;
  margin: 17px 16px;
  text-decoration: none !important;
  
  &:hover, &:hover *{
    color: #64B6AC;
    text-decoration: none !important;
  }
`;

const Item = styled.span`
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  color: ${props => props.active ? "#499392" : "#000000"};
  text-decoration: none !important;
`;


const Brand = styled(NavLink)`
  font-family: 'Skranji', cursive;
  font-size: 20px;
  @media (min-width: 576px){
    font-size: 23px;
  }
  @media (min-width: 1200px){
    font-size: 30px;
  }
  text-decoration: none !important;
  display: flex;
  align-items: center;
  gap: 20px;

  &, & *,
  &:hover,
  &:hover *{
    color: #000000 !important;
    text-decoration: none !important;
  }
`;
const BrandName = styled.div`
  line-height: 30px;
`;
const MobiToggle = styled.div`
  margin-left: 10px;
  cursor: pointer;
`;

const Topbar = () => {
  const {lang} = useParams();
  const { pathname } = useLocation();
  const [isOpen, setOpen] = useState(false);

  return (
    <Wrapper>
      <Padding right left>
        <Content>
          <Brand to={"/"+lang}><Logo height={window.screen.width > BREAKPOINT_SM ? "70px" : "50px"} /> <BrandName>Equinozio ODV</BrandName></Brand>
          <MenuCnt isOpen={isOpen}>
            <Nav>
              <Link exact="true" to={"/"+(lang ? lang : "it")+"/"}>
                {({ isActive }) => (<Item active={isActive}>Home</Item>)}
              </Link>
              <Link to={"/"+(lang ? lang : "it")+"/associazione"}>
                <Item active={pathname.includes('/associazione')}>
                  Associazione
                </Item>
              </Link>
              <Link to={"/"+(lang ? lang : "it")+"/sostienici"}>
                <Item active={pathname.includes('/sostienici')}>
                  Sostienici
                </Item>
              </Link>
              <Link to={"/"+(lang ? lang : "it")+"/cavalli"}>
                <Item active={pathname.includes('/cavalli')}>
                  Cavalli
                </Item>
              </Link>
              {/* <Link to={"/"+(lang ? lang : "it")+"/eventi"}>
                <Item active={pathname.includes('/eventi')}>
                  Eventi
                </Item>
              </Link> */}
              <Link exact="true" to={"/"+(lang ? lang : "it")+"/contatti"}>
                {({ isActive }) => (<Item active={isActive}>Contatti</Item>)}
              </Link>
            </Nav>
          </MenuCnt>
          <MobiIconsCnt>
            <MobiToggle onClick={() => setOpen(!isOpen)}>
              <Icon name="menu" size="24px" />
            </MobiToggle>
          </MobiIconsCnt>
        </Content>
      </Padding>
    </Wrapper>
    
  )
}

export default Topbar;

