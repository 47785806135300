import React  from 'react';
import styled from 'styled-components';

import Overlay from './Overlay';
import Card from './Card';


const Message = styled.div`
  border-radius: 9px;
  background: #fbf2c0;
  padding: 30px;
  text-align: center;
  color: #000000;
  font-weight: bold;
  font-size: 16px;
`;


const Success = ({fnClose = ()=>{}, message}) => {
  return (
    <Overlay fnClose={fnClose}>
      <Card onClick={() => fnClose()}>
        <Message>{message}</Message>
      </Card>
    </Overlay>
  )
}

export default Success;
