import React  from 'react';
import styled from 'styled-components';

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #bdc2d6d9;

  overflow-x: hidden;
  overflow-y: auto;
`;

const Spacer = styled.div`
  flex-basis: 30px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  max-width: 90%;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
`;

const Overlay = ({fnClose = ()=>{}, children}) => {
  return (
    <Background onClick={(event) => {
       event.preventDefault();
       if(event.target === event.currentTarget || event.target.parentElement === event.currentTarget) {
          fnClose();
       }
    }}>
      <Spacer />
      <Wrapper>
        {children}
      </Wrapper>
      <Spacer />
    </Background>
  )
}

export default Overlay;
