import React from "react";
import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: ${props => props.gap}px;
  align-items: ${props => props.align};
`;

const Row = ({gap = 20, align = "top", children}) => {
  return (
    <Wrapper align={align} gap={gap}>
      {children}
    </Wrapper>
  )
}

export default Row;
