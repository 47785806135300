import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import AnalyticsService from './../../services/AnalyticsService';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Image from './../../common/components/Image';             
import Typo from './../../common/components/Typo';
import Loading from './../../common/components/Loading';

import Layout from './../layout/Layout';
import Section from './../layout/Section';
import Meta from './../layout/Meta';

import NotFound from './NotFound';

import DatabaseService from './../../services/DatabaseService';

const Label = styled.div`
  font-family: 'Skranji', cursive;
  font-size: 18px;
  font-weight: bold;
`;
const Value = styled.div`
  font-size: 16px;
  margin-bottom: 16px;
`;

const EventiSingle = ({layout}) => {
  let { lang } = useParams();
  let { slug } = useParams();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  useEffect(() => {
    DatabaseService.getEvent(slug).then((response) => {
      if(response.success){
        setEvent(response.data);
      }else{
        console.log(response.error);
      }
    });

    
  }, [slug]);
  
  if(event === null){
    return (
      <Loading />
    )
  }else if(typeof event === 'undefined'){
    return (
      <NotFound />
    )
  }else{
    return (
      <Layout>
        <Meta
          title={event["name_"+lang]}
          description={event["short_"+lang]}
          image={"https://www.equinozio-odv.it/events/details/" + event.slug + ".png"}
          languages={{"it": "eventi/"+event.slug, "en":"events/"+event.slug}}
        />
        <Section>
          <Typo type="h1" align="center">{event["name_"+lang]}</Typo>
          <Row>
            <Col sm={8}>
              <Image src={"events/" + event.slug + "-zoom.png"} alt={event["name_"+lang] + (lang === "it" ? " organizzato da Equinozio ODV" : "organised by Equinozio ODV")} />
            </Col>
            <Col sm={4}>
              {event.status ?
                <>
                  <Label>{lang === "it" ? "Stato" : "Status"}</Label>
                  <Value>{event.status}</Value>
                </>
              : "" }
            </Col>
          </Row>
        </Section>
        <Section>
          {event.description ?
            <Typo type="p" align="center"><div dangerouslySetInnerHTML={{__html: event["description_"+lang]}} /></Typo>
          : "" }
        </Section>

      </Layout>
    )
  }
}

export default EventiSingle
