import React, {useState, useEffect, useCallback} from 'react';
// import {Link} from 'react-router-dom';
import styled from 'styled-components';

// import Container from './../../common/components/Container';
import Row from './../../common/components/Row';
import Col from './../../common/components/Col';
import Loading from './../../common/components/Loading';
import Card from './../../common/components/Card';
import Input from './../../common/components/Input';
import Cta from './../../common/components/Cta';
import Button from './../../common/components/Button';
// import Select from './../../common/components/Select';

import DatabaseService from './../../services/DatabaseService';

import Success from './../../common/components/Success';

const ErrorMessage = styled.div`
  height: 30px;
  color: red;
`;
const CardHeaderTitle = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #303030;
  margin-bottom: 5px;
`;

const HorseInfo = ({item, refreshItem}) => {
  const [object, setObject] = useState(item);

  const [disabled, setDisabled] = useState(true);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    setErrorMessage(null);
    let valid = true;


    if(object.name.length === 0){
      valid = false;
    }


    setDisabled(!valid);
  }, [object]);

  const updateItem = useCallback(() => {
    setErrorMessage("");
    DatabaseService.updateEvent(object.slug, object).then((response) => {
      if(response.success){
        setSuccessMessage("Dati salvati correttamente!");
        refreshItem();
      }else{
        setErrorMessage(response.error);
        console.log(response.error);
      }
    });
  }, [object, refreshItem]);

  if(true){ //list
    return (
      <>
        <Card
          headerLeft={
            <>
              <CardHeaderTitle>Informazioni</CardHeaderTitle>
            </>
          }
          headerSeparator={true}
          footerLeft={<Cta 
            variant="default-o"
            text="Indietro"
            link={"/admin/events"}
            icon="arrow_backward"
          />}
          footerRight={<Button 
            onClick={() => updateItem()}
            variant="default-o"
            text="Salva"
            icon="save"
            disabled={disabled}
          />}
        >
          <Row>
            <Col widthmd="4">
              <Input
                label="Nome"
                width="100%"
                value={object.name}
                onChange={(val) => {
                  let tempItem = {...object};
                  tempItem.name = val;
                  setObject(tempItem);
                }}
              />
            </Col>
            <Col widthmd="4">
              <Input
                label="Nome IT"
                width="100%"
                value={object.name_it}
                onChange={(val) => {
                  let tempItem = {...object};
                  tempItem.name_it = val;
                  setObject(tempItem);
                }}
              />
            </Col>
            <Col widthmd="4">
              <Input
                label="Nome EN"
                width="100%"
                value={object.name_en}
                onChange={(val) => {
                  let tempItem = {...object};
                  tempItem.name_en = val;
                  setObject(tempItem);
                }}
              />
            </Col>
          </Row>
          <ErrorMessage>
            <br/>{errorMessage}<br/>
          </ErrorMessage>
        </Card>
        {successMessage ?
          <Success fnClose={() =>setSuccessMessage(false)} message={successMessage} />
        : null}
      </>
    )
  }else{
    return <Loading />;
  }
}

export default HorseInfo;
