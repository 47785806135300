import React, {useEffect} from 'react'
import {Helmet} from "react-helmet";

import AnalyticsService from './../../services/AnalyticsService';

import Layout from './../layout/Layout';
import Meta from './../layout/Meta';

const NotFoundPage = ({layout}) => {

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return ( 
    <>
      <Meta
        title={"404"}
        description={"Aiutaci a salvare chi non può salvarsi da solo!"}
        image={"https://www.equinozio-odv.it/photos/header.jpg"}
      />
      <Helmet>
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      <Layout layout={layout}>
        404: Not Found
      </Layout>
    </>
  )
}

export default NotFoundPage
