import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';

import AnalyticsService from '../../services/AnalyticsService';

import Meta from '../layout/Meta';
import Layout from '../layout/Layout';
import Header from '../layout/Header';
import Section from '../layout/Section';
import Padding from '../layout/Padding';
import Typo from '../../common/components/Typo';
import Cta from '../../common/components/Cta';


const SostieniciVolontariatoadistanza = ({layout}) => {
  let { lang } = useParams();

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Volontariato a distanza | Sostienici" : "Remote volunteering | Support us"}
        languages={{"it": "sostienici/volontariatoadistanza", "en":"support-us/remotevolunteering"}}
      />

      <Header 
        uppertext={lang === "it" ? "Sostienici" : "Support us"}
        text={lang === "it" ? 
          <>
            Unisciti al nostro team di volontari a distanza.
          </>
        : 
          <>
            Unisciti al nostro team di volontari a distanza.
          </>
        }
        lowertext={lang === "it" ? 
          <>
            Puoi fare la differenza e cambiare la vita di molti cavalli, ovunque tu sia.
          </>
        : 
          <>
            Puoi fare la differenza e cambiare la vita di molti cavalli, ovunque tu sia.
          </>          
        }
        page="volontariatoadistanza"
        alt="Emotional image"
      />
      
      <Section
        variant="default"
      >
        <Padding top right bottom left>
          {lang === "it" ? 
            <>
              <Typo type="h2" align="center">title</Typo>
              <Typo type="p" align="center">
                Text
              </Typo>
              <Typo type="p" align="center">
                Text
              </Typo>
            </>
          :
            <>
              <Typo type="h2" align="center">title</Typo>
              <Typo type="p" align="center">
                Text
              </Typo>
              <Typo type="p" align="center">
                Text
              </Typo>
            </>
          }
        </Padding>
      </Section>

      <Section
        variant="secondary-2"
        imageSx={"/web/sections/cinquexmille-cose.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          {lang === "it" ? 
            <>
              <Typo type="h2">Ricerca di Sostegno</Typo>
              <Typo type="p">
                Puoi appendere alle bacheche o distribuire ai tuoi conoscenti, amici di amici o parenti i nostri volantini e biglietti da visita stampabili, fondamentali per farci conoscere e per trovare nuovi donatori disposti a destinarci il 5x1000, a donare o ad adottare a distanza i nostri cavalli per aiutarci a sostenerne le spese.<br/>
                Se conosci attività commerciali desiderose di sostenere un'organizzazione di volontariato, puoi aiutarci a raccogliere materiale per le lotterie, concordare partnerships ed essere il nostro tramite.
              </Typo>
              <br/>
              <Cta 
                size="big"
                variant="light-o"
                text="Download material"
                link="/it/areadownload"
                icon="arrow_forward"
              />
            </>
          :
            <>
              <Typo type="h2">Ricerca di Sostegno</Typo>
              <Typo type="p">
                Puoi appendere alle bacheche o distribuire ai tuoi conoscenti, amici di amici o parenti i nostri volantini e biglietti da visita stampabili, fondamentali per farci conoscere e per trovare nuovi donatori disposti a destinarci il 5x1000, a donare o ad adottare a distanza i nostri cavalli per aiutarci a sostenerne le spese.<br/>
                Se conosci attività commerciali desiderose di sostenere un'organizzazione di volontariato, puoi aiutarci a raccogliere materiale per le lotterie, concordare partnerships ed essere il nostro tramite.
              </Typo>
              <br/>
              <Cta 
                size="big"
                variant="light-o"
                text="Download material"
                link="/en/downloadarea"
                icon="arrow_forward"
              />
            </>
          }
        </Padding>
      </Section>

      <Section 
        variant="secondary-3"
        imageSx={"/web/sections/cinquexmille-grazie.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          {lang === "it" ? 
            <>
              <Typo type="h2">Promozione di Shop e Lotterie</Typo>
              <Typo type="p">
                Puoi cercare acquirenti per gli articoli del nostro shop o per i biglietti delle nostre lotterie e, se possibile, offrirti di coordinare la distribuzione dei premi nella tua zona.
              </Typo>
              <br/>
              <Cta 
                size="big"
                variant="light-o"
                text="Shop"
                link="/it/shop"
                icon="arrow_forward"
              />
            </>
          :
            <>
              <Typo type="h2">Promozione di Shop e Lotterie</Typo>
              <Typo type="p">
                Puoi cercare acquirenti per gli articoli del nostro shop o per i biglietti delle nostre lotterie e, se possibile, offrirti di coordinare la distribuzione dei premi nella tua zona.
              </Typo>
              <br/>
              <Cta 
                size="big"
                variant="light-o"
                text="Shop"
                link="/en/shop"
                icon="arrow_forward"
              />
            </>
          }
        </Padding>
      </Section>

      <Section
        variant="secondary-2"
        imageSx={"/web/sections/cinquexmille-cose.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          {lang === "it" ? 
            <>
              <Typo type="h2">Bandi</Typo>
              <Typo type="p">
                Puoi aiutarci a cercare on line bandi e supportaci nell’iter di partecipazione.
              </Typo>
            </>
          :
            <>
              <Typo type="h2">Bandi</Typo>
              <Typo type="p">
                Puoi aiutarci a cercare on line bandi e supportaci nell’iter di partecipazione.
              </Typo>
            </>
          }
        </Padding>
      </Section>

      <Section 
        variant="secondary-3"
        imageSx={"/web/sections/cinquexmille-grazie.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          {lang === "it" ? 
            <>
              <Typo type="h2">Organizzazione di Eventi</Typo>
              <Typo type="p">
                Puoi aiutarci ad organizzare eventi per sensibilizzare i centri equestri locali per promuovere un'equitazione rispettosa, ti supporteremo in presenza o da remoto finché non sarai autonomo/a.
              </Typo>
            </>
          :
            <>
              <Typo type="h2">Organizzazione di Eventi</Typo>
              <Typo type="p">
                Puoi aiutarci ad organizzare eventi per sensibilizzare i centri equestri locali per promuovere un'equitazione rispettosa, ti supporteremo in presenza o da remoto finché non sarai autonomo/a.
              </Typo>
            </>
          }
        </Padding>
      </Section>
    </Layout>
  )
}

export default SostieniciVolontariatoadistanza;
