import React from "react";
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  ${props => props.size !== "fluid" ? `
    @media (min-width: 576px){
      max-width: ` + (props.size === "big" ? 540 : props.size === "medium" ? 540 : 450) + `px;
    }
    @media (min-width: 768px){
      max-width: ` + (props.size === "big" ? 720 : props.size === "medium" ? 720 : 450) + `px;
    }
    @media (min-width: 992px){
      max-width: ` + (props.size === "big" ? 960 : props.size === "medium" ? 850 : 450) + `px;
    }
    @media (min-width: 1200px){
      max-width: ` + (props.size === "big" ? 1140 : props.size === "medium" ? 850 : 450) + `px;
    }
  ` : null }


  &, & * {
    box-sizing: border-box;
  }
`;

const Container = ({size = "big", children}) => {
  return (
    <Wrapper size={size}>
      {children}
    </Wrapper>
  )
}

export default Container;
