export const firebaseConfig = {
  apiKey: "AIzaSyAN8IkgzMW9YQ5jTeH1-KyHLejFBF5Wiws",
  authDomain: "equinozio-odv.firebaseapp.com",
  projectId: "equinozio-odv",
  storageBucket: "equinozio-odv.appspot.com",
  messagingSenderId: "718391188587",
  appId: "1:718391188587:web:aca5e4467da839a552a52e",
  measurementId: "G-ZL74PRQ354"
};

export const BREAKPOINT_XS = 768 - 1;
export const BREAKPOINT_SM = 992 - 1;
export const BREAKPOINT_MD = 1400 - 1;
export const LAYOUT_XS = "XS";
export const LAYOUT_SM = "SM";
export const LAYOUT_MD = "MD";
export const LAYOUT_LG = "LG";
export const PAGESIZE = 20;