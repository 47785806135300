import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';

import AnalyticsService from './../../services/AnalyticsService';

import Meta from './../layout/Meta';
import Layout from '../layout/Layout';
import Section from '../layout/Section';
import Padding from '../layout/Padding';
import Row from './../../common/components/Row';
import Col from './../../common/components/Col';
import Typo from './../../common/components/Typo';
import Cta from './../../common/components/Cta';

const Map = styled.div`
  margin-bottom: -6px;
`;


const Contatti = ({layout}) => {
  let { lang } = useParams();

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  let iframe = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2818.8602866773285!2d10.77504681572498!3d45.04805696913462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47802b4e24a0d651%3A0x8c0b82c5f835ef8!2sEquinozioOnlus%20-%20Nuova%20Vita%20ASD%20-%20Equitazione%20etica!5e0!3m2!1sit!2sit!4v1673458964318!5m2!1sit!2sit" width="100%" height="500" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>';

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Contatti" : "Contacts"}
        languages={{"it": "contatti", "en":"contacts"}}
      />

      <Map dangerouslySetInnerHTML={{__html: iframe}} />
      <Section
        variant="primary-2" 
        imageDx={"/web/sections/contatti-contatti.jpg"}
        minHeight="500px"
      >
        <Padding top right bottom left>
          {lang === "it" ?
            <>
              <Typo type="h1">Contatti</Typo>
              <Typo type="p">L'Associazione Equinozio ODV si trova a Borgo Virgilio, presso il centro Nuova Vita ASD.<br/>Vieni a trovarci, saremo felici di mostrarti i nostri cavalli e raccontarti le loro storie!</Typo>
              <Typo type="p">Vuoi aiutarci facendo volontariato?<br/>Vuoi delle informazioni sulla gestione dei cavalli?<br/>Vuoi segnalarci qualcosa?<br/>Contattaci liberamente!</Typo>
            </>
          :
            <>
              <Typo type="h1">Contacts</Typo>
              <Typo type="p">The Equinozio ODV Association is located in Borgo Virgilio, at the Nuova Vita ASD centre.<br/>Come and visit us, we will be happy to show you our horses and tell you their stories!</Typo>
              <Typo type="p">Want to help us by volunteering?<br/>Would you like information on horse management?<br/>Do you want to report something to us?<br/>Feel free to contact us!</Typo>
            </>
          }
        </Padding>
     </Section>
     <Section variant="primary-1">
        <Padding top right bottom left>
          <Row>
            <Col widthmd={3}>
              <Typo type="h3" align="center">
                {lang === "it" ? "Oasi" : "Oasis"}
              </Typo>
              <Typo type="p" align="center">
                Nuova Vita ASD<br/>
                Via Argine Boccadiganda 115,<br/>
                Borgo Virgilio 46034 (MN)
              </Typo>
            </Col>
            <Col widthmd={3}>
              <Typo type="h3" align="center">
                {lang === "it" ? "Sede legale" : "Registered office"}
              </Typo>
              <Typo type="p" align="center">
                Via Learco Guerra 11,<br/>Mantova 46100 (MN)<br/>
                CF: 93069130206
              </Typo>
            </Col>
            <Col widthmd={3}>
              <Typo type="h3" align="center">
                {lang === "it" ? "Contatti" : "Contacts"}
              </Typo>
              <Typo type="p" align="center">
                <a href="tel:+393505199517" target="_blank" rel="noreferrer">Erica: +39 347 9339927</a><br/>
                <a href="tel:+393505199517" target="_blank" rel="noreferrer">Martina: +39 346 1330641</a><br/>
                <a href="info@equinozio-odv.it" target="_blank" rel="noreferrer">Email: info@equinozio-odv.it</a>
              </Typo>
            </Col>
            <Col widthmd={3}>
              <Typo type="h3" align="center">
                Social
              </Typo>
              <Typo type="p" align="center">
                <a href="https://www.instagram.com/equinozioodv" rel="noreferrer" target="_blank">Instagram</a><br/>
                <a href="https://www.facebook.com/EquinozioODV" rel="noreferrer" target="_blank">Facebook</a>
              </Typo>
            </Col>
          </Row>
        </Padding>
      </Section>
      <Section
        variant="secondary-2" 
        imageSx={"/web/sections/contatti-nuovacasa.jpg"}
        minHeight="300px"
      >
        <Padding top right bottom left>
          {lang === "it" ?
            <>
              <Typo type="h2">Hai bisogno di trovare una nuova casa al tuo cavallo?</Typo>
              <Typo type="p">Compila il modulo che trovi qui!</Typo>
              <br/>
              <Cta 
                size="big"
                variant="light-o"
                text="Richiedi aiuto"
                link="/it/contatti/richiesta-cessione"
                icon="arrow_forward"
              />
            </>
          :
            <>
              <Typo type="h2">Need to find a new home for your horse</Typo>
              <Typo type="p">Fill in the form here!</Typo>
              <br/>
              <Cta 
                size="big"
                variant="light-o"
                text="Ask for help"
                link="/it/contacts/cession-application"
                icon="arrow_forward"
              />
            </>
          }
        </Padding>
      </Section>


      {/*<Section>
        {lang === "it" ?
          <>
            <Typo type="h2" align="center">Vuoi candidarti per un'adozione?</Typo>
            <Typo type="p" align="center">
              Compila il modulo che trovi qui!<br/><br/>
              <Link to="/it/candidatura-adozione">
                <Button variant="peach">Candidati</Button>
              </Link>
            </Typo>
          </>
        :
          <>
            <Typo type="h2" align="center">Would you like to apply for an adoption?</Typo>
            <Typo type="p" align="center">
              Fill in the form here!<br/><br/>
              <Link to="/en/adoption-application">
                <Button variant="peach">Apply</Button>
              </Link>
            </Typo>
          </>
        }
      </Section> */}
    </Layout>
  )
}

export default Contatti
