import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import AnalyticsService from '../../services/AnalyticsService';


import DatabaseService from '../../services/DatabaseService';

import Section from '../layout/Section';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Typo from '../../common/components/Typo'
import Meta from '../layout/Meta';

import Layout from '../layout/Layout';
import Button from '../../common/components/Button';
import Input from '../../common/components/Input';
import Select from '../../common/components/Select';
import Textarea from '../../common/components/Textarea';

const ErrorMessage = styled.div`
  margin: 20px 0px;
  width: 100%;
  color: red;
  text-align: center;
`;

const SuccessMessage = styled.div`
  margin: 20px 0px;
  width: 100%;
  color: green;
  text-align: center;
`;

const CircleApplication = ({layout}) => {
  let { lang } = useParams();
  const [item, setItem] = useState({});
  const [horses, setHorses] = useState([]);

  const [disabled, setDisabled] = useState(true);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  useEffect(() => {
    setErrorMessage(null);
    let valid = true;
    if(!item.horse || item.horse.length === 0){
      valid = false;
    }
    if(!item.donorName || item.donorName.length === 0){
      valid = false;
    }
    if(!item.givenName || item.givenName.length === 0){
      valid = false;
    }
    if(!item.familyName || item.familyName.length === 0){
      valid = false;
    }
    if(!item.phone || item.phone.length === 0){
      valid = false;
    }
    if(!item.frequency || item.frequency.length === 0){
      valid = false;
    }
    if(!item.amount || item.amount.length === 0){
      valid = false;
    }
    if(!item.method || item.method.length === 0){
      valid = false;
    }
    setDisabled(!valid);
  }, [item]);

  const send = useCallback(() => {
    setErrorMessage("");
    item.date = Date.now();
    DatabaseService.addCircleApplication(item).then((response) => {
      if(response.success){
        setSuccessMessage(lang === "it" ? "Richiesta inviata correttamente!" : "Request sent successfully");
        setItem({});
      }else{
        setErrorMessage(response.error);
        console.log(response.error);
      }
    });
  }, [item, lang]);

  useEffect(() => {
    DatabaseService.getHorses({order: "name", status: "Residente dell'Oasi", showWeb: true}).then((response) => {
      if(response.success){
        setHorses(response.data);
      }else{
        console.log(response.error);
      }
    });
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Entra a far parte di una Cerchia!" : "Enter in a Circle!"}
        languages={{"it": "candidatura-cerchia", "en":"circle-application"}}
      />
      <Section>
        <Typo type="h1" align="center">{lang === "it" ? "Entra a far parte di una Cerchia!" : "Enter in a Circle!"}</Typo>
        <Typo type="p" align="center">
          {lang === "it" ? 
            "Desideri sostenere a distanza uno dei nostri cavalli? Compila questo modulo ed entra nella cerchia WhatsApp del cavallo scelto!"
          : 
            "Would you like to adopt one of our horses? Fill in this form!"
          } 
          <br/>
          {lang === "it" ? 
            "Scegli il nome da visualizzare nella cerchia e sui social, la frequenza di pagamento, l'importo e il mezzo."
          : 
            "Scegli il nome da visualizzare nella cerchia e sui social, la frequenza di pagamento, l'importo e il mezzo."
          } 
        </Typo>
        <Typo type="p" align="center">
          {lang === "it" ? 
            "Invia il pagamento ricorrente tramite Bonifico (IBAN: IT74S0103058030000010190156 intestato a Associazione Equinozio Onlus) o PayPal (equinozioonlus@gmail.com)."
          : 
            "Invia il pagamento ricorrente tramite Bonifico (IBAN: IT74S0103058030000010190156 intestato a Associazione Equinozio Onlus) o PayPal (equinozioonlus@gmail.com)."
          } 
        </Typo>
        <Typo type="p" align="center">
          {lang === "it" ? 
            "A brevissimo ti inseriremo nella cerchia!"
          : 
            "A brevissimo ti inseriremo nella cerchia!"
          } 
        </Typo>

        <Row>
          <Col md={6}>
            <Select
              label={lang === "it" ? "Scegli il cavallo" : "Choose the horse"}
              width="100%"
              searchable={false}
              values={horses.map(horse => ({
                "name": horse.name,
                "value": horse.name
              }))}
              selected={item.horse}
              onChange={(val) => {
                let tempItem = {...item};
                tempItem.horse = val;
                setItem(tempItem);
              }}
              required
            />
          </Col>
          <Col md={6}>
            <Input
              label={lang === "it" ? "Nome donatore da visualizzare" : "Donor name"}
              width="100%"
              value={item.donorName}
              onChange={(val) => {
                let tempItem = {...item};
                tempItem.donorName = val;
                setItem(tempItem);
              }}
              required
            />
          </Col>
          <Col md={6}>
            <Input
              label={lang === "it" ? "Il tuo nome" : "Your name"}
              width="100%"
              value={item.givenName}
              onChange={(val) => {
                let tempItem = {...item};
                tempItem.givenName = val;
                setItem(tempItem);
              }}
              required
            />
          </Col>
          <Col md={6}>
            <Input
              label={lang === "it" ? "Il tuo cognome" : "Your last name"}
              width="100%"
              value={item.familyName}
              onChange={(val) => {
                let tempItem = {...item};
                tempItem.familyName = val;
                setItem(tempItem);
              }}
              required
            />
          </Col>
          <Col md={6}>
            <Input
              label={lang === "it" ? "Il tuo numero di telefono" : "Your phone"}
              width="100%"
              value={item.phone}
              onChange={(val) => {
                let tempItem = {...item};
                tempItem.phone = val;
                setItem(tempItem);
              }}
              required
            />
          </Col>
          <Col md={6}>
            <Select
              label={lang === "it" ? "Frequenza della donazione" : "Donation frequency"}
              width="100%"
              searchable={false}
              values={[
                {name: lang === "it" ? "Mensile" : "Monthly", value: "Mensile"},
                {name: lang === "it" ? "Trimestrale" : "Trimestrale", value: "Trimestrale"},
                {name: lang === "it" ? "Semestrale" : "Semestrale", value: "Semestrale"},
                {name: lang === "it" ? "Annuale" : "Annuale", value: "Annuale"}
              ]}
              selected={item.frequency}
              onChange={(val) => {
                let tempItem = {...item};
                tempItem.frequency = val;
                setItem(tempItem);
              }}
              required
            />
          </Col>
          <Col md={6}>
            <Input
              label={lang === "it" ? "Importo della donazione" : "Amount of donation"}
              width="100%"
              value={item.amount}
              onChange={(val) => {
                let tempItem = {...item};
                tempItem.amount = val;
                setItem(tempItem);
              }}
              required
            />
          </Col>
          <Col md={6}>
            <Select
              label={lang === "it" ? "Metodo di pagamento" : "Payment method"}
              width="100%"
              searchable={false}
              values={[
                {name: lang === "it" ? "Bonifico" : "Bonifico", value: "Bonifico"},
                {name: lang === "it" ? "PayPal" : "PayPal", value: "PayPal"}
              ]}
              selected={item.method}
              onChange={(val) => {
                let tempItem = {...item};
                tempItem.method = val;
                setItem(tempItem);
              }}
              required
            />
          </Col>
          <Col md={12}>
            <Textarea
              label={lang === "it" ? "Note" : "Notes"}
              width="100%"
              value={item.notes}
              onChange={(val) => {
                let tempItem = {...item};
                tempItem.notes = val;
                setItem(tempItem);
              }}
            />
          </Col>
        </Row>
      </Section>
      <Section> 
        
        <ErrorMessage>
          {errorMessage}
        </ErrorMessage>

        <SuccessMessage>
          {successMessage}
        </SuccessMessage>

        <center>
          <Button onClick={() => send()} variant={disabled ? "disabled" : "mint"} disabled={disabled}>{lang === "it" ? "Invia richiesta" : "Submit request"}</Button>
        </center>
      </Section>
    </Layout>
  )
}

export default CircleApplication;
